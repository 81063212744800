import {
  DistributionReadModel,
  DistributionValueReadModel,
  ElementTimelinePayload,
  ElementTimelineReadModel,
  ManualDistributionValuePayload,
  TimelineDistributionPayload,
  TimelineDurationUnit,
} from '@client/shared/api';
import { TimeInput } from '../components';
import { isEmpty } from '@client/shared/utilities';
import { deleteEmptyProperties } from './deleteEmptyProperties';
import { objectsEqual } from './objectsEqual';

/* const calculateTimeLineEndDate = (effectiveStart?: string | null, endElement?: TimeInput | null) => {
  if (!endElement) {
    return effectiveStart;
  }

  switch (endElement.type) {
    case 'CalculationModelReference':
    case 'DeliveryPhaseReference':
    case 'MileStoneReference':
    case 'TimelineReference':
      return addMonthsToDate(endElement.effectiveDate ?? '', endElement.offset ?? 0);
    case 'FixedDates':
      if (endElement.fixedDate) return endElement.fixedDate;
      else return addMonthsToDate(effectiveStart ?? '', (endElement.duration ?? 0) + (endElement.offset ?? 0));
    default:
      return undefined;
  }
};

const calculateTimeLineMonths = (frequency: DistributionFrequency | undefined, start: string, end: string) => {
  if (!frequency) frequency = 'Month';

  const startDate = new Date(start);
  const endDate = new Date(end);
  const msInDays = 24 * 60 * 60 * 1000;
  const msInMonths = 2629746000;
  const fullYears = endDate.getFullYear() - startDate.getFullYear() - 1;

  const getMonths = (startDate: Date, endDate: Date) => {
    startDate.setDate(new Date(startDate.getFullYear(), startDate.getMonth(), 1).getDate());
    endDate.setDate(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate());
    return Math.round((endDate.getTime() - startDate.getTime()) / msInMonths);
  };

  const getWeeks = (startDate: Date, endDate: Date) => {
    const isStartSunday = startDate.getDay() === 0;
    const isEndMonday = endDate.getDay() === 1;
    const newStartDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate() - startDate.getDay() + 1
    );
    const daysBetween = Math.round(Math.abs((newStartDate.getTime() - endDate.getTime()) / msInDays));
    let weeksBetween = Math.ceil(daysBetween / 7);
    if (isEndMonday) weeksBetween++;
    if (isStartSunday) weeksBetween++;
    return weeksBetween;
  };

  const getHalfYears = (startDate: Date, endDate: Date) => {
    const startHalf = startDate.getMonth() < 6 ? 1 : 2;
    const endHalf = endDate.getMonth() < 6 ? 1 : 2;
    return fullYears * 2 + (startHalf + endHalf) - 1;
  };

  const getQuarterYears = (startDate: Date, endDate: Date) => {
    const startQuarter = Math.floor(startDate.getMonth() / 3 + 3);
    const endQuarter = Math.floor(endDate.getMonth() / 3 + 3);
    if (startDate.getFullYear() === endDate.getFullYear()) {
      return endQuarter - startQuarter + 1;
    } else if (startDate.getFullYear() + 1 === endDate.getFullYear()) {
      return 4 - startQuarter + 1 + endQuarter;
    } else {
      return 4 - startQuarter + 1 + fullYears * 4 + endQuarter;
    }
  };

  switch (frequency) {
    case 'Month':
      return getMonths(startDate, endDate);
    case 'Week':
      return getWeeks(startDate, endDate);
    case 'QuarterYear':
      return getQuarterYears(startDate, endDate);
    case 'HalfYear':
      return getHalfYears(startDate, endDate);
    default:
      return getMonths(startDate, endDate);
  }
}; */

/**
 * Returns updated timeline, if there are changes, if not return null
 */
export const updateTimeLine = (
  data: {
    startElement?: TimeInput;
    endElement?: TimeInput;
    elementDistribution?: DistributionReadModel;
  },
  timeLineStart: TimeInput | undefined,
  timeLineEnd: TimeInput | undefined,
  hideEnd: boolean,
  timeline: ElementTimelineReadModel | undefined | null,
  timelineDistribution: DistributionReadModel | undefined | null
) => {
  const start = data.startElement ? data.startElement : timeLineStart;
  const end = data.endElement ? data.endElement : timeLineEnd;
  const distribution = data.elementDistribution ? data.elementDistribution : timelineDistribution;

  const effectiveEndDate= end?.effectiveDate ? end.effectiveDate : start?.effectiveDate ?? start?.fixedDate ?? null;
  const effectiveStartDate = start?.effectiveDate;

  const finalDistribution = distribution ? {...distribution} : null;
  if (finalDistribution && (!effectiveEndDate || !effectiveStartDate)) {
    finalDistribution.type = 'None';
  }

  let newTimeLineElement: ElementTimelineReadModel = {
    id: timeline?.id,
    startCalculationModelDeliveryPhaseId: start?.variantDeliveryPhaseId,
    startCalculationModelMileStoneId: start?.variantMileStoneId,
    startOffset: start?.offset ?? 0,
    startOffsetPosition: start?.offsetPosition,
    startOffsetUnit: start?.offsetUnit,
    startFixedStartDate: start?.fixedDate,
    startElementTimelineId: start?.elementTimelineId,
    startType: start?.type ?? 'FixedDates',
    effectiveStartDate: effectiveStartDate,
    endCalculationModelDeliveryPhaseId: hideEnd ? null : end?.variantDeliveryPhaseId,
    endCalculationModelMileStoneId: hideEnd ? null : end?.variantMileStoneId,
    endOffset: end?.offset ?? 0,
    endOffsetPosition: hideEnd ? null : end?.offsetPosition,
    endElementTimelineId: hideEnd ? null : end?.elementTimelineId,
    endType: end?.type ?? 'FixedDates',
    effectiveEndDate: effectiveEndDate, // if no end date set, we just set the start date
    endDate: !end?.fixedDate ? start?.fixedDate ?? start?.effectiveDate ?? null : end.fixedDate, // only the fixed date, but if no date set, we use the start date
    duration: hideEnd ? 0 : end?.duration,
    durationUnit: hideEnd ? ('Days' as TimelineDurationUnit) : end?.durationUnit,
    distribution: finalDistribution,
  };

  // clone original input data for change detection
  const previousTimeLineElement = { ...timeline };
  delete previousTimeLineElement.id;

  newTimeLineElement = { ...previousTimeLineElement, ...newTimeLineElement };
  // prevent infinite loop by checking if the new timeline element is different from an empty one or the previous values
  if (
    (isEmpty(newTimeLineElement.duration) &&
      isEmpty(newTimeLineElement.endElementTimelineId) &&
      newTimeLineElement.endOffset === 0 &&
      isEmpty(newTimeLineElement.endOffsetPosition) &&
      isEmpty(newTimeLineElement.endCalculationModelDeliveryPhaseId) &&
      isEmpty(newTimeLineElement.endCalculationModelMileStoneId) &&
      isEmpty(newTimeLineElement.startElementTimelineId) &&
      isEmpty(newTimeLineElement.startFixedStartDate) &&
      newTimeLineElement.startOffset === 0 &&
      isEmpty(newTimeLineElement.startOffsetPosition) &&
      isEmpty(newTimeLineElement.startCalculationModelDeliveryPhaseId) &&
      isEmpty(newTimeLineElement.startCalculationModelMileStoneId) &&
      isEmpty(newTimeLineElement.distribution)) ||
    objectsEqual(deleteEmptyProperties(newTimeLineElement), deleteEmptyProperties(previousTimeLineElement))
  ) {
    // no changes, so nothing to update
    return null;
  } else {
    // the updated element
    return newTimeLineElement;
  }
};

export const timelineReadModelToPayload = (
  timeline: ElementTimelineReadModel | null | undefined
): ElementTimelinePayload | null =>
  timeline === null || timeline === undefined || !timeline?.effectiveStartDate
    ? null
    : {
        id: !timeline?.id || timeline?.id === '' ? null : timeline?.id,
        startCalculationModelDeliveryPhaseId:
          !timeline?.startCalculationModelDeliveryPhaseId || timeline?.startCalculationModelDeliveryPhaseId === ''
            ? null
            : timeline?.startCalculationModelDeliveryPhaseId,
        startCalculationModelMileStoneId:
          !timeline?.startCalculationModelMileStoneId || timeline?.startCalculationModelMileStoneId === ''
            ? null
            : timeline?.startCalculationModelMileStoneId,
        startOffset: timeline?.startOffset,
        startOffsetPosition: timeline?.startOffsetPosition,
        startFixedStartDate: timeline?.startFixedStartDate,
        startElementTimelineId: timeline?.startElementTimelineId,
        startType: timeline?.startType ?? 'DeliveryPhaseReference',
        duration: timeline?.duration,
        endFixedDate: timeline?.endDate, // only fixed date!
        endCalculationModelDeliveryPhaseId:
          !timeline?.endCalculationModelDeliveryPhaseId || timeline?.endCalculationModelDeliveryPhaseId === ''
            ? null
            : timeline?.endCalculationModelDeliveryPhaseId,
        endCalculationModelMileStoneId:
          !timeline?.endCalculationModelMileStoneId || timeline?.endCalculationModelMileStoneId === ''
            ? null
            : timeline?.endCalculationModelMileStoneId,
        endOffset: timeline?.endOffset,
        endOffsetPosition: timeline?.endOffsetPosition,
        endElementTimelineId: timeline?.endElementTimelineId,
        endType: timeline?.endType ?? 'FixedDates',
        durationUnit: timeline.durationUnit ?? 'Months',
        endTimelineDurationUnit: 'Months',
        startTimelineDurationUnit: 'Months',
        timelineDistribution: distributionReadModelToPayload(timeline.distribution),
      };

export const distributionReadModelToPayload = (
  distribution: DistributionReadModel | null | undefined
): TimelineDistributionPayload | null =>
  distribution === null || distribution === undefined
    ? null
    : {
        id: !distribution?.id || distribution?.id === '' ? null : distribution?.id,
        distributionType: distribution.type,
        distributionFrequency: distribution.frequency,
        manualDistributionPayload: distribution.type === 'Manual'? distributionValuesToPayload(distribution.manualDistributionValues) : [],
      };

const distributionValuesToPayload = (
  distributionValues: DistributionValueReadModel[]
): ManualDistributionValuePayload[] => {
  return distributionValues.filter(x=> !x.rest).map<ManualDistributionValuePayload>((value) => {
    return {
      id: value.id,
      description: value.description,
      percentage: value.percentage,
      fixedValue: value.value,
      fixedDate: value.date
    };
  });
};
