import { useTranslation } from 'react-i18next';
import {
  ComboSelect,
  Button,
  DocumentCustomIcon,
  HintBox,
  Modal,
  ModalOnCloseProps,
  TagWindowIcon,
  TextInput,
  LoadingIndicator,
  TaskCompletedDottedIcon,
  WizardSlideHeader,
} from '@client/shared/toolkit';
import { ShortContractReadModel, useApiPostCopyContractMutation } from '@client/shared/api';
import { isEmpty, safeMutation } from '@client/shared/utilities';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useEffect, useState } from 'react';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useNavigate } from 'react-router-dom';

interface ContractCopyModalProps extends ModalOnCloseProps {
  currentContract: ShortContractReadModel | null;
  availableContracts: ShortContractReadModel[];
}

export const ContractCopyModal = ({ onClose, currentContract, availableContracts }: ContractCopyModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const contractOptions = availableContracts.map((contract) => ({
    label: isEmpty(contract.name) ? contract.code : `${contract.code} - ${contract.name}`,
    value: contract.id,
  }));

  const [selectedContract, setSelectedContract] = useState<ShortContractReadModel>(
    currentContract ?? availableContracts[0],
  );

  const [name, setName] = useState<string>('');
  const [hasNameChanged, setHasNameChanged] = useState<boolean>(false);

  const [code, setCode] = useState<string>('');
  const [hasCodeChanged, setHasCodeChanged] = useState<boolean>(false);

  const [copy, { isLoading }] = useApiPostCopyContractMutation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const [createdContract, setCreatedContract] = useState<string | undefined>(undefined);

  const handleCopyContract = async () => {
    if (selectedContract?.id) {
      try {
        const response = await safeMutation(
          copy,
          {
            contractId: selectedContract.id,
            projectId: loadedProjectId ?? '',
            calculationModelId: loadedVariantId ?? '',
            body: {
              code: code,
              name: name,
            },
          },
          isLoading,
        );
        if (response) {
          setCreatedContract(response.contractId);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (!hasNameChanged) {
      setName(t('app.copyCopyOf', { name: selectedContract.name }));
    }
    if (!hasCodeChanged) {
      setCode(t('app.copyCopyOf', { name: selectedContract.code }));
    }
  }, [selectedContract, hasNameChanged, hasCodeChanged, t]);

  const onSelectedContractChanged = (contractId: string | null) => {
    const contract = availableContracts.find((x) => x.id === contractId);
    if (!contract) {
      return;
    }

    setSelectedContract(contract);
  };

  const onNameChanged = (name: string) => {
    setHasNameChanged(true);
    setName(name);
  };

  const onCodeChanged = (code: string) => {
    setHasCodeChanged(true);
    setCode(code);
  };

  return (
    <>
      <Modal.Header title={t('projectContract.copyContract')} />
      <Modal.Content className="space-y-6">
        {isLoading && <LoadingIndicator text={t('projectContract.copyContractLoading') ?? ''} mode="overlay" />}
        {!createdContract ? (
          <>
            {!currentContract && (
              <div className="border-b">
                <ComboSelect
                  label={t('projectContract.contract')}
                  options={contractOptions}
                  value={selectedContract.id}
                  onChange={onSelectedContractChanged}
                  icon={<DocumentCustomIcon />}
                />
              </div>
            )}

            <div className="border-b">
              <TextInput
                label={t('projectContract.copyName')}
                value={name}
                onChange={onNameChanged}
                icon={<TagWindowIcon />}
              />
            </div>
            <div className="border-b">
              <TextInput
                label={t('projectContract.copyCode')}
                value={code}
                onChange={onCodeChanged}
                icon={<TagWindowIcon />}
              />
            </div>
            <HintBox hintType="info">{t('projectContract.copyHint')}</HintBox>
          </>
        ) : (
          <WizardSlideHeader
            icon={<TaskCompletedDottedIcon />}
            title={t('projectContract.contractCopyComplete')}
            subTitle={t('projectContract.contractCopyCompleteDescription')}
            fullHeight
          />
        )}
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
          {t(!createdContract ? 'common.cancel' : 'common.close')}
        </Button>
        {!createdContract ? (
          <Button variant="primary" onClick={handleCopyContract}>
            {t('projectContract.copyCreateCopy')}
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              onClose(true);
              if (createdContract) {
                navigate(
                  ROUTES_CONFIG.PROJECT_CONTRACT_VIEW.path
                    .replace(':id', loadedProjectId ?? '')
                    .replace(':contractId', createdContract),
                );
              }
              setCreatedContract(undefined);
            }}
          >
            {t('projectContract.createWizard.goToCreatedContractButton')}
          </Button>
        )}
      </Modal.Controls>
    </>
  );
};
