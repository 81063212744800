import { api } from '@client/shared/api';
import { settings } from '@client/shared/store';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { removeCookie } from 'typescript-cookie';

export const LogoutRoute = () => {
  const { t } = useTranslation();

 
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, AnyAction>>();

  useEffect(() => {
    dispatch(api.endpoints.apiPostLogout.initiate())
    .then(() => {
        removeCookie("access-token", {
          domain: settings.mainDomain,
          sameSite: 'Lax',
          path: '/',
          expires: 30 //TODO: use action.payload.expires_in ?
        });
    })
  }, [dispatch]);

  return <div>{t('auth.loggingOut')}</div>;
};
