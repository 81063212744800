import { createSelector } from '@reduxjs/toolkit';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

export type ProbisErrorDataType =
  {
    message?: string | null
    code?: string | null
    messageParameters?: string[] | null
    errorType?: string // 'Domain' | 'None' | 'Exception' | 'Validation'
  }

// eslint-disable-next-line prefer-const
let queryDelay = 0;

export const setDelay = (delay: string | null | undefined) => {
  if (delay == null) return;
  queryDelay = +delay;
};

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object
) => {
  if (queryDelay > 0) {
    console.log(`⚠️ Delaying call to ${api.endpoint} by ${queryDelay}ms`);
    await new Promise((resolve) => setTimeout(resolve, queryDelay));
  }
  return fetchBaseQuery({
    credentials: 'include',
    prepareHeaders: (headers) => {

      // probably not needed anymore after switching away from
      // http header authentication towards cookie based authentication
      return headers;
    },
  })(args, api, extraOptions);
};

export const apiBase = createApi({
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
});

export const isRtkLoadingSelector = createSelector(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (state: any) => state.api,
  (state) => {
    for (const query of Object.keys(state.queries)) {
      if (state.queries[query]?.status === 'pending') return true;
    }
    for (const query of Object.keys(state.mutations)) {
      if (state.mutations[query]?.status === 'pending') return true;
    }
    return false;
  }
);
