import * as yup from 'yup';
import { InferType } from 'yup';

export const DatevWizardFormValidationSchema = yup.object({
  smartLogin: yup.boolean().required('validation.required'),
  invoiceDataService: yup.boolean().required('validation.required'),
  isActive: yup.boolean().required('validation.required'),
  client: yup.string().required('validation.required'),
});

export type DatevWizardFormValidationValues = InferType<typeof DatevWizardFormValidationSchema>;
