import { ShortProjectGroupReadModel } from '@client/shared/api';
import React, { useCallback, useMemo, useState } from 'react';
import {
  ContextMenuItem,
  Modal,
  SlideOver,
  TextHighlighter,
  AddButton,
  PencilIcon,
  TrashIcon,
  SkyscrapersDottedIcon,
  ReportingMenuIcon,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { ROUTES_CONFIG, useValidateProjectPermission, useValidateTenantPermission } from '@client/shared/permissions';
import cn from 'classnames';
import { OpenModal, ProjectOverviewCard } from '@client/project/edit';
import { useNavigate } from 'react-router-dom';
import { EditMultiProjectSlideOver } from './EditMultiProjectSlideOver';
import { getProjectThumbnail } from '@client/project/shared';
import { DashboardCard } from '@client/shared/toolkit';
import { MultiProjectDeleteModal } from './MultiProjectDeleteModal';
import { settings, useUi } from '@client/shared/store';
import { formatUnit } from '@client/shared/utilities';

interface MultiProjectOverviewCardProps {
  project: ShortProjectGroupReadModel;
  showCheckbox: boolean;
  isProjectSelected: boolean;
  selectedProjects: Set<string>;
  expanded: boolean;
  onToggleExpand: (arg: string) => void;
  onSelectProject: (arg: string) => void;
  onAddSubProject: () => void;
  searchText?: string;
  multiProjects?: ShortProjectGroupReadModel[];
}
export const MultiProjectOverviewCard = ({ project, multiProjects, ...props }: MultiProjectOverviewCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const thumbnail = getProjectThumbnail('Small', project, true);

  const [openModal, setOpenModal] = useState<OpenModal>('None');
  const userHasDeletePermission = !useValidateTenantPermission(['TENANT_PROJECT_DELETE']);
  const readOnly = !useValidateProjectPermission(['PROJECT_WRITE'], project.id);
  const canProjectCreate = useValidateTenantPermission(['TENANT_PROJECT_CREATE']);

  const ui = useUi();
  const user = ui.appUser;
  
  const canViewReporting = (user.tenant?.tenantId === '3239f945-4fce-4ebb-931f-89700f4c7631') || settings.mainDomain === 'probis.qa' || settings.mainDomain === 'probis.test' || settings.mainDomain === 'probis.dev'

  const icons = [<SkyscrapersDottedIcon className="h-7" />];

  if (canViewReporting)
    icons.push(
      <ReportingMenuIcon
        className="h-7"
        onClick={(e) => {
          e.stopPropagation();
          onProjectCardClick('reporting');
        }}
      />,
    );

  const contextItems: ContextMenuItem[] = [
    {
      label: t('common.edit'),
      subtitle: t('common.editEntity', { entity: t('app.project') }),
      icon: <PencilIcon />,
      onClick: () => setOpenModal('Edit'),
    },
    {
      label: t('common.deleteGroup'),
      subtitle: t('common.deleteEntity', { entity: t('app.project') }),
      isDisabled: userHasDeletePermission,
      icon: <TrashIcon />,
      onClick: () => setOpenModal('Delete'),
    },
  ];

  const handleCloseModal = () => {
    setOpenModal('None');
  };

  const onProjectCardClick = useCallback(
    (path?: string) => {
      if (props.expanded || path === 'reporting') {
        if (path === 'reporting') {
          navigate(`${ROUTES_CONFIG.MULTI_PROJECT_REPORTING.path.replace(':id', project.id)}`);
        } else {
          navigate(`${ROUTES_CONFIG.MULTI_PROJECT_DASHBOARD.path.replace(':id', project.id)}`);
        }
      } else {
        props.onToggleExpand(project.id);
      }
    },
    [navigate, project.id, props],
  );

  const notExpandedClasses = useMemo(() => {
    return cn(
      'absolute top-0 left-0 w-full h-full z-0',
      'before:content-[""] before:h-full before:w-full before:rounded-md before:overflow-hidden before:bg-slate-100 before:shadow',
      'before:absolute before:top-0 before:left-0 before:z-0 before:-translate-x-3 before:-translate-y-3',
      'after:content-[""] after:h-full after:w-full after:rounded-md after:overflow-hidden after:bg-slate-50 after:shadow',
      'after:absolute after:top-0 after:left-0 after:-translate-x-1.5 after:-translate-y-1.5 after:z-[1]',
    );
  }, []);

  const subtitle = useMemo(() => {
    if (!project.cities.length) return null;
    const cities = project.cities?.join(', ');
    return (
      <span className="whitespace-nowrap">
        {props.searchText ? (
          <TextHighlighter
            text={cities}
            highlighted={props.searchText
              .split(',')
              .filter((x) => x !== '')
              .map((x) => x.trim())}
          />
        ) : (
          cities
        )}
        {!!project.totalBgf && (
          <>
            {cities && ' • '}
            {formatUnit(project.totalBgf, 'm²')} <sup className="text-[9px]">{t('project.bgfRoi')}</sup>
          </>
        ) }
        {/* TODO tbd where to put that
        {project.totalPlotSize && (
          <>
            {(project.totalBgf || cities) && (' • ')}{formatUnit(project.totalPlotSize, 'm²')} <sup className="text-[9px]">{t('project.plot')}</sup>
          </>
        )}
        */}
      </span>
    );
  }, [project.cities, project.totalBgf, t, props.searchText]);

  return (
    <>
      <DashboardCard
        className={cn('relative z-10', {
          'rounded-md shadow-[inset_1px_1px_3px_0px_rgba(51,65,85,0.2)] bg-gray-200': props.expanded,
          'before:content-[""] before:bg-gray-200 before:absolute before:left-0.5 before:bottom-0 before:h-5 before:z-10 before:w-[calc(100%-4px)]':
            props.expanded,
        })}
        showStack={props.expanded}
        passDataCy={`multiproject-${project.name}`}
        thumbnail={thumbnail}
        showCheckbox={props.showCheckbox}
        isSelected={props.isProjectSelected}
        toggleSelect={() => props.onSelectProject(project.id)}
        title={
          props.searchText ? (
            <TextHighlighter
              text={project.name}
              highlighted={props.searchText
                .split(',')
                .filter((x) => x !== '')
                .map((x) => x.trim())}
            />
          ) : (
            project.name
          )
        }
        subTitle={subtitle}
        contextItems={contextItems}
        onClick={onProjectCardClick}
        icons={icons}
      >
        {!props.expanded && <div className={notExpandedClasses} />}
      </DashboardCard>
      <Modal isOpen={openModal === 'Delete'} onClose={handleCloseModal}>
        <MultiProjectDeleteModal project={project} onClose={handleCloseModal} />
      </Modal>
      <SlideOver isOpen={openModal === 'Edit'} onClose={handleCloseModal}>
        <EditMultiProjectSlideOver
          project={project}
          readOnly={readOnly}
          onClose={handleCloseModal}
          thumbnail={thumbnail}
        />
      </SlideOver>
      {props.expanded && (
        <div className="bg-gray-200 shadow-[inset_1px_1px_3px_0px_rgba(51,65,85,0.2)] -translate-y-1.5 rounded col-span-full grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 py-3 overflow-y-auto relative">
          {project.projects.map((project) => (
            <ProjectOverviewCard
              project={project}
              key={project.id}
              showCheckbox={props.showCheckbox}
              isProjectSelected={props.selectedProjects.has(project.id)}
              onSelectProject={() => props.onSelectProject(project.id)}
              enableHover
              searchText={props.searchText}
              multiProjects={multiProjects}
            />
          ))}
          {canProjectCreate && <div className="flex items-center p-5">
            <AddButton variant="primary" onClick={props.onAddSubProject} />
          </div>}
        </div>
      )}
    </>
  );
};
