import { Button } from './Button';
import React, { useMemo } from 'react';
import cn from 'classnames';
import { LinkIcon } from '../icons';

interface UploadBadgeProps {
  size?: 'large' | 'small' | 'xs' | 'custom';
  variant?: 'active' | 'inactive' | 'custom' | 'error' | 'disabled';
  className?: string;
  onClick?: () => void;
  tooltip?: string;
  iconSize?: string;
}

export const UploadBadge = (props: UploadBadgeProps) => {
  const { size = 'large', variant = 'active', className, onClick, tooltip, iconSize } = props;

  let sizeClass = ''
  switch (size) {
    case 'xs':
      sizeClass = 'w-4 h-4';
      break;
    case 'small':
      sizeClass = 'w-5 h-5';
      break;
    case 'large':
      sizeClass = 'w-11 h-11';
      break;
    default:
      sizeClass = '';
  }

  let iconSizeClass = ''
  switch (size) {
    case 'xs':
      iconSizeClass = 'w-2 h-2';
      break;
    case 'small':
      iconSizeClass = 'w-3 h-3';
      break;
    case 'large':
      iconSizeClass = 'w-5 h-5';
      break;
    default:
      iconSizeClass = '';
  }

  const variantClass = useMemo(() => {
    switch (variant) {
      case 'active':
        return 'bg-emerald-500 text-white';
      case 'inactive':
        return 'bg-amber-400 text-white';
      case 'error':
        return 'bg-red-400 text-white';
      case 'disabled':
        return 'bg-gray-400 text-white';
      default:
        return '';
    }
  }, [variant]);

  if (onClick) {
    return (
      <Button
        hasPadding={false}
        variant="custom"
        className={cn('flex items-center justify-center', sizeClass, variantClass, className, {
          '!cursor-default': !onClick,
        })}
        onClick={onClick}
      >
        <LinkIcon className={cn('-rotate-45', iconSize ? iconSize: iconSizeClass)} />
      </Button>
    );
  }

  return (
    <div
      title={tooltip}
      className={cn(
        'rounded-full font-medium transition-colors duration-200 flex items-center justify-center',
        sizeClass,
        variantClass,
        className,
      )}
    >
      <LinkIcon className={cn('-rotate-45', iconSize ? iconSize: iconSizeClass)} />
    </div>
  );
};
