import {
  DecoratedCard,
  Collapsible,
  useComponentDimensions,
  LoadingIndicator,
  LevelToggle,
} from '@client/shared/toolkit';
import React, { PropsWithChildren, ReactNode, useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useApiGetComparisonReportQuery, IComparisonReportRowReadModel } from '@client/shared/api';
import {
  useLoadedProjectVariants,
  useLoadedProjectId,
  useExpandedReportingIds,
  setExpandedComparisonReportIds,
} from '@client/project/store';
import { formatPercentage } from '@client/shared/utilities';
import { CalculationModelSelect } from './CalculationModelSelect';
import { ReportCollapsingRow } from './ReportCollapsingRow';
import { ToggleButton } from '@client/shared/toolkit';
import { FormattedCurrency } from '@client/project/shared';
import { ReportingContext } from './ReportingContextProvider';
import { useDispatch } from 'react-redux';
import { getLevelledElements } from '../utils';

export const ReportComparison = () => {
  const { t } = useTranslation();

  const { data: projectVariants } = useLoadedProjectVariants();

  const projectId = useLoadedProjectId();

  const {
    sourceCalculationModelId,
    setSourceCalculationModelId,
    targetCalculationModelId,
    setTargetCalculationModelId,
  } = useContext(ReportingContext);

  const { data, isFetching } = useApiGetComparisonReportQuery(
    {
      projectId: projectId ?? '',
      calculationModelId: sourceCalculationModelId ?? '',
      targetId: targetCalculationModelId ?? '',
    },
    { skip: sourceCalculationModelId === undefined || targetCalculationModelId === undefined },
  );

  const sourceColumnTitle = projectVariants.find((v) => v.id === sourceCalculationModelId)?.name ?? '-';
  const targetColumnTitle = projectVariants.find((v) => v.id === targetCalculationModelId)?.name ?? '-';

  const levelledElements = useMemo(() => {
    const allElements = [
      ...(data?.report.costs.entries ?? []),
      ...(data?.report.risks.entries ?? []),
      ...(data?.report.earnings.entries ?? []),
      ...(data?.report.financing.entries ?? []),
    ];

    return getLevelledElements(
      allElements,
      ['children'],
      'rowIdentifier',
    );
  }, [data]);

  const dispatch = useDispatch();

  const { Comparison: expandedElements } = useExpandedReportingIds();

  const handleOnCollapse = (level: number) => {
    const elements = levelledElements.filter((x) => x.level <= level - 2);
    const expanded = elements.map((x) => x.elementId);
    dispatch(setExpandedComparisonReportIds(expanded));
  };

  const maxLevel = useMemo(() => {
    return levelledElements.reduce((value, element) => {
      return Math.max(value, element.level);
    }, 3) + 1;
  }, [levelledElements]);

  return (
    <DecoratedCard className="flex-grow">
      <DecoratedCard.Header showActionButton={false}>
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex">
            <div className="truncate">{t('reporting.reportComparison.title')}</div>
            <div className="pdf-export-hidden">
              <LevelToggle handleOnCollapse={handleOnCollapse} showLevels={Array.from({ length: maxLevel }, (_, i) => i + 1)} />
            </div>
          </div>
          <div className="flex items-center">
            <div className="font-bold text-[15px] pr-6">{t('reporting.dataSource')}</div>
            <div className="flex space-x-0.5 items-center">
              <CalculationModelSelect
                selectedCalculationModelId={sourceCalculationModelId}
                className="rounded-r-none w-52"
                onChange={(id) => setSourceCalculationModelId(id)}
              />
              <CalculationModelSelect
                selectedCalculationModelId={targetCalculationModelId}
                className="rounded-l-none w-52"
                onChange={(id) => setTargetCalculationModelId(id)}
              />
            </div>
          </div>
          {/*
          <div className="pdf-export-hidden">
            <Button
              variant="secondary"
              className="inline-flex items-center cursor-pointer font-normal text-base"
              onClick={onDownload}
            >
              <DownloadingUpdatesIcon className="h-5 w-5 mr-2" />
              {t('reporting.actionExportToExcel')}
            </Button>
            {false && (
              <Button
                variant="secondary"
                className="inline-flex items-center cursor-pointer font-normal text-base"
                onClick={onDownloadPDF}
              >
                <DownloadingUpdatesIcon className="h-5 w-5 mr-2" />
                Download as PDF
              </Button>
            )}
          </div>
          */}
        </div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="w-full h-full flex relative">
        {isFetching && <LoadingIndicator mode="overlay" />}
        <ComparisonGroup
          title={t('reporting.reportComparison.groupCosts')}
          accentColor="bg-red-700"
          titleColor="text-red-700"
          sourceTitle={sourceColumnTitle}
          targetTitle={targetColumnTitle}
          totals={[
            <FormattedCurrency amount={data?.report.costs.totals.sourceTotal} />,
            <FormattedCurrency amount={data?.report.costs.totals.targetTotal} />,
            <FormattedCurrency amount={data?.report.costs.totals.differenceAbsolute} />,
          ]}
        >
          {data?.report.costs.entries?.map((cost, i) => (
            <ComparisonRow
              rowData={cost}
              key={`comparison-cost-${cost.code}-${i}`}
              expandedElements={expandedElements}
            />
          ))}
        </ComparisonGroup>

        <ComparisonGroup
          title={t('reporting.reportComparison.groupRisks')}
          accentColor="bg-sky-700"
          titleColor="text-sky-700"
          sourceTitle={sourceColumnTitle}
          targetTitle={targetColumnTitle}
          totals={[
            <FormattedCurrency amount={data?.report.risks.totals.sourceTotal} />,
            <FormattedCurrency amount={data?.report.risks.totals.targetTotal} />,
            <FormattedCurrency amount={data?.report.risks.totals.differenceAbsolute} />,
          ]}
        >
          {data?.report.risks.entries?.map((risk, i) => (
            <ComparisonRow
              rowData={risk}
              key={`comparison-risk-${risk.code}-${i}`}
              expandedElements={expandedElements}
            />
          ))}
        </ComparisonGroup>

        {data?.report.earnings && (
          <ComparisonGroup
            title={t('reporting.reportComparison.groupEarnings')}
            accentColor="bg-lime-600"
            titleColor="text-lime-600"
            sourceTitle={sourceColumnTitle}
            targetTitle={targetColumnTitle}
            totals={[
              <FormattedCurrency amount={data?.report.earnings.totals.sourceTotal} />,
              <FormattedCurrency amount={data?.report.earnings.totals.targetTotal} />,
              <FormattedCurrency amount={data?.report.earnings.totals.differenceAbsolute} />,
            ]}
          >
            {data?.report.earnings.entries?.map((earning, i) => (
              <ComparisonRow
                rowData={earning}
                key={`comparison-earning-${earning.code}-${i}`}
                expandedElements={expandedElements}
              />
            ))}
          </ComparisonGroup>
        )}

        <ComparisonGroup
          title={t('reporting.reportComparison.groupFinancing')}
          accentColor="bg-slate-600"
          titleColor="text-slate-600"
          sourceTitle={sourceColumnTitle}
          targetTitle={targetColumnTitle}
          totals={[
            <FormattedCurrency amount={data?.report.financing.totals.sourceTotal} />,
            <FormattedCurrency amount={data?.report.financing.totals.targetTotal} />,
            <FormattedCurrency amount={data?.report.financing.totals.differenceAbsolute} />,
          ]}
        >
          {data?.report.financing.entries?.map((financing, i) => (
            <ComparisonRow
              rowData={financing}
              key={`comparison-financing-${financing.code}-${i}`}
              expandedElements={expandedElements}
            />
          ))}
        </ComparisonGroup>
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};

interface ComparisonGroupProps extends PropsWithChildren {
  accentColor?: string;
  titleColor?: string;
  title?: string;
  totals?: (string | ReactNode)[];
  sourceTitle?: string;
  targetTitle?: string;
}

const ComparisonGroup = ({
  accentColor,
  children,
  sourceTitle,
  title,
  titleColor,
  totals,
  targetTitle,
}: ComparisonGroupProps) => {
  const { t } = useTranslation();

  const wrapper = useRef<HTMLDivElement>(null);
  const dimensions = useComponentDimensions(wrapper);

  const [open, setOpen] = React.useState(true);

  return (
    <div className="relative w-full" ref={wrapper}>
      <div
        className={classNames('w-2 my-4 bg-cyan-700 rounded -ml-1 left-0 absolute', accentColor)}
        style={{ height: (dimensions.height ?? 15) - 32 }}
      />
      <div className="bg-white border-b border-slate-300">
        <div className="flex pl-4 pr-4 w-full h-full">
          <div className="flex-grow flex">
            <div>
              <div className="flex">
                {children && React.Children.count(children) > 0 ? (
                  <ToggleButton open={open} onClick={() => setOpen(!open)} />
                ) : (
                  <div className="w-8 h-8">&nbsp;</div>
                )}
                <div className="w-10">&nbsp;</div>
                <div className={classNames('text-[22px] font-bold truncate', titleColor)}>{title}</div>
              </div>
              <div className="flex text-[11px] text-slate-500 gap-2">
                <div className="w-6">&nbsp;</div>
                <div className="flex w-[422px] gap-2">
                  <div className="flex-none truncate">{t('reporting.tableColumnId')}</div>
                  <div className="truncate">{t('reporting.tableColumnDescription')}</div>
                </div>
                <div className="truncate">{t('reporting.tableColumnDetails')}</div>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-48 text-right font-bold text-[15px] px-4 truncate mt-auto">{sourceTitle}</div>
            <div className="w-48 text-right font-bold text-[15px] px-4 truncate mt-auto">{targetTitle}</div>
            <div className="w-48 text-right font-bold text-[15px] px-4 truncate mt-auto">
              {t('reporting.tableColumnDifference')}
            </div>
          </div>
        </div>
      </div>
      <Collapsible open={open}>{children}</Collapsible>
      <div className="my-6 bg-white">
        <div className="flex pl-4 pr-4 w-full h-full items-center">
          <div className="flex-grow flex items-end">
            <div>
              <div className="flex">
                <div className="h-6 w-8" />
                <div className="w-10">&nbsp;</div>
                <div className={classNames('text-[15px] font-bold truncate', titleColor)}>
                  {t('reporting.reportComparison.groupTotalSum')}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-48 text-right font-bold text-[15px] px-4 border-b-4 border-double">{totals?.[0]}</div>
            <div className="w-48 text-right font-bold text-[15px] px-4 border-b-4 border-double">{totals?.[1]}</div>
            <div className="w-48 text-right font-bold text-[15px] px-4 border-b-4 border-double">{totals?.[2]}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ComparisonRowProps extends PropsWithChildren {
  rowData?: IComparisonReportRowReadModel;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
}

const ComparisonRow = ({ columns, level = 0, rowData, expandedElements }: ComparisonRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>{rowData.code}</>,
      <>{rowData.description}</>,
      <>-</>,
      <FormattedCurrency amount={rowData.totals.sourceTotal} />,
      <span
        className={classNames({
          'text-red-700': rowData.totals.differencePercent && rowData?.totals.differencePercent > 0,
          'text-lime-600': rowData.totals.differencePercent && rowData?.totals.differencePercent < 0,
        })}
      >
        {rowData.totals.differencePercent != null &&
          (rowData.totals.differencePercent > 0 || rowData.totals.differencePercent < 0) && (
            <span className="text-[8px] mr-1">
              {formatPercentage(rowData.totals.differencePercent, { maxDigits: 0 })}
            </span>
          )}
        <FormattedCurrency amount={rowData.totals.targetTotal} />
      </span>,
      <FormattedCurrency amount={rowData.totals.differenceAbsolute} />,
    ];
  }

  return (
    <ReportCollapsingRow<IComparisonReportRowReadModel>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={ComparisonRow}
      expandedElements={expandedElements}
      report="Comparison"
    />
  );
};
