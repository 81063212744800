import {
  ComboSelect,
  ComboSelectOption,
  ContractIcon,
  DocumentPositionBoundingBoxReadModel,
  DocumentViewerFileDataGroup,
  DocumentViewerFileDataInlineEdit,
  DocumentViewerFileDataSet,
  Form,
  FormField,
  FormWatch,
  FormRefHandle,
  InvoiceDocumentPositionReadModel,
  LoadingIndicator,
  ComboSelectAdditionalOption,
  Modal,
  // GuideDialog,
  Button,
  PaidBillDottedIcon,
  SlideOverTitle,
  // CardActionButton,
} from '@client/shared/toolkit';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AiEvalResultReadModel,
  AiEvalValuesReadModel,
  InvoiceState,
  InvoiceType,
  ProbisErrorDataType,
  ShortContractReadModel,
  useApiGetContractsQuery,
  useApiPostCreateInvoiceByContractMutation,
  useApiPostCreateInvoiceMutation,
  useApiPostGenerateNextProjectObjectCodeMutation,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { formatDateOnly,
  //  formatDate, 
  safeMutation } from '@client/shared/utilities';
import { addDays } from 'date-fns';
import {
  InvoiceCreateFormValidationSchema,
  InvoiceCreateFormValidationValues,
} from '../InvoiceCreateFormValidationValues';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import cn from 'classnames';
import { InvoiceDocumentReviewContractorAndClient } from './InvoiceDocumentReviewContractorAndClient';
import { InvoiceDocumentReviewValues } from './InvoiceDocumentReviewValues';
import { ContractNewWizard } from '../../Contract';
import { InvoiceDataEdit } from '../InvoiceTab';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

interface InvoiceDocumentFileDataProps {
  invoiceDocument?: AiEvalResultReadModel;
  fileData?: AiEvalValuesReadModel | null;
  allBoundingBoxes?: {
    boxes: number[][][];
    pages: number[];
    texts: string[][];
  } | null;
  hoveredBox: number | null;
  showAllBoxes: boolean;
  boxes?: (DocumentPositionBoundingBoxReadModel | null)[];
  setShowAllBoxes: () => void;
  setHoveredBox: (index: number | null) => void;
  setIsFormValid?: (isValid: boolean) => void;
  onClose: () => void;
}

export type InvoiceDocumentFileDataRef = {
  createInvoice: () => Promise<boolean | undefined>;
};

export const InvoiceDocumentFileData = forwardRef<InvoiceDocumentFileDataRef, InvoiceDocumentFileDataProps>(
  (props, ref) => {
    const { invoiceDocument, fileData, hoveredBox, boxes, setHoveredBox, setIsFormValid, onClose } = props;

    const { t } = useTranslation();

    const loadedProjectId = useLoadedProjectId();
    const loadedVariantId = useLoadedVariantId();

    const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');

    const [createInvoice, { isLoading: isCreating }] = useApiPostCreateInvoiceMutation();
    const [createInvoiceByContract, { isLoading: isCreatingByContract }] = useApiPostCreateInvoiceByContractMutation();
    const [createdContract, setCreatedContract] = useState<string | undefined>(undefined);
    const [getNextCode, { isLoading: isGettingCode }] = useApiPostGenerateNextProjectObjectCodeMutation();

    const { data: fetchedContracts, isFetching: isFetchingContracts } = useApiGetContractsQuery(
      {
        projectId: loadedProjectId ?? '',
        calculationModelId: loadedVariantId ?? '',
      },
      {
        skip: !loadedProjectId || !loadedVariantId,
      },
    );

    const formRef = useRef<FormRefHandle<InvoiceCreateFormValidationValues>>(null);

    const [contractOptions, setContractOptions] = useState<ComboSelectOption[]>([]);
    const [codeError, setCodeError] = useState(false);
    const [loadedContracts, setLoadedContracts] = useState<ShortContractReadModel[]>([]);
    const [selectedContract, setSelectedContract] = useState<ShortContractReadModel | null>(null);
    const [allowChangeMode, setAllowChangeMode] = useState(true);
    const [isCreateContractWizardOpen, setIsCreateContractWizardOpen] = useState(false);

    useEffect(() => {
      const getNextInvoiceCode = async () => {
        if (loadedProjectId && loadedVariantId && !formRef.current?.getValues().code) {
          const isGettingNextCode = false;
          const nextCodeResponse = await safeMutation(
            getNextCode,
            {
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              body: { projectObjectType: 'Invoice' },
            },
            isGettingNextCode,
          );
          if (typeof nextCodeResponse !== 'undefined') {
            formRef.current?.setValue('code', nextCodeResponse.code);
          }
        }
      };

      if (formRef.current && !formRef.current.getValues().code && !isGettingCode && invoiceDocument) {
        getNextInvoiceCode();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceDocument]);

    const defaultFormValues = useMemo(() => {
      let dateOfReceipt = '';
      if (invoiceDocument?.document.created) {
        dateOfReceipt = formatDateOnly(new Date(invoiceDocument.document.created));
      }

      const contractId =
        invoiceDocument?.document?.uploadedByContract?.id ?? invoiceDocument?.result?.contractId ?? null;

      return {
        invoicingPartyId: null,
        invoiceRecipientId: null,
        contractId: contractId,
        code: '',
        externalCode: invoiceDocument?.result?.invoiceDetails.number ?? '',
        state: 'Pending' as InvoiceState,
        type: invoiceDocument?.result?.invoiceDetails.type ?? ('Single' as InvoiceType),
        dateOfReceipt: dateOfReceipt,
        invoiceDate: invoiceDocument?.result?.invoiceDetails.invoiceDate ?? null,
        dateOfAudit: null,
        dateOfApproval: null,
        paymentDate: null,
        vat: invoiceDocument?.result?.invoiceDetails.vat ?? 0,
        net: invoiceDocument?.result?.invoiceDetails.net ?? 0,
        claim: invoiceDocument?.result?.invoiceDetails.gross ?? 0,
        dueDate: invoiceDocument?.result?.invoiceDetails.dueDate ?? null,
        cashDiscountDate: invoiceDocument?.result?.invoiceDetails.cashDiscountDate ?? null,
      };
    }, [invoiceDocument]);

    useEffect(() => {
      if (typeof fetchedContracts !== 'undefined') {
        let allContracts: ShortContractReadModel[] = fetchedContracts.contracts ?? [];
        let contractOptions =
          fetchedContracts.contracts?.map((contract) => {
            return {
              value: contract.id,
              label: `${contract.code} - ${contract.name}`,
            };
          }) ?? [];

        fetchedContracts.commitments?.forEach((commitment) => {
          allContracts = [...allContracts, ...(commitment.contracts ?? [])];
          contractOptions = contractOptions.concat(
            commitment.contracts?.map((contract) => {
              return {
                value: contract.id,
                label: `${contract.code} - ${contract.name}`,
              };
            }) ?? [],
          );
        });
        setLoadedContracts(allContracts);
        setContractOptions(contractOptions);
      }
    }, [fetchedContracts]);

    useEffect(() => {
      if (createdContract && loadedContracts.length && formRef.current) {
        const foundContract = loadedContracts.find((contract) => contract.id === createdContract);
        if (foundContract) {
          setSelectedContract(foundContract ?? null);
          formRef.current.setValue('invoicingPartyId', null);
          formRef.current.setValue('invoiceRecipientId', null);
          formRef.current.setValue('contractId', foundContract.id);
        }
      }
    }, [loadedContracts, createdContract]);

    useEffect(() => {
      if (
        (invoiceDocument?.document?.uploadedByContract?.id || invoiceDocument?.result?.contractId) &&
        loadedContracts.length &&
        formRef.current &&
        !createdContract
      ) {
        const contractId = invoiceDocument?.document?.uploadedByContract?.id ?? invoiceDocument?.result?.contractId;
        const foundContract = loadedContracts.find((contract) => contract.id === contractId);
        if (foundContract) {
          setSelectedContract(foundContract ?? null);
          formRef.current.setValue('invoicingPartyId', null);
          formRef.current.setValue('invoiceRecipientId', null);
          formRef.current.setValue('contractId', foundContract.id);
        }
      }
    }, [
      createdContract,
      loadedContracts,
      invoiceDocument?.document?.uploadedByContract?.id,
      invoiceDocument?.result?.contractId,
    ]);

    const positions: InvoiceDocumentPositionReadModel[] = []; // TODO

    const handleError = (e: unknown) => {
      const error = e as FetchBaseQueryError;
      const data = error.data as ProbisErrorDataType;
      if (data?.code === 'error.invoice.code_already_exists') {
        setCodeError(true);
      }
    };

    const handleSubmit = async (data: InvoiceCreateFormValidationValues) => {
      if (loadedProjectId && loadedVariantId && invoiceDocument?.document.id) {
        if (data.contractId) {
          try {
            await safeMutation(
              createInvoiceByContract,
              {
                projectId: loadedProjectId,
                calculationModelId: loadedVariantId,
                contractId: data.contractId,
                body: {
                  invoiceDocumentId: invoiceDocument.document.id,
                  code: data.code,
                  externalCode: data.externalCode,
                  type: data.type,
                  vat: data.vat,
                  claim: data.claim,
                  invoiceDate: data.invoiceDate
                    ? formatDateOnly(new Date(data.invoiceDate))
                    : formatDateOnly(new Date()),
                  dateOfReceipt: data.dateOfReceipt
                    ? formatDateOnly(new Date(data.dateOfReceipt))
                    : formatDateOnly(new Date()),
                  dueDate: data.dueDate ? formatDateOnly(new Date(data.dueDate)) : null,
                  cashDiscountDate: data.cashDiscountDate ? formatDateOnly(new Date(data.cashDiscountDate)) : null,
                  comment: data.comment,
                },
              },
              isCreatingByContract,
            );
            onClose();
            return(true)
          } catch (e) {
            handleError(e);
            return(false)
          }
        } else {
          try {
            await safeMutation(
              createInvoice,
              {
                projectId: loadedProjectId,
                calculationModelId: loadedVariantId,
                body: {
                  invoiceDocumentId: invoiceDocument.document.id,
                  invoicingPartyId: data.invoicingPartyId,
                  invoiceRecipientId: data.invoiceRecipientId,
                  code: data.code,
                  externalCode: data.externalCode,
                  type: data.type,
                  vat: data.vat,
                  claim: data.claim,
                  invoiceDate: data.invoiceDate
                    ? formatDateOnly(new Date(data.invoiceDate))
                    : formatDateOnly(new Date()),
                  dateOfReceipt: data.dateOfReceipt
                    ? formatDateOnly(new Date(data.dateOfReceipt))
                    : formatDateOnly(new Date()),
                  dueDate: data.dueDate ? formatDateOnly(new Date(data.dueDate)) : null,
                  cashDiscountDate: data.cashDiscountDate ? formatDateOnly(new Date(data.cashDiscountDate)) : null,
                  comment: data.comment,
                },
              },
              isCreating,
            );
            onClose();
            return(true)
          } catch (e) {
            handleError(e);
            return(false)
          }
        }
      }
    };

    useImperativeHandle(ref, () => ({
      createInvoice: async () => {
        if (formRef.current) {
          formRef.current?.validateForm();
          const { isValid } = formRef.current.getState();
          if (setIsFormValid) {
            setIsFormValid(isValid);
          }
          return await handleSubmit(formRef.current.getValues());
        }
      },
    }));

    // render form with default values only if invoice document was loaded already
    if (!invoiceDocument) return null;

    return (
      <Form<InvoiceCreateFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={InvoiceCreateFormValidationSchema}
        defaultValues={defaultFormValues}
        className="flex flex-col gap-7"
        ref={formRef}
      >
        {isFetchingContracts && (
          <LoadingIndicator text={t('projectControl.loadingInvoiceDocument')} mode="overlay-window" />
        )}
        {(isCreating || isCreatingByContract) && (
          <LoadingIndicator text={t('projectControl.creatingInvoiceDocument')} mode="overlay-window" />
        )}

        <FormWatch<InvoiceCreateFormValidationValues>
          onChange={({ invoicingPartyId, invoiceRecipientId, invoiceDate }) => {
            if (invoicingPartyId || invoiceRecipientId) {
              setSelectedContract(null);
            }
          }}
          fieldNames={[
            'externalCode',
            'code',
            'invoicingPartyId',
            'invoiceRecipientId',
            'type',
            'invoiceDate',
            'dateOfReceipt',
            'dueDate',
            'cashDiscountDate',
            'claim',
            'net',
            'vat',
          ]}
        >
          {({ dateOfReceipt, claim, net, vat }) => (
            <>
              {/* CONTRACT */}
              <DocumentViewerFileDataGroup className="relative overflow-hidden" divider={false}>
                <Disclosure as="div" className="flex flex-col divide-y-2" defaultOpen>
                  {({ open }) => (
                    <>
                      <DisclosureButton>
                        <div className={cn('flex gap-2 cursor-pointer', open ? 'pb-2' : '')}>
                          <PaidBillDottedIcon className="w-9 flex-none" />
                          <SlideOverTitle
                            marginTop={false}
                            title={t('projectContract.contract')}
                            className="mt-4 flex-1 flex hover:text-gray-800 duration-300 transition-colors relative"
                          >
                            <div className={classNames('flex-1 flex items-center justify-end')}>
                              <ChevronDownIcon
                                className={classNames(
                                  'transition-transform will-change-transform duration-100 transform -rotate-90 h5 w-5',
                                  {
                                    'rotate-0': open,
                                  },
                                )}
                              />
                            </div>
                          </SlideOverTitle>
                        </div>
                      </DisclosureButton>
                      <DisclosurePanel>
                        {/* CONTRACTOR & CLIENT */}
                        <InvoiceDocumentReviewContractorAndClient
                          fileData={fileData}
                          formRef={formRef}
                          selectedContract={selectedContract}
                        />
                        {/* CONTRACT */}
                        <div className="pt-4">
                          <DocumentViewerFileDataInlineEdit
                            allowChangeMode={allowChangeMode}
                            noHover={selectedContract ? false : true}
                            toggleContent={
                              <FormField name="contractId">
                                {(control) => (
                                  <ComboSelect
                                    nullable
                                    icon={<ContractIcon className="h-6 w-6" />}
                                    label={t('projectContract.contract')}
                                    options={contractOptions}
                                    disabled={!canWrite}
                                    handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                                    {...control}
                                    onChange={(contractId) => {
                                      const foundContract = loadedContracts.find(
                                        (contract) => contract.id === contractId,
                                      );
                                      setSelectedContract(foundContract ?? null);
                                      control.onChange(contractId);
                                      formRef.current?.setValue('invoicingPartyId', null);
                                      formRef.current?.setValue('invoiceRecipientId', null);
                                      /*
                                       * For the due date the due date deadline days of contract are added to the invoice receipt date
                                       * For the cash discount date the ash discount deadline days of contract are added to the invoice receipt date
                                       */
                                      if (formRef.current && dateOfReceipt && foundContract) {
                                        if (foundContract?.dueDateDeadline) {
                                          formRef.current.setValue(
                                            'dueDate',
                                            addDays(dateOfReceipt, foundContract.dueDateDeadline),
                                          );
                                        }
                                        if (foundContract?.cashDiscountDeadline) {
                                          formRef.current.setValue(
                                            'cashDiscountDate',
                                            addDays(dateOfReceipt, foundContract.cashDiscountDeadline),
                                          );
                                        }
                                      }
                                    }}
                                    additionalOptionOnClick={() => setIsCreateContractWizardOpen(true)}
                                    additionalOption={
                                      <ComboSelectAdditionalOption label={t('projectContract.createContract')} />
                                    }
                                  />
                                )}
                              </FormField>
                            }
                          >
                            {selectedContract ? (
                              <div className="flex flex-col gap-4">
                                <DocumentViewerFileDataSet
                                  className={
                                    selectedContract.code !== defaultFormValues.code ? 'text-secondary' : undefined
                                  }
                                  label={t('projectContract.contract')}
                                  title={selectedContract.name}
                                  text={selectedContract?.description}
                                />
                                <div className="grid grid-cols-2 gap-2 items-center">
                                  <DocumentViewerFileDataSet
                                    label={t('projectControl.contractNr')}
                                    subtitle={<span className="font-bold text-lg">{selectedContract.code}</span>}
                                  />
                                  {/* {selectedContract.commitmentId && (
                                    <DocumentViewerFileDataSet
                                      label={t('projectControl.commitmentDate')}
                                      subtitle={formatDate(selectedContract.contractDate)}
                                    />
                                  )} */}
                                </div>
                                {selectedContract.commitmentId && (
                                  <DocumentViewerFileDataSet
                                    label={t('projectControl.commitments')}
                                    subtitle={
                                      fetchedContracts?.commitments?.find(
                                        (commitment) => commitment.id === selectedContract.commitmentId,
                                      )?.name ?? ''
                                    }
                                  />
                                )}
                              </div>
                            ) : (
                              <DocumentViewerFileDataSet
                                label={t('projectContract.contract')}
                                subtitle={
                                  <div className="w-full flex justify-center">
                                    <Button variant="secondary">{t('common.add')}</Button>
                                  </div>
                                }
                              />
                            )}
                          </DocumentViewerFileDataInlineEdit>
                        </div>
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
              </DocumentViewerFileDataGroup>

              {/* INVOICE DATA */}
              <DocumentViewerFileDataGroup className="relative">
                <InvoiceDataEdit
                  codeError={codeError}
                  defaultFormValues={defaultFormValues}
                  allowChangeMode={allowChangeMode}
                  setAllowChangeMode={setAllowChangeMode}
                  canEdit={canWrite}
                  first
                />
              </DocumentViewerFileDataGroup>

              {/* INVOICE TITLES AND VALUES */}
              <DocumentViewerFileDataGroup className="relative" divider={false}>
                {/* INVOICE TITLES */}
                {positions && positions?.length > 0 && (
                  <div className="flex flex-col gap-4 text-[15px] truncate">
                    {positions.map((position, i) => (
                      <div
                        key={`invoice-position-${i}`}
                        className={cn('flex flex-nowrap gap-4 justify-between transition-colors duration-300', {
                          'bg-red-500/20': hoveredBox === i,
                          'cursor-pointer': boxes?.length && boxes[i],
                        })}
                        onMouseEnter={boxes?.length && boxes[i] ? () => setHoveredBox(i) : undefined}
                        onMouseLeave={boxes?.length && boxes[i] ? () => setHoveredBox(null) : undefined}
                      >
                        <span className="truncate">{position.value}</span>
                        <span className="text-right whitespace-nowrap">
                          {position.correctedValue ? position.correctedValue : position.originalValue}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                {/* INVOICE VALUES */}
                <InvoiceDocumentReviewValues
                  claim={claim}
                  net={net}
                  vat={vat}
                  formRef={formRef}
                  defaultFormValues={defaultFormValues}
                />
              </DocumentViewerFileDataGroup>
              {/*
          {allBoundingBoxes && (
            <Button variant="text" onClick={setShowAllBoxes} className="text-left text-xs">
              {showAllBoxes ? 'Hide' : 'Show'} all extracted texts
            </Button>
          )}
          */}

              {/* Prepared Guide Dialogs */}
              {/* <GuideDialog
                header={<img src="/assets/questionmark.png" className="w-full h-full" alt="Questionmark" />}
                show
                title={t('projectInvoice.adoptNewAddress')}
                description={t('projectInvoice.adoptNewAddressDescription')}
                controls={
                  <div className="p-1 mb-1 mt-5 w-full flex justify-between">
                    <Button variant="secondary" className="mr-2">
                      {t('common.close')}
                    </Button>
                    <Button variant="primary">{t('common.update')}</Button>
                  </div>
                }
              />

              <GuideDialog
                header={<img src="/assets/stripes.png" className="w-full h-full" alt="Stripes" />}
                title={t('projectInvoice.createOrSearchContract')}
                description={t('projectInvoice.createOrSearchContractDescription')}
                controls={
                  <div className="p-1 mb-1 mt-5 w-full flex justify-between">
                    <Button variant="secondary" className="mr-2">
                      {t('common.back')}
                    </Button>
                    <Button variant="primary">{t('projectInvoice.gotIt')}</Button>
                  </div>
                }
              />

              <GuideDialog
                showCloseButton
                header={<div className="w-full bg-green-500 h-1 rounded-t-lg" />}
                watermark={
                  <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                    <path
                      id="icons8-info"
                      d="M29,2A27,27,0,1,0,56,29,26.951,26.951,0,0,0,29,2Zm0,2.348A24.652,24.652,0,1,1,4.348,29,24.553,24.553,0,0,1,29,4.348Zm0,8.217a3.522,3.522,0,1,0,3.522,3.522A3.522,3.522,0,0,0,29,12.565ZM24.3,24.3v2.348h2.348V41.913H24.3v2.348H33.7V41.913H31.348V24.3Z"
                      transform="translate(-2 -2)"
                      fill="#e2e8f0"
                    />
                  </svg>
                }
                title={t('projectInvoice.warningNoContractor')}
                description={t('projectInvoice.warningNoContractorDescription')}
                controls={
                  <div className="p-1 mb-1 mt-5 w-full flex justify-end">
                    <Button variant="primary">{t('common.next')}</Button>
                  </div>
                }
              />

              <GuideDialog
                showCloseButton
                title={t('projectInvoice.noLinkFound')}
                description={t('projectInvoice.noLinkFoundDescription')}
                watermark={
                  <svg xmlns="http://www.w3.org/2000/svg" width="62.99" height="54.078" viewBox="0 0 62.99 54.078">
                    <path
                      id="icons8-error"
                      d="M32,5.922a2.966,2.966,0,0,0-2.578,1.473L.93,55.469A3,3,0,0,0,3.508,60h56.98a3,3,0,0,0,2.582-4.531L34.578,7.395A2.966,2.966,0,0,0,32,5.922Zm0,2a.978.978,0,0,1,.859.492L61.348,56.488A1,1,0,0,1,60.488,58H3.508a1,1,0,0,1-.859-1.512L31.141,8.414A.978.978,0,0,1,32,7.922Zm0,8.031a1.984,1.984,0,0,0-1.719.98L10.039,50.98A2,2,0,0,0,11.758,54H13a1,1,0,0,0,0-2H11.758L32,17.957,52.242,52H51a1,1,0,0,0,0,2h1.242a2,2,0,0,0,1.719-3.02L33.719,16.938A1.987,1.987,0,0,0,32,15.953Zm-.187,10.094a1.88,1.88,0,0,0-1.227.57,1.947,1.947,0,0,0-.508,1.457L31.039,41a1,1,0,0,0,2,0l.883-12.926a1.947,1.947,0,0,0-.508-1.457,1.966,1.966,0,0,0-1.6-.57ZM32.039,44a2,2,0,1,0,2,2A2,2,0,0,0,32.039,44ZM17,50a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V51A1,1,0,0,0,17,50Zm5,0a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V51A1,1,0,0,0,22,50Zm5,0a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V51A1,1,0,0,0,27,50Zm5,0a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V51A1,1,0,0,0,32,50Zm5,0a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V51A1,1,0,0,0,37,50Zm5,0a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V51A1,1,0,0,0,42,50Zm5,0a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V51A1,1,0,0,0,47,50Z"
                      transform="translate(-0.505 -5.922)"
                      fill="#e2e8f0"
                    />
                  </svg>
                }
                controls={
                  <div className="p-2 mt-5 w-full flex justify-end">
                    <Button variant="primary">{t('projectInvoice.gotIt')}</Button>
                  </div>
                }
              />

              <GuideDialog
                showCloseButton
                title={t('projectInvoice.poorScan')}
                description={t('projectInvoice.poorScanDescription')}
                watermark={
                  <svg xmlns="http://www.w3.org/2000/svg" width="62.99" height="62.99" viewBox="0 0 62.99 62.99">
                    <path
                      id="icons8-single_sided_scanning"
                      d="M4,4v9a1.5,1.5,0,1,0,3,0V7h6a1.5,1.5,0,1,0,0-3ZM57.992,4a1.5,1.5,0,1,0,0,3h6v6a1.5,1.5,0,1,0,3,0V4ZM17.5,13V45.993H5.5a1.5,1.5,0,1,0,0,3H18.746a1.5,1.5,0,0,0,.489,0H51.741a1.5,1.5,0,0,0,.489,0H65.49a1.5,1.5,0,1,0,0-3h-12V13Zm3,3h30v30h-30Zm15.2,6.374L31,25.6v3.029l4.5-3.134v14.5h3V22.372ZM17.5,51.992v6H53.492v-6h-3v3h-30v-3ZM5.476,56.471A1.5,1.5,0,0,0,4,57.992v9h9a1.5,1.5,0,1,0,0-3H7v-6a1.5,1.5,0,0,0-1.523-1.52Zm59.991,0a1.5,1.5,0,0,0-1.476,1.52v6h-6a1.5,1.5,0,1,0,0,3h9v-9a1.5,1.5,0,0,0-1.523-1.52Z"
                      transform="translate(-4 -4)"
                      fill="#e2e8f0"
                    />
                  </svg>
                }
                controls={
                  <div className="p-2 mt-5 w-full flex justify-end">
                    <Button variant="primary">{t('projectInvoice.gotIt')}</Button>
                  </div>
                }
              /> */}
            </>
          )}
        </FormWatch>

        <Modal isOpen={isCreateContractWizardOpen} onClose={() => setIsCreateContractWizardOpen(false)}>
          <ContractNewWizard
            onClose={(createdContract) => {
              setIsCreateContractWizardOpen(false);
              // setCreatedContract(createdContract)
            }}
            setNewContractId={setCreatedContract}
          />
        </Modal>
      </Form>
    );
  },
);
