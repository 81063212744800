import {
  ApprovedIcon,
  ContextMenu,
  ContextMenuItem,
  ListCustomIcon,
  FloatingActionButton,
  InvoiceNotAuditedIcon,
  ListItemProps,
  LoadingIndicator,
  Modal,
  ToggleSwitch,
  TrashIcon,
  EyeIcon,
  AddIcon,
  CircledPlayIcon,
  UnavailableIcon,
  SlideOver,
  BillIcon,
  ExportIcon,
  FloatingMenuOptions,
  FloatingMenu,
  CheckBox,
  DownloadIcon,
  CoinsIcon,
  DocumentDottedIcon,
} from '@client/shared/toolkit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InvoiceListItemSum, InvoiceListSortHeader } from './Invoice';
import { useTranslation } from 'react-i18next';
import { List } from '@client/shared/toolkit';
import { InvoiceListItem } from './Invoice';
import {
  AiEvalDocumentReadModel,
  api,
  apiBase,
  ApiTagTypes,
  ExternalApiReadModel,
  ShortInvoiceReadModel,
  useApiCancelAiEvalProcessMutation,
  useApiGetAiEvalDocumentPdfFileQuery,
  useApiGetAiEvalDocumentsQuery,
  useApiGetExternalApisQuery,
  useApiGetInvoicesQuery,
  useApiGetTransmittedInvoicesStatusQuery,
  useApiPostReprocessAiEvalDocumentMutation,
  useApiStartInvoiceWorkflowMutation,
} from '@client/shared/api';
import { useLoadedProject, useLoadedProjectId, useToggledInvoiceFilters } from '@client/project/store';
import {
  InvoiceDeleteModal,
  InvoiceDocumentUploadModal,
  InvoiceEditContextProvider,
  StartWorkflowDisabledMessage,
  InvoiceCreateWizard,
  InvoiceTakeoverModal,
  DatevExportModal,
} from '@client/project/shared';
import { InvoiceDocumentListItem } from './Invoice';
import { ProtectedRoute, ROUTES_CONFIG, useValidateProjectPermission } from '@client/shared/permissions';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { InvoiceView, InvoiceDocumentView, ImportInvoicesSlideOver, InvoiceCoverSheetView } from '.';
import { useDispatch } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { DeleteInvoiceAiEvalDocumentModal } from './Invoice';
import { safeMutation } from '@client/shared/utilities';
import toast from 'react-hot-toast';
import { ArrowsRightLeftIcon } from '@heroicons/react/20/solid';
import { produce } from 'immer';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export interface ControlContainerProps {
  searchValue?: string;
  searchResults?: string[];
}

export const ControlContainer = ({ searchValue = '', searchResults = [] }: ControlContainerProps) => {
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, AnyAction>>();

  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedProject = useLoadedProject();
  const loadedVariantId = loadedProject?.data?.project?.calculationModels?.find((x) => x.type === 'Version')?.id;

  const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');
  const canDelete = useValidateProjectPermission(['INVOICE_DELETE'], loadedProjectId ?? '');
  const canApprove = useValidateProjectPermission(['INVOICE_APPROVE'], loadedProjectId ?? '');

  const [openOverlay, setOpenOverlay] = useState<
    null | 'upload' | 'create' | 'import' | 'delete' | 'documentDelete' | 'takeOver' | 'datevExport' | 'datevLog'
  >(null);

  const [invoices, setInvoices] = useState<ShortInvoiceReadModel[]>([]);
  const [aiEvalDocuments, setAiEvalDocuments] = useState<AiEvalDocumentReadModel[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<ShortInvoiceReadModel | null>(null);
  const [selectedInvoiceDocument, setSelectedInvoiceDocument] = useState<AiEvalDocumentReadModel | null>(null);
  const [showNet, setShowNet] = useState(true);
  const [sortValues, setSortValues] = useState<(boolean | null)[]>([true, null, null, null, null, null]);
  const [checkedStatus, setCheckedStatus] = useState(new Set<ShortInvoiceReadModel>());

  const [isLoadedList1, setIsLoadedList1] = useState(false);
  const [isLoadedList2, setIsLoadedList2] = useState(false);
  const [isLoadedList3, setIsLoadedList3] = useState(false);
  const [isLoadedList4, setIsLoadedList4] = useState(false);

  const navigate = useNavigate();

  const toggledInvoiceFilters = useToggledInvoiceFilters();

  const [reprocessInvoiceDocument, { isLoading: isLoadingReprocessing }] = useApiPostReprocessAiEvalDocumentMutation();
  const [startWorkflow, { isLoading: isStartingWorkflow }] = useApiStartInvoiceWorkflowMutation();
  const [cancelProcessingInvoiceDocument, { isLoading: isCancellingProcessing }] = useApiCancelAiEvalProcessMutation();

  const { data: externalApis, isFetching: isLoadingExternalApis } = useApiGetExternalApisQuery();

  // don't show loading indicator for this one, as this is running the background
  const { data: fetchedInvoiceDocuments } = useApiGetAiEvalDocumentsQuery(
    {
      projectId: loadedProjectId ?? 'unset',
    },
    {
      skip: !loadedProjectId,
      refetchOnMountOrArgChange: true,
    },
  );

  // don't show loading indicator for this one, as this is running the background
  const { data: transmittedInvoicesStatus } = useApiGetTransmittedInvoicesStatusQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId,
    },
  );

  const { data: fetchedInvoices, isFetching: isFetchingInvoices } = useApiGetInvoicesQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId,
    },
  );

  const invoiceAiActive = useMemo(() => {
    return !!externalApis?.find((x: ExternalApiReadModel) => x.api.name === 'Probis Invoice AI')?.api.isActive;
  }, [externalApis]);

  const loadData = async () => {
    if (loadedProjectId && loadedVariantId) {
      // const result = await dispatch(
      //   api.endpoints.apiGetAiEvalDocuments.initiate(
      //     {
      //       projectId: loadedProjectId,
      //     },
      //     {
      //       subscribe: false,
      //       forceRefetch: true,
      //     },
      //   ),
      // );
      await dispatch(
        api.endpoints.apiGetTransmittedInvoicesStatus.initiate(
          {
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId ?? '',
          },
          {
            subscribe: false,
            forceRefetch: true,
          },
        ),
      );
    }
  };

  useEffect(() => {
    if (loadedProjectId) {
      const interval = setInterval(() => {
        loadData();
      }, 10000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchedInvoiceDocuments) {
      if (aiEvalDocuments.length) {
        fetchedInvoiceDocuments.forEach((document) => {
          const foundDocument = aiEvalDocuments.find((x) => x.id === document.id);
          if (foundDocument && foundDocument !== document) {
            // @ts-expect-error tmp fix for missing callback from BE
            dispatch(apiBase.util.invalidateTags([{ type: ApiTagTypes.InvoiceDocumentResult, id: document.id }, { type: ApiTagTypes.Tasks, id: 'LIST' }]));
          }
        });
      }
      setAiEvalDocuments(fetchedInvoiceDocuments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedInvoiceDocuments]);

  useEffect(() => {
    if (invoices.length > 0 && transmittedInvoicesStatus && transmittedInvoicesStatus.length > 0) {
      transmittedInvoicesStatus.forEach((transmittedInvoice) => {
        const invoiceWithUpdatedStatus = invoices.find((invoice) => {
          return invoice.id === transmittedInvoice.id && invoice.datevStatus?.status !== transmittedInvoice.status;
        });

        if (invoiceWithUpdatedStatus) {
          // @ts-expect-error tmp fix for missing callback from BE
          dispatch(apiBase.util.invalidateTags([{ type: ApiTagTypes.Invoices, id: loadedVariantId ?? '' }]));
        }
      });
    }
  }, [dispatch, invoices, loadedVariantId, transmittedInvoicesStatus]);

  useEffect(() => {
    if (fetchedInvoices && fetchedInvoices.invoices?.length) {
      const data = [...fetchedInvoices.invoices];
      setInvoices(data);

      // if in create / edit slide over the selected invoice got updated, we need to update the selected invoice, too
      if (selectedInvoice) {
        const foundInvoice = data.find((invoice) => invoice.id === selectedInvoice.id);
        if (foundInvoice) {
          setSelectedInvoice(foundInvoice);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedInvoices]);

  const closeOverlay = () => {
    setOpenOverlay(null);
  };

  const handleCloseInvoiceUploadSlideOver = () => {
    setSelectedInvoiceDocument(null);
    closeOverlay();
  };

  const contextItems: ContextMenuItem[] = [
    {
      label: t('projectControl.uploadInvoiceTitle'),
      subtitle: t('projectControl.uploadInvoiceSubTitle'),
      icon: <AddIcon />,
      onClick: () => {
        setSelectedInvoice(null);
        setSelectedInvoiceDocument(null);
        setOpenOverlay('upload');
      },
      isDisabled: !invoiceAiActive || !canWrite,
    },
    {
      label: t('projectContract.newInvoice'),
      subtitle: t('projectContract.createNewInvoice'),
      icon: <AddIcon />,
      onClick: () => {
        setSelectedInvoice(null);
        setSelectedInvoiceDocument(null);
        setOpenOverlay('create');
      },
      isDisabled: !canWrite,
    },
    {
      label: t('projectControl.invoiceImport'),
      subtitle: t('projectControl.invoiceImportDescription'),
      icon: <AddIcon />,
      onClick: () => {
        setSelectedInvoice(null);
        setSelectedInvoiceDocument(null);
        setOpenOverlay('import');
      },
      isDisabled: !canWrite || !canApprove,
    },
  ];

  const datevMenuOptions: FloatingMenuOptions[] = [
    {
      label: t('projectControl.exportToDatev'),
      icon: <ExportIcon />,
      show: true,
      onClick: () => {
        setOpenOverlay('datevExport');
      },
    },
    {
      label: t('projectControl.viewDatevLog'),
      icon: <ListCustomIcon />,
      show: true,
      disabled:
        Array.from(checkedStatus).length !== 1 ||
        !Array.from(checkedStatus).every((invoice) => invoice.datevStatus && invoice.datevStatus?.status === 'Failed'),
      onClick: () => {
        setOpenOverlay('datevLog');
      },
    },
  ];

  const handleStartWorkflow = async (invoiceId: string) => {
    if (loadedProjectId && loadedVariantId) {
      try {
        await safeMutation(
          startWorkflow,
          {
            body: {
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              invoiceId: invoiceId,
            },
          },
          isStartingWorkflow,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const openInvoice = useCallback(
    (invoice: ShortInvoiceReadModel) => {
      if (selectedInvoice !== invoice) {
        setSelectedInvoice(invoice);
      }
      navigate(
        ROUTES_CONFIG.PROJECT_INVOICE_VIEW.path.replace(':id', loadedProjectId ?? '').replace(':invoiceId', invoice.id),
      );
    },
    [selectedInvoice, navigate, loadedProjectId],
  );

  const openInvoiceDocument = useCallback(
    (invoiceDocument: AiEvalDocumentReadModel) => {
      if (selectedInvoiceDocument !== invoiceDocument) {
        setSelectedInvoiceDocument(invoiceDocument);
      }
      navigate(
        ROUTES_CONFIG.PROJECT_INVOICE_DOCUMENT_VIEW.path
          .replace(':id', loadedProjectId ?? '')
          .replace(':documentId', invoiceDocument.id),
      );
    },
    [loadedProjectId, navigate, selectedInvoiceDocument],
  );


  const [selectedDocumentToDownload, setSelectedDocumentToDownload] = useState<AiEvalDocumentReadModel | null>(null);

  const { data: fetchedFile, isFetching: isFetchingAiEvalDocument } = useApiGetAiEvalDocumentPdfFileQuery(
    {
      documentId: selectedDocumentToDownload?.id ?? '',
      projectId: loadedProjectId ?? 'unset',
    },
    {
      skip: !selectedDocumentToDownload || !loadedProjectId
    });

  useEffect(() => {
    if(fetchedFile) {
      const link = document.createElement("a");
      link.setAttribute('download', selectedDocumentToDownload?.filename ?? 'document.pdf');
      link.href = fetchedFile;
      document.body.appendChild(link);
      link.click();
      link.remove();
      setSelectedDocumentToDownload(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedFile]);

  const invoiceListItems = useMemo(() => {
    let totalNetNotApproved = 0;
    let totalNetApproved = 0;
    let totalNetTransmitted = 0;
    let totalNetPayed = 0;

    let totalGrossNotApproved = 0;
    let totalGrossApproved = 0;
    let totalGrossTransmitted = 0;
    let totalGrossPayed = 0;

    let retentionNetNotApproved = 0;
    let retentionNetApproved = 0;
    let retentionNetTransmitted = 0;
    let retentionNetPayed = 0;

    let retentionGrossNotApproved = 0;
    let retentionGrossApproved = 0;
    let retentionGrossTransmitted = 0;
    let retentionGrossPayed = 0;

    let paymentNetNotApproved = 0;
    let paymentNetApproved = 0;
    let paymentNetTransmitted = 0;
    let paymentNetPayed = 0;

    let paymentGrossNotApproved = 0;
    let paymentGrossApproved = 0;
    let paymentGrossTransmitted = 0;
    let paymentGrossPayed = 0;

    const notApproved: ListItemProps[] = [];
    const approved: ListItemProps[] = [];
    const transmitted: ListItemProps[] = [];
    const payed: ListItemProps[] = [];

    let currentInvoices = [...invoices];

    if (currentInvoices.length) {
      // apply search value
      if (searchValue) {
        const searchFor = searchValue.toLowerCase();
        currentInvoices = currentInvoices.filter((invoice) => {
          return toggledInvoiceFilters && toggledInvoiceFilters.length > 0
            ? searchResults && searchResults?.includes(invoice.id)
            : invoice.code.toLowerCase().includes(searchFor) ||
                invoice.contractName?.toLowerCase().includes(searchFor) ||
                invoice.contractCode?.toLowerCase().includes(searchFor) ||
                invoice.state?.toLowerCase().includes(searchFor) ||
                invoice.externalCode?.toLowerCase().includes(searchFor) ||
                invoice.datevStatus?.status?.toLowerCase().includes(searchFor.replace(/\s/g, ''));
        });
      }

      // TODO global sorting
      currentInvoices.sort((a, b) => {
        if (sortValues[0] !== null) {
          const valA = a.code ?? '';
          const valB = b.code ?? '';
          if (sortValues[0]) {
            return valA.localeCompare(valB);
          } else {
            return valB.localeCompare(valA);
          }
        } else if (sortValues[1] !== null) {
          const valA = a.dateOfReceipt ? new Date(a.dateOfReceipt).getTime() : null;
          const valB = b.dateOfReceipt ? new Date(b.dateOfReceipt).getTime() : null;
          if (valA && valB) {
            if (sortValues[1]) {
              return valA - valB;
            } else {
              return valB - valA;
            }
          }
        } else if (sortValues[2] !== null) {
          const valA = a.contractName ?? '';
          const valB = b.contractName ?? '';
          if (sortValues[2]) {
            return valA.localeCompare(valB);
          } else {
            return valB.localeCompare(valA);
          }
        } else if (sortValues[3] !== null) {
          const valA = a.claim ?? 0;
          const valB = b.claim ?? 0;
          if (sortValues[3]) {
            return valA - valB;
          } else {
            return valB - valA;
          }
        } else if (sortValues[4] !== null) {
          const valA = a.invoiceValueNet ?? 0;
          const valB = b.invoiceValueNet ?? 0;
          if (sortValues[4]) {
            return valA - valB;
          } else {
            return valB - valA;
          }
        } else if (sortValues[5] !== null) {
          const valA = a.retentionNet ?? 0;
          const valB = b.retentionNet ?? 0;
          if (sortValues[5]) {
            return valA - valB;
          } else {
            return valB - valA;
          }
        } else if (sortValues[6] !== null) {
          const valA = a.paymentValueNet ?? 0;
          const valB = b.paymentValueNet ?? 0;
          if (sortValues[6]) {
            return valA - valB;
          } else {
            return valB - valA;
          }
        }
        return 0;
      });
      currentInvoices.forEach((invoice) => {
        const contextMenuItems: ContextMenuItem[] = [
          {
            label: t('common.view'),
            subtitle: t('projectControl.viewInvoiceDetails'),
            icon: <EyeIcon />,
            onClick: () => {
              openInvoice(invoice);
            },
          },
        ];

        contextMenuItems.push({
          label: t('projectControl.viewIcs'),
          subtitle: t('projectControl.viewIcsSubtitle'),
          icon: <BillIcon />,
          onClick: () => {
            navigate(
              `${ROUTES_CONFIG.PROJECT_INVOICE_VIEW.path
                .replace(':id', loadedProjectId ?? '')
                .replace(':invoiceId', invoice.id)}?tab=ics`,
            );
          },
        });

        if (!invoice.isPxInvoice) {
          if (invoice.contractId && invoice.state === 'Pending') {
            contextMenuItems.push({
              label: t('projectControl.takeoverInvoice'),
              subtitle: t('projectControl.takeoverInvoiceDetails'),
              icon: <ArrowsRightLeftIcon />,
              onClick: () => {
                setSelectedInvoice(invoice);
                setOpenOverlay('takeOver');
              },
            });
          }

          if (canDelete) {
            contextMenuItems.push({
              label: t('common.delete'),
              subtitle: t('projectControl.deleteInvoice'),
              icon: <TrashIcon />,
              // disabled: invoice.
              onClick: () => {
                setSelectedInvoice(invoice);
                setOpenOverlay('delete');
              },
            });
          }
          contextMenuItems.push({
            label: invoice.startWorkflowStatus.canRestartWorkflow
              ? invoice.startWorkflowStatus.workflowName
                ? t('projectControl.restartWorkflowName', { name: invoice.startWorkflowStatus.workflowName })
                : t('projectControl.restartWorkflow')
              : invoice.startWorkflowStatus.workflowName
                ? t('projectControl.startWorkflowName', { name: invoice.startWorkflowStatus.workflowName })
                : t('projectControl.startWorkflow'),
            subtitle: t('projectControl.startWorkflowForInvoice'),
            icon: <CircledPlayIcon />,
            onClick: () => {
              handleStartWorkflow(invoice.id);
            },
            isDisabled:
              !invoice.startWorkflowStatus.canStartWorkflow && !invoice.startWorkflowStatus.canRestartWorkflow,
            children:
              !invoice.startWorkflowStatus.canStartWorkflow && !invoice.startWorkflowStatus.canRestartWorkflow ? (
                <StartWorkflowDisabledMessage invoice={invoice} projectId={loadedProjectId ?? ''} />
              ) : null,
            truncateText: false,
          });
        }

        const item = {
          onClick: (e: React.MouseEvent<HTMLDivElement>) => {
            const target = e.target as HTMLElement;
            if (target.tagName !== 'INPUT' && (target as HTMLInputElement).type !== 'checkbox') {
              openInvoice(invoice);
            }
          },
          borderColor: 'bg-slate-400',
          children: (
            <InvoiceListItem
              invoice={invoice}
              showNet={showNet}
              searchValue={searchValue}
              customIcon={
                invoice.state === 'Approved' ? (
                  !invoice.datevStatus?.status || invoice.datevStatus?.status === 'NotProcessed' ? (
                    <CheckBox
                      checked={checkedStatus.has(invoice)}
                      onChange={() => {
                        setCheckedStatus(
                          produce((draft) => {
                            if (!draft.has(invoice)) {
                              draft.add(invoice);
                            } else if (draft.has(invoice)) {
                              draft.delete(invoice);
                            }
                          }),
                        );
                      }}
                      className="hidden md:block cursor-pointer"
                    />
                  ) : invoice.datevStatus?.status === 'Failed' ? (
                    <div className="flex items-center">
                      <CheckBox
                        checked={checkedStatus.has(invoice)}
                        onChange={() => {
                          setCheckedStatus(
                            produce((draft) => {
                              if (!draft.has(invoice)) {
                                draft.add(invoice);
                              } else if (draft.has(invoice)) {
                                draft.delete(invoice);
                              }
                            }),
                          );
                        }}
                        className="hidden md:block cursor-pointer"
                      />
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-700" />
                    </div>
                  ) : undefined
                ) : undefined
              }
            />
          ),
          additionalContent: (
            <div className="hidden md:flex flex-col justify-center px-4">
              <ContextMenu items={contextMenuItems} />
            </div>
          ),
        };

        if (invoice.state !== 'Approved' && invoice.state !== 'Paid') {
          totalNetNotApproved += invoice.invoiceValueNet;
          totalGrossNotApproved += invoice.invoiceValueGross;
          retentionNetNotApproved += invoice.retentionNet;
          retentionGrossNotApproved += invoice.retentionGross;
          paymentNetNotApproved += invoice.paymentValueNet;
          paymentGrossNotApproved += invoice.paymentValueGross;
          item.borderColor = 'bg-slate-400';
          notApproved.push(item);
        } else if (invoice.state === 'Approved' && !invoice.datevStatus) {
          totalNetApproved += invoice.invoiceValueNet;
          totalGrossApproved += invoice.invoiceValueGross;
          retentionNetApproved += invoice.retentionNet;
          retentionGrossApproved += invoice.retentionGross;
          paymentNetApproved += invoice.paymentValueNet;
          paymentGrossApproved += invoice.paymentValueGross;
          item.borderColor = 'bg-green-400';
          approved.push(item);
        } else if (invoice.state === 'Approved' && invoice.datevStatus) {
          totalNetTransmitted += invoice.invoiceValueNet;
          totalGrossTransmitted += invoice.invoiceValueGross;
          retentionNetTransmitted += invoice.retentionNet;
          retentionGrossTransmitted += invoice.retentionGross;
          paymentNetTransmitted += invoice.paymentValueNet;
          paymentGrossTransmitted += invoice.paymentValueGross;
          item.borderColor = 'bg-green-600';
          transmitted.push(item);
        } else {
          totalNetPayed += invoice.invoiceValueNet;
          totalGrossPayed += invoice.invoiceValueGross;
          retentionNetPayed += invoice.retentionNet;
          retentionGrossPayed += invoice.retentionGross;
          paymentNetPayed += invoice.paymentValueNet;
          paymentGrossPayed += invoice.paymentValueGross;
          item.borderColor = 'bg-gray-400';
          payed.push(item);
        }
      });
    }

    return {
      notApproved: {
        items: notApproved,
        totalGross: totalGrossNotApproved,
        totalNet: totalNetNotApproved,
        retentionGross: retentionGrossNotApproved,
        retentionNet: retentionNetNotApproved,
        paymentGross: paymentGrossNotApproved,
        paymentNet: paymentNetNotApproved,
      },
      approved: {
        items: approved,
        totalGross: totalGrossApproved,
        totalNet: totalNetApproved,
        retentionGross: retentionGrossApproved,
        retentionNet: retentionNetApproved,
        paymentGross: paymentGrossApproved,
        paymentNet: paymentNetApproved,
      },
      transmitted: {
        items: transmitted,
        totalGross: totalGrossTransmitted,
        totalNet: totalNetTransmitted,
        retentionGross: retentionGrossTransmitted,
        retentionNet: retentionNetTransmitted,
        paymentGross: paymentGrossTransmitted,
        paymentNet: paymentNetTransmitted,
      },
      payed: {
        items: payed,
        totalGross: totalGrossPayed,
        totalNet: totalNetPayed,
        retentionGross: retentionGrossPayed,
        retentionNet: retentionNetPayed,
        paymentGross: paymentGrossPayed,
        paymentNet: paymentNetPayed,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    invoices,
    sortValues,
    t,
    openInvoice,
    showNet,
    searchValue,
    searchResults,
    toggledInvoiceFilters,
    canDelete,
    checkedStatus,
  ]);

  const triggerProcessingDocument = useCallback(
    async (document: AiEvalDocumentReadModel) => {
      if (loadedProjectId) {
        try {
          await safeMutation(
            reprocessInvoiceDocument,
            {
              documentId: document.id,
              projectId: loadedProjectId,
            },
            isLoadingReprocessing,
          );
          toast.success(t('projectControl.notificationUploadingInvoiceDocument', { name: document.filename }), {
            duration: 5000,
          });
        } catch (e) {
          console.error(e);
        }
      }
    },
    [loadedProjectId, isLoadingReprocessing, t, reprocessInvoiceDocument],
  );

  const cancelProcessingDocument = useCallback(
    async (document: AiEvalDocumentReadModel) => {
      if (loadedProjectId) {
        try {
          await safeMutation(
            cancelProcessingInvoiceDocument,
            {
              documentId: document.id,
              projectId: loadedProjectId,
            },
            isCancellingProcessing,
          );
          toast.success(t('projectControl.notificationCanceledInvoiceDocument', { name: document.filename }), {
            duration: 5000,
          });
        } catch (e) {
          console.error(e);
        }
      }
    },
    [loadedProjectId, cancelProcessingInvoiceDocument, isCancellingProcessing, t],
  );

  const getDocumentStateLabel = useCallback(
    (state: string) => {
      switch (state) {
        case 'Failed':
          return t('projectControl.invoiceDocumentStatusFailed');
        case 'Canceled':
          return t('projectControl.invoiceDocumentStatusCanceled');
        case 'Succeeded':
          return t('projectControl.invoiceDocumentStatusSucceeded');
        case 'Processing':
          return t('projectControl.invoiceDocumentStatusProcessing');
        case 'Unprocessed':
          return t('projectControl.invoiceDocumentStatusUnprocessed');
        case 'PageCountExceeded':
          return t('error.ai_eval_document.document_pagecount_exceeded');
      }
    },
    [t],
  );

  const invoiceDocumentListItems = useMemo(() => {
    let currentDocuments = [...aiEvalDocuments];
    const listItems: ListItemProps[] = [];
    if (currentDocuments.length) {
      if (searchValue) {
        const searchFor = searchValue.toLowerCase();
        currentDocuments = currentDocuments.filter((document) => {
          const translatedLabel = getDocumentStateLabel(document.state)
          return document.filename.toLowerCase().includes(searchFor) || 
          translatedLabel?.toLowerCase().includes(searchFor);
        });
      }
      currentDocuments.sort((a, b) => {
        if (sortValues[0] !== null) {
          const valA = a.filename ?? '';
          const valB = b.filename ?? '';
          if (sortValues[0]) {
            return valA.localeCompare(valB);
          } else {
            return valB.localeCompare(valA);
          }
        }
        return 0;
      });
      currentDocuments.forEach((document) => {
        const contextMenuItems: ContextMenuItem[] = [];
        if (document.state === 'Succeeded') {
          contextMenuItems.push({
            label: t('projectControl.reviewInvoiceDocument'),
            subtitle: t('projectControl.editInvoiceDocument'),
            icon: <EyeIcon />,
            onClick: () => openInvoiceDocument(document),
          });
        }
        if (invoiceAiActive && document.state !== 'Succeeded' && document.state !== 'Processing') {
          contextMenuItems.push({
            label: t('projectControl.extractValues'),
            subtitle: t('projectControl.extractValuesFromInvoiceDocument'),
            icon: <DocumentDottedIcon />,
            onClick: () => {
              triggerProcessingDocument(document);
            },
            isDisabled: !canDelete,
          });
        }
        if (document.state === 'Processing') {
          contextMenuItems.push({
            label: t('projectControl.cancelExtractingValues'),
            subtitle: t('projectControl.cancelExtractValuesFromInvoiceDocument'),
            icon: <UnavailableIcon />,
            onClick: () => {
              cancelProcessingDocument(document);
            },
            isDisabled: !canDelete || !invoiceAiActive,
          });
        }
        if (document.state !== 'Processing') {
          contextMenuItems.push({
            label: t('common.delete'),
            subtitle: t('projectControl.deleteInvoiceDocument'),
            icon: <TrashIcon />,
            onClick: () => {
              setSelectedInvoiceDocument(document);
              setOpenOverlay('documentDelete');
            },
            isDisabled: !canDelete,
          });
        }
        contextMenuItems.push({
          label: t('common.download'),
          subtitle: t('projectControl.downloadInvoiceDocument'),
          icon: <DownloadIcon />,
          onClick: () => {
            setSelectedDocumentToDownload(document);
          },
        });
        listItems.push({
          onClick:
            document.state === 'Succeeded' || document.state === 'Unprocessed'
              ? () => openInvoiceDocument(document)
              : undefined,
          borderColor: 'bg-orange-400',
          children: <InvoiceDocumentListItem invoiceDocument={document} showNet={showNet} />,
          additionalContent: (
            <div className="hidden md:flex flex-col justify-center px-4 w-[56px] flex-none">
              {contextMenuItems.length > 0 && <ContextMenu items={contextMenuItems} />}
            </div>
          ),
        });
      });
    }
    return listItems;
  }, [aiEvalDocuments, searchValue, getDocumentStateLabel, sortValues, invoiceAiActive, t, showNet, openInvoiceDocument, canDelete, triggerProcessingDocument, cancelProcessingDocument]);

  const onHandleSort = useCallback(
    (index: number) => {
      const currentSortValues = [...sortValues];
      currentSortValues.forEach((val, i) => {
        if (i !== index) {
          currentSortValues[i] = null;
        }
      });
      const oldValue = currentSortValues[index];
      currentSortValues[index] = !oldValue;
      setSortValues(currentSortValues);
    },
    [sortValues],
  );

  const sortHeader = useMemo(() => {
    if (invoices.length) {
      return <InvoiceListSortHeader onHandleSort={onHandleSort} sortValues={sortValues} />;
    }
    return null;
  }, [invoices.length, onHandleSort, sortValues]);

  const approvedSortHeader = useMemo(() => {
    if (invoices.length) {
      return (
        <InvoiceListSortHeader
          onHandleSort={onHandleSort}
          sortValues={sortValues}
          checkedStatus={checkedStatus.size === invoices.filter((invoice) => invoice.state === 'Approved' && invoice.datevStatus?.status !== 'Succeeded' && invoice.datevStatus?.status !== 'InProcess').length}
          setCheckedStatus={
            invoices.some(
              (invoice: ShortInvoiceReadModel) =>
                invoice.state === 'Approved' &&
                invoice.datevStatus?.status !== 'Succeeded' &&
                invoice.datevStatus?.status !== 'InProcess',
            )
              ? () => {
                  setCheckedStatus(
                    produce((draft: Set<ShortInvoiceReadModel>) => {
                      if (invoices.filter((invoice) => invoice.state === 'Approved' && invoice.datevStatus?.status !== 'Succeeded' && invoice.datevStatus?.status !== 'InProcess').length === draft.size) {
                        draft.clear();
                      } else {
                        invoices.forEach((invoice: ShortInvoiceReadModel) => {
                          if (
                            invoice.state === 'Approved' &&
                            invoice.datevStatus?.status !== 'Succeeded' &&
                            invoice.datevStatus?.status !== 'InProcess'
                          ) {
                            draft.add(invoice);
                          }
                        });
                      }
                    }),
                  );
                }
              : undefined
          }
        />
      );
    }
    return null;
  }, [checkedStatus.size, invoices, onHandleSort, sortValues]);

  return (
    <div className="relative mb-20">
      {(isFetchingInvoices ||
        !isLoadedList1 ||
        !isLoadedList2 ||
        !isLoadedList3 ||
        !isLoadedList4 ||
        isStartingWorkflow ||
        isLoadingExternalApis ||
        isCancellingProcessing) && (
        <LoadingIndicator text={t(isFetchingAiEvalDocument ? 'projectControl.fetching' :'projectControl.fetchingInvoicesLoadingIndicator')} mode="overlay-window" />
      )}
      {invoices.length > 0 && (
        <div className="md:absolute md:right-0 md:top-0 mb-2 md:mb-0">
          <ToggleSwitch
            id="netGrossToggle"
            checked={showNet}
            name="netGrossToggle"
            onChange={() => setShowNet((prev) => !prev)}
            offLabel={t('projectControl.gross')}
            onLabel={t('projectControl.net')}
          />
        </div>
      )}
      {/* Invoice documents (have no invoice, yet) */}
      <List
        icon={<InvoiceNotAuditedIcon />}
        listTitle={t('projectControl.notAuditedInvoiceDocuments')}
        items={invoiceDocumentListItems}
        emptyMessage={t('projectControl.noInvoiceDocuments')}
        updateLoading={() => setIsLoadedList1(true)}
        collapsible
        showPagination={true}
        amountPerPage={5}
      />
      {/* Invoices */}
      <List
        className="mt-10"
        icon={<InvoiceNotAuditedIcon />}
        listTitle={t('projectControl.notApprovedInvoices')}
        items={invoiceListItems.notApproved.items}
        emptyMessage={t('projectControl.noInvoices')}
        sortHeader={invoiceListItems.notApproved.items.length > 0 ? sortHeader : []}
        updateLoading={() => setIsLoadedList2(true)}
        collapsible
        showPagination={true}
        amountPerPage={5}
      >
        {invoiceListItems.notApproved.items.length > 0 && (
          <InvoiceListItemSum
            showNet={showNet}
            grossValue={invoiceListItems.notApproved.totalGross}
            netValue={invoiceListItems.notApproved.totalNet}
            retentionGross={invoiceListItems.notApproved.retentionGross}
            retentionNet={invoiceListItems.notApproved.retentionNet}
            paymentGross={invoiceListItems.notApproved.paymentGross}
            paymentNet={invoiceListItems.notApproved.paymentNet}
          />
        )}
      </List>
      <List
        className="mt-10"
        icon={<ApprovedIcon />}
        listTitle={t('projectControl.approvedInvoices')}
        items={invoiceListItems.approved.items}
        emptyMessage={t('projectControl.noInvoices')}
        sortHeader={invoiceListItems.approved.items.length > 0 ? approvedSortHeader : []}
        updateLoading={() => setIsLoadedList3(true)}
        collapsible
        showPagination={true}
        amountPerPage={5}
      >
        {invoiceListItems.approved.items.length > 0 && (
          <InvoiceListItemSum
            showNet={showNet}
            grossValue={invoiceListItems.approved.totalGross}
            netValue={invoiceListItems.approved.totalNet}
            retentionGross={invoiceListItems.approved.retentionGross}
            retentionNet={invoiceListItems.approved.retentionNet}
            paymentGross={invoiceListItems.approved.paymentGross}
            paymentNet={invoiceListItems.approved.paymentNet}
          />
        )}
      </List>
      <List
        className="mt-10"
        icon={<ExportIcon />}
        listTitle={t('projectControl.transmittedInvoices')}
        items={invoiceListItems.transmitted.items}
        emptyMessage={t('projectControl.noInvoices')}
        sortHeader={invoiceListItems.transmitted.items.length > 0 ? sortHeader : []}
        updateLoading={() => setIsLoadedList3(true)}
        collapsible
        showPagination={true}
        amountPerPage={5}
      >
        {invoiceListItems.transmitted.items.length > 0 && (
          <InvoiceListItemSum
            showNet={showNet}
            grossValue={invoiceListItems.transmitted.totalGross}
            netValue={invoiceListItems.transmitted.totalNet}
            retentionGross={invoiceListItems.transmitted.retentionGross}
            retentionNet={invoiceListItems.transmitted.retentionNet}
            paymentGross={invoiceListItems.transmitted.paymentGross}
            paymentNet={invoiceListItems.transmitted.paymentNet}
          />
        )}
      </List>
      <List
        className="mt-10"
        icon={<CoinsIcon />}
        listTitle={t('projectControl.payedInvoices')}
        items={invoiceListItems.payed.items}
        emptyMessage={t('projectControl.noInvoices')}
        sortHeader={invoiceListItems.payed.items.length > 0 ? sortHeader : []}
        updateLoading={() => setIsLoadedList4(true)}
        collapsible
        showPagination={true}
        amountPerPage={5}
      >
        {invoiceListItems.payed.items.length > 0 && (
          <InvoiceListItemSum
            showNet={showNet}
            grossValue={invoiceListItems.payed.totalGross}
            netValue={invoiceListItems.payed.totalNet}
            retentionGross={invoiceListItems.payed.retentionGross}
            retentionNet={invoiceListItems.payed.retentionNet}
            paymentGross={invoiceListItems.payed.paymentGross}
            paymentNet={invoiceListItems.payed.paymentNet}
          />
        )}
      </List>
      <FloatingActionButton menuItems={contextItems} />

      {/* ------------ OVERLAYS (MODALS & SLIDE OVERS) -------------*/}

      {/* DATEV EXPORT MENU */}
      {Boolean(checkedStatus.size) && (
        <div className="h-16 fixed z-10 left-10 right-10 bottom-10 min-w-0 flex flex-row justify-center items-center">
          <FloatingMenu
            selectedItemsCount={checkedStatus.size}
            clearSelection={() => setCheckedStatus(new Set<ShortInvoiceReadModel>())}
            menuOptions={datevMenuOptions}
          />
        </div>
      )}
      {/* DATEV EXPORT MODAL */}
      {(openOverlay === 'datevExport' || openOverlay === 'datevLog') && (
        <DatevExportModal
          isOpen={openOverlay === 'datevExport' || openOverlay === 'datevLog'}
          type={openOverlay === 'datevExport' || openOverlay === 'datevLog' ? openOverlay : 'datevExport'}
          onClose={() => {
            setCheckedStatus(new Set<ShortInvoiceReadModel>());
            closeOverlay();
          }}
          selectedInvoices={checkedStatus.size ? Array.from(checkedStatus) : []}
          invoices={invoices}
        />
      )}

      {/* INVOICE DOCUMENT UPLOAD */}
      <InvoiceDocumentUploadModal isOpen={openOverlay === 'upload'} onClose={handleCloseInvoiceUploadSlideOver} />

      {/* CREATE INVOICE */}
      <InvoiceCreateWizard isOpen={openOverlay === 'create'} onClose={closeOverlay} />

      {/* IMPORT INVOICE */}
      <SlideOver variant="large" isOpen={openOverlay === 'import'} onClose={closeOverlay}>
        <ImportInvoicesSlideOver onClose={closeOverlay} />
      </SlideOver>

      {/* INVOICE EDIT replaced by invoice create wizard */}
      {/*
      <SlideOver isOpen={openOverlay === 'create'} onClose={closeOverlay}>
        <InvoiceEditSlideOver
          onClose={closeOverlay}
        />
      </SlideOver>
      */}

      {/* DELETE INVOICE */}
      <Modal isOpen={openOverlay === 'delete'} onClose={closeOverlay}>
        {selectedInvoice && <InvoiceDeleteModal invoice={selectedInvoice} onClose={closeOverlay} />}
      </Modal>

      <Modal isOpen={openOverlay === 'takeOver'} onClose={closeOverlay}>
        {selectedInvoice && (
          <InvoiceTakeoverModal
            invoiceId={selectedInvoice.id}
            contractId={selectedInvoice.contractId ?? ''}
            onClose={closeOverlay}
          />
        )}
      </Modal>

      {/* DELETE INVOICE DOCUMENT */}
      <Modal isOpen={openOverlay === 'documentDelete'} onClose={closeOverlay}>
        {selectedInvoiceDocument && (
          <DeleteInvoiceAiEvalDocumentModal document={selectedInvoiceDocument} onClose={closeOverlay} />
        )}
      </Modal>

      {/* ------------ CHILD ROUTES -------------*/}
      <Routes>
        <Route
          path={ROUTES_CONFIG.PROJECT_INVOICE_VIEW.name}
          element={
            <ProtectedRoute routeConfig={ROUTES_CONFIG.PROJECT_INVOICE_VIEW} projectId={loadedProjectId}>
              <InvoiceEditContextProvider>
                <InvoiceView
                  isLoading={
                    isFetchingInvoices ||
                    !isLoadedList1 ||
                    !isLoadedList2 ||
                    !isLoadedList3 ||
                    !isLoadedList4 ||
                    isStartingWorkflow ||
                    isLoadingExternalApis ||
                    isCancellingProcessing
                  }
                />
              </InvoiceEditContextProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES_CONFIG.PROJECT_INVOICE_DOCUMENT_VIEW.name}
          element={
            <ProtectedRoute routeConfig={ROUTES_CONFIG.PROJECT_INVOICE_DOCUMENT_VIEW} projectId={loadedProjectId}>
              <InvoiceDocumentView />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES_CONFIG.PROJECT_INVOICE_ICS_VIEW.name}
          element={
            <ProtectedRoute routeConfig={ROUTES_CONFIG.PROJECT_INVOICE_ICS_VIEW} projectId={loadedProjectId}>
              <InvoiceCoverSheetView />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </div>
  );
};
