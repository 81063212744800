import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  BaseSelectOption,
  Button,
  ComboSelect,
  CurrencySelect,
  Form,
  FormField,
  FormRefHandle,
  FormWatch,
  NumberInput,
  SlideOver,
} from '@client/shared/toolkit';
import { countryCodes } from '@client/shared/utilities';
import {
  BenchmarkingEditValues,
  BenchmarkingValueType,
  EditBenchmarkingDefaultValueValidationSchema,
  EditBenchmarkingDefaultValueValidationValues,
  UsedYearsType,
} from './DefaultValuesBenchmarkingEditSlideOver';

export const DefaultValueBenchmarkingValueEditForm = ({
  onSubmit,
  onCancel,
  title,
  usedYears,
  code,
  minProjectYear,
  maxProjectYear,
  type,
  defaultCurrency,
}: {
  onSubmit: (data: BenchmarkingEditValues) => void;
  onCancel: () => void;
  title: string;
  usedYears: UsedYearsType;
  code?: string;
  minProjectYear?: number;
  maxProjectYear?: number;
  type: BenchmarkingValueType;
  defaultCurrency?: string;
}) => {
  const { t } = useTranslation();
  const [selectedCode, setSelectedCode] = useState<string | null>(code ?? null);
  const formRef = useRef<FormRefHandle<EditBenchmarkingDefaultValueValidationValues>>();

  const minYear = useMemo(() => {
    return minProjectYear ?? new Date().getFullYear() - 100;
  }, [minProjectYear]);
  const maxYear = useMemo(() => {
    return maxProjectYear ?? new Date().getFullYear() + 100;
  }, [maxProjectYear]);

  const yearOptions: BaseSelectOption[] = useMemo(() => {
    return Array.from({ length: maxYear - minYear + 1 }, (_, i) => {
      const year = i + minYear;
      const yearString = year.toString();
      return {
        value: yearString,
        label: yearString,
        disabled: !selectedCode || (selectedCode && usedYears[selectedCode] && usedYears[selectedCode].includes(year)),
      };
    }).filter((n) => n !== null) as BaseSelectOption[];
  }, [usedYears, selectedCode, minYear, maxYear]);

  useEffect(() => {
    if (formRef.current && yearOptions.length && selectedCode && usedYears[selectedCode]) {
      const lastYear = Math.max(...usedYears[selectedCode]);
      const nextYear = lastYear + 1;
      if (nextYear <= maxYear) {
        formRef.current.setValue('year', nextYear.toString());
      }
    }
  }, [yearOptions, selectedCode, usedYears, maxYear]);

  const defaultFormValues = {
    code: code ?? '',
    year: new Date().getFullYear().toString(),
    factor: 1,
  };

  return (
    <>
      <SlideOver.Header onClose={onCancel} title={title} backgroundClassName="bg-sky-900" />
      <Form<EditBenchmarkingDefaultValueValidationValues>
        onSubmit={(data) => {
          onSubmit({
            code: data.code,
            year: parseInt(data.year),
            factor: data.factor,
          });
        }}
        validationSchema={EditBenchmarkingDefaultValueValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
        ref={formRef}
      >
        <FormWatch fieldNames={['code', 'year', 'factor']}>
          {({ code, year, factor }) => (
            <>
              <SlideOver.Content className="p-8">
                {type === 'currency' ? (
                  <CurrencySelect
                    formFieldName="code"
                    onChange={(val) => {
                      setSelectedCode(val);
                    }}
                    disabledOptions={defaultCurrency ? [defaultCurrency] : []}
                  />
                ) : (
                  <FormField name="code">
                    {(control) => (
                      <ComboSelect
                        label={t('common.addressCountry')}
                        options={countryCodes.getCountries()}
                        {...control}
                        onChange={(val) => {
                          setSelectedCode(val);
                          control.onChange(val);
                        }}
                      />
                    )}
                  </FormField>
                )}
                <FormField name="year">
                  {(control) => (
                    <ComboSelect
                      label={t('projectCalculate.localLaw.year')}
                      options={yearOptions}
                      nullable
                      disabled={!code}
                      {...control}
                      showValidation={
                        control.showValidation || (code && usedYears[code] && usedYears[code].includes(parseInt(year)))
                      }
                      isValidationValid={
                        control.isValidationValid &&
                        !(code && usedYears[code] && usedYears[code].includes(parseInt(year)))
                      }
                      helperText={
                        code && usedYears[code] && usedYears[code].includes(parseInt(year))
                          ? type === 'currency'
                            ? t('app.masterDataDefaultValues.benchmarking.yearForCurrencyAlreadyCreated')
                            : t('app.masterDataDefaultValues.benchmarking.yearForCountryCodeAlreadyCreated')
                          : undefined
                      }
                    />
                  )}
                </FormField>
                <FormField name="factor">
                  {(control) => <NumberInput label={t('common.factor')} min={0} maxLength={4} {...control} />}
                </FormField>
              </SlideOver.Content>
              <SlideOver.Controls>
                <Button variant="secondary" className="mr-2" onClick={onCancel}>
                  {t('common.cancel')}
                </Button>
                <Button
                  className="flex-none block"
                  formSubmit
                  disabled={
                    !code ||
                    !year ||
                    !factor ||
                    (year && (year < minYear || year > maxYear)) ||
                    (code && usedYears[code] && usedYears[code].includes(parseInt(year)))
                  }
                >
                  {t('common.create')}
                </Button>
              </SlideOver.Controls>
            </>
          )}
        </FormWatch>
      </Form>
    </>
  );
};
