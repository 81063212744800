import { ValueType } from '@client/shared/api';
import * as yup from 'yup';
import { InferType } from 'yup';

export const ProjectNewFormValidationSchema = yup.object({
  projectId: yup.string().required('validation.required'),
  name: yup.string().required('validation.required'),
  projectGroupId: yup.string().optional(),
  street: yup.string().optional(),
  houseNumber: yup.string().optional(),
  postalCode: yup.string().optional(),
  city: yup.string().optional(),
  countryCode: yup.string().optional(),
  vat: yup.number().optional(),
  plotSize: yup.number().typeError('validation.number').min(0),
  gfz: yup.number().typeError('validation.number').min(0),
  grz: yup
    .number()
    .typeError('validation.number')
    .test('range', 'validation.range', (value) => (value as number) >= 0 && (value as number) <= 1),
  startDate: yup.date().required('validation.required'),
  costCatalogId: yup.string().required('validation.required'),
  riskCatalogId: yup.string().optional(),
  earningsCatalogId: yup.string().optional(),
  financingCatalogId: yup.string().optional(),
  deliveryPhasesCatalogId: yup.string().required('validation.required'),
  coverImage: yup.mixed<FileList>().optional(),
  measure: yup.string().required('validation.required'),
  unitSystem: yup.string().required('validation.required'),
  calculateValueType: yup
    .mixed<ValueType>()
    .oneOf(['Net', 'Gross'])
    .required('validation.required'),
  currency: yup.string().required('validation.required'),    
});

export type ProjectNewFormValidationValues = InferType<typeof ProjectNewFormValidationSchema>;
