import { EmailButtonIcon, PhoneButtonIcon, WhatsappButtonIcon } from '../../icons';
import React, { PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';

interface ContactButtonsProps extends PropsWithChildren {
  className?: string;
  phone?: string | null;
  email?: string | null;
  whatsapp?: string | null;
  color?: string;
  width?: string;
  height?: string;
}
export const ContactButtons = (props: ContactButtonsProps) => {
  const { className, phone, email, whatsapp, color, width = 'aspect-square', height, children } = props;
  if (!phone && !email && !whatsapp) return;
  return (
    <ul className={cn('flex gap-2', className)}>
      {phone && (
        <li className={width}>
          <ContactButton icon={<PhoneButtonIcon className={height} />} link={`tel:${phone}`} color={color} />
        </li>
      )}
      {email && (
        <li className={width}>
          <ContactButton icon={<EmailButtonIcon className={height} />} link={`mailTo:${email}`} color={color} />
        </li>
      )}
      {whatsapp && (
        <li className={width}>
          <ContactButton
            icon={<WhatsappButtonIcon className={height} />}
            link={`https://wa.me/1${whatsapp}`}
            color={color}
          />
        </li>
      )}
      <li>{children}</li>
    </ul>
  );
};

type ContactButtonProps = {
  link: string;
  icon: ReactNode;
  color?: string;
};
export const ContactButton = (props: ContactButtonProps) => {
  const { link, icon, color = 'text-gray-400 hover:text-gray-700' } = props;
  return (
    <a
      href={link}
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={cn('block transition-colors duration-300 h-full w-full', color)}
    >
      {icon}
    </a>
  );
};
