import {
  BaseSelect,
  BaseSelectOption,
  BooleanInput,
  DecoratedCard,
  SettingsInlineEditField,
  Form,
  LoadingIndicator,
  SlideOverOnCloseProps,
} from '@client/shared/toolkit';
import {
  BenchmarkSettingsPayload,
  Currency,
  MeasureType,
  ProjectReadModel,
  useApiGetDefaultValuesQuery,
  useApiPostUpdateProjectMetadataMutation,
  ValueType,
  YearlyValuePayload,
} from '@client/shared/api';
import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectSettingsEditSection } from '@client/shared/toolkit';
import { EditProjectFormValidationSchema, EditProjectFormValidationValues } from './EditProject';
import { safeMutation } from '@client/shared/utilities';

interface ProjectBenchmarkingSettingsProps extends SlideOverOnCloseProps {
  project?: ProjectReadModel;
  formRef: RefObject<HTMLFormElement>;
  readOnly: boolean;
}

export const ProjectBenchmarkingSettings = (props: ProjectBenchmarkingSettingsProps) => {
  const { project, formRef } = props;
  const { t } = useTranslation();

  const [isBenchmarkProject, setIsBenchmarkProject] = useState<boolean>(project?.payload.isBenchmarkProject ?? true);
  const [benchmarkSettings, setBenchmarkSettings] = useState<BenchmarkSettingsPayload | null>(null);

  const [postProjectMetadata, { isLoading }] = useApiPostUpdateProjectMetadataMutation();
  const { data: defaultValues, isFetching: isLoadingDefaultValues } = useApiGetDefaultValuesQuery();

  const projectCurrency = useMemo(() => {
    return project?.payload.currency.currency;
  }, [project?.payload.currency.currency]);

  const years = useMemo(() => {
    let startYear = project?.payload.start ? new Date(project.payload.start).getFullYear() : null;
    const endYear = project?.payload.end ? new Date(project.payload.end).getFullYear() : null;
    const years = [];
    if (startYear && endYear) {
      while (startYear <= endYear) {
        years.push(startYear);
        startYear++;
      }
    }
    return years;
  }, [project?.payload.start, project?.payload.end]);

  const yearOptions: BaseSelectOption[] = useMemo(() => {
    return years.map((year) => {
      return {
        value: year.toString(),
        label: year.toString(),
      };
    });
  }, [years]);

  useEffect(() => {
    if (project?.payload?.benchmarkSettings) {
      const benchmarkSettingsPayload: BenchmarkSettingsPayload = {
        completionYear: project.payload.benchmarkSettings.completionYear,
        regionalFactor: project.payload.benchmarkSettings.regionalFactor,
        constructionCostIncreases: project.payload.benchmarkSettings.constructionCostIncreases,
        rentalIncreases: project.payload.benchmarkSettings.rentalIncreases,
        salesIncreases: project.payload.benchmarkSettings.salesIncreases,
        currencyConversions: project.payload.benchmarkSettings.currencyConversions,
      };
      setBenchmarkSettings(benchmarkSettingsPayload);
    } else if (defaultValues && project?.payload) {
      const benchmarkSettingsPayload: BenchmarkSettingsPayload = {
        completionYear: project?.payload?.end
          ? new Date(project?.payload?.end).getFullYear() + 1
          : new Date().getFullYear() + 1,
        regionalFactor: 0,
        constructionCostIncreases: defaultValues.constructionCostIncreases
          .filter((item) => item.countryCode === project.payload.countryCode)
          .reduce((yearlyValues, values) => [...yearlyValues, ...values.yearlyValues], [] as YearlyValuePayload[]),
        rentalIncreases: defaultValues.rentalIncreases
          .filter((item) => item.countryCode === project.payload.countryCode)
          .reduce((yearlyValues, values) => [...yearlyValues, ...values.yearlyValues], [] as YearlyValuePayload[]),
        salesIncreases: defaultValues.salesIncreases
          .filter((item) => item.countryCode === project.payload.countryCode)
          .reduce((yearlyValues, values) => [...yearlyValues, ...values.yearlyValues], [] as YearlyValuePayload[]),
        currencyConversions: defaultValues.currencyConversions
          .filter((item) => item.currency === project.payload.currency.currency)
          .reduce((yearlyValues, values) => [...yearlyValues, ...values.yearlyValues], [] as YearlyValuePayload[]),
      };
      setBenchmarkSettings(benchmarkSettingsPayload);
    }
  }, [project?.payload, defaultValues]);

  const getFactorForYear = useCallback(
    (type: 'construction' | 'rental' | 'sales' | 'currency', year: number) => {
      switch (type) {
        case 'construction':
          return benchmarkSettings?.constructionCostIncreases.find((cc) => cc.year === year)?.factor ?? null;
        case 'rental':
          return benchmarkSettings?.rentalIncreases.find((cc) => cc.year === year)?.factor ?? null;
        case 'sales':
          return benchmarkSettings?.salesIncreases.find((cc) => cc.year === year)?.factor ?? null;
        case 'currency':
          return benchmarkSettings?.currencyConversions.find((cc) => cc.year === year)?.factor ?? null;
        default:
          return null;
      }
    },
    [benchmarkSettings],
  );

  const handleSubmit = async (data: EditProjectFormValidationValues) => {
    if (project?.payload.id) {
      try {
        await safeMutation(
          postProjectMetadata,
          {
            projectId: project?.payload.id,
            body: {
              projectId: data.projectId,
              name: data.name,
              street: data.street,
              houseNumber: data.houseNumber,
              postalCode: data.postalCode,
              city: data.city,
              countryCode: data.countryCode,
              vat: data.vat,
              measure: data.measure as MeasureType,
              currency: data.currency as Currency,
              calculateValueType: data.calculateValueType as ValueType,
              userDefinedFieldsPayload: undefined,
              isBenchmarkProject: isBenchmarkProject,
              benchmarkSettingsPayload: benchmarkSettings,
            },
          },
          isLoading,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleFactorChange = useCallback(
    (type: 'construction' | 'rental' | 'sales' | 'currency', val: number | null, year: number) => {
      if (benchmarkSettings) {
        const settingsCopy = { ...benchmarkSettings };
        let entries: YearlyValuePayload[] = [];
        switch (type) {
          case 'construction':
            entries = settingsCopy.constructionCostIncreases ? [...settingsCopy.constructionCostIncreases] : [];
            break;
          case 'rental':
            entries = settingsCopy.rentalIncreases ? [...settingsCopy.rentalIncreases] : [];
            break;
          case 'sales':
            entries = settingsCopy.salesIncreases ? [...settingsCopy.salesIncreases] : [];
            break;
          case 'currency':
            entries = settingsCopy.currencyConversions ? [...settingsCopy.currencyConversions] : [];
            break;
        }

        const foundIndex = entries.findIndex((value) => value.year === year);
        if (foundIndex >= 0) {
          const entryCopy = { ...entries[foundIndex] };
          entryCopy.factor = val ?? 0;
          entries[foundIndex] = entryCopy;
        } else {
          entries.push({
            year: year,
            factor: val ?? 0,
          });
        }

        switch (type) {
          case 'construction':
            settingsCopy.constructionCostIncreases = entries;
            break;
          case 'rental':
            settingsCopy.rentalIncreases = entries;
            break;
          case 'sales':
            settingsCopy.salesIncreases = entries;
            break;
          case 'currency':
            settingsCopy.currencyConversions = entries;
            break;
        }
        setBenchmarkSettings(settingsCopy);
      }
    },
    [benchmarkSettings],
  );

  const defaultFormValues = {
    projectId: project?.payload.projectId ?? '',
    name: project?.payload.name ?? '',
    street: project?.payload.street ?? '',
    houseNumber: project?.payload.number ?? '',
    postalCode: project?.payload.postalCode ?? '',
    city: project?.payload.city ?? '',
    countryCode: project?.payload.countryCode ?? undefined,
    vat: project?.payload.vat ?? undefined,
    measure: project?.payload.measure,
    currency: project?.payload.currency.currency,
    calculateValueType: project?.payload.calculateValueType,
  };

  return (
    <DecoratedCard>
      <DecoratedCard.Header showActionButton={false}>
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex">
            <div className="truncate">{t('projectSetting.benchmarking.title')}</div>
          </div>
        </div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="px-6">
        {(isLoadingDefaultValues || isLoading) && <LoadingIndicator mode="overlay" />}
        <div className="border-b mb-2">
          <BooleanInput
            label={t('projectSetting.useProjectForBenchmarking')}
            value={isBenchmarkProject}
            onChange={(val) => setIsBenchmarkProject(val)}
            variant="checkbox-left"
          />
        </div>
        {benchmarkSettings && project && (
          <Form<EditProjectFormValidationValues>
            onSubmit={handleSubmit}
            validationSchema={EditProjectFormValidationSchema}
            defaultValues={defaultFormValues}
            className="relative"
            ref={formRef}
          >
            {!isBenchmarkProject && <div className="absolute w-full h-full opacity-50 bg-white z-50" />}
            <ProjectSettingsEditSection
              title={t('projectSetting.benchmarking.title')}
              // description="Definition von Benchmarking Faktoren zur Umrechnung auf ein globales Gesamtprojekt bzw. ntuzen des zentralen Benschmarkschnitts"
            >
              <div className="mt-3 flex-none w-auto">
                <SettingsInlineEditField
                  value={`${benchmarkSettings.regionalFactor}x`}
                  valueInput={benchmarkSettings.regionalFactor}
                  label={t('projectSettings.benchmarking.regionalFactor')}
                  onChange={(val) => {
                    if (benchmarkSettings) {
                      const settingsCopy = { ...benchmarkSettings };
                      settingsCopy.regionalFactor = val ?? 0;
                      setBenchmarkSettings(settingsCopy);
                    }
                  }}
                />
              </div>
            </ProjectSettingsEditSection>
            <ProjectSettingsEditSection title={t('projectSettings.benchmarking.increaseConstructionCosts')}>
              <div className="mt-3 flex gap-1 flex-none w-auto overflow-x-auto pb-2">
                {years.map((year) => (
                  <SettingsInlineEditField
                    key={`benchmarking-cost-factor-year-${year.toString()}`}
                    value={`${getFactorForYear('construction', year) ?? '-'}% ${t(
                      'projectSettings.benchmarking.perYearLabel',
                    )}`}
                    valueInput={getFactorForYear('construction', year)}
                    label={year.toString()}
                    onChange={(val) => handleFactorChange('construction', val, year)}
                  />
                ))}
              </div>
            </ProjectSettingsEditSection>
            <ProjectSettingsEditSection title={t('projectSettings.benchmarking.rentIncrease')}>
              <div className="mt-3 flex gap-1 flex-none w-auto overflow-x-auto pb-2">
                {years.map((year) => (
                  <SettingsInlineEditField
                    key={`benchmarking-rent-increase-year-${year.toString()}`}
                    value={`${getFactorForYear('rental', year) ?? '-'}% ${t(
                      'projectSettings.benchmarking.perYearLabel',
                    )}`}
                    valueInput={getFactorForYear('rental', year)}
                    label={year.toString()}
                    onChange={(val) => handleFactorChange('rental', val, year)}
                  />
                ))}
              </div>
            </ProjectSettingsEditSection>
            <ProjectSettingsEditSection title={t('projectSettings.benchmarking.salesIncreases')}>
              <div className="mt-3 flex gap-1 flex-none w-auto overflow-x-auto pb-2">
                {years.map((year) => (
                  <SettingsInlineEditField
                    key={`benchmarking-sales-increase-year-${year.toString()}`}
                    value={`${getFactorForYear('sales', year) ?? '-'}% ${t(
                      'projectSettings.benchmarking.perYearLabel',
                    )}`}
                    valueInput={getFactorForYear('sales', year)}
                    label={year.toString()}
                    onChange={(val) => handleFactorChange('sales', val, year)}
                  />
                ))}
              </div>
            </ProjectSettingsEditSection>
            <ProjectSettingsEditSection title={t('projectSettings.benchmarking.currencyConversion')}>
              <div className="mt-3 flex gap-1 flex-none w-auto overflow-x-auto pb-2">
                {years.map((year) => (
                  <SettingsInlineEditField
                    key={`benchmarking-currency-factor-year-${year.toString()}`}
                    value={`${getFactorForYear('currency', year) ?? '-'} ${projectCurrency?.toUpperCase()}/${
                      defaultValues?.currency.toUpperCase() ?? 'EUR'
                    }`}
                    valueInput={getFactorForYear('currency', year)}
                    label={year.toString()}
                    onChange={(val) => handleFactorChange('currency', val, year)}
                  />
                ))}
              </div>
            </ProjectSettingsEditSection>
            <ProjectSettingsEditSection title={t('projectSettings.benchmarking.completionDate')}>
              <div className="mt-3 flex-none w-[250px]">
                <BaseSelect
                  label={t('projectSettings.benchmarking.completionDateYear')}
                  value={benchmarkSettings.completionYear.toString()}
                  options={yearOptions}
                  onChange={(val) => {
                    if (benchmarkSettings) {
                      const settingsCopy = { ...benchmarkSettings };
                      settingsCopy.completionYear = parseInt(val);
                      setBenchmarkSettings(settingsCopy);
                    }
                  }}
                  nullable
                  placeHolder="JJJJ"
                  variant="medium"
                />
              </div>
            </ProjectSettingsEditSection>
          </Form>
        )}
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};
