import { useTranslation } from 'react-i18next';
import { DistributionModal, DistributionModalAddButton } from '@client/project/shared';
import { FinancingDistributionType } from './FinancingDistribution';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FinancingDistributionModalHeader } from './FinancingDistributionModalHeader';
import {
  DistributionFrequency,
  DistributionType,
  ElementTimelineReadModel,
  FinancePayoutTypeEnum,
  FinancingPaybackPlanElementReadModel,
  FinancingPaymentPlanElementReadModel,
  PaymentPlanType,
  RepaymentMethod,
  useApiGetFinancingElementsDistributionSuggestionMutation,
  useApiPostCalculateAutomaticTimelineDistributionMutation,
} from '@client/shared/api';
import { Button, HintBox } from '@client/shared/toolkit';
import { RenderFinancingPaybackElement, RenderFinancingPayoutElement } from '../index';
import { formatDateOnly } from '@client/shared/utilities';
import { useLoadedProjectId, useLoadedVariant, useLoadedVariantId } from '@client/project/store';
import { formatISO } from 'date-fns';
import { FormattedCurrency } from '@client/project/shared';

const updateOrder = (planToOrder: FinancingPaymentPlanElementReadModel[]): FinancingPaymentPlanElementReadModel[] => {
  const elements = [...planToOrder];
  return elements.map((v, i) => {
    if ((v as FinancingPaybackPlanElementReadModel).rest) {
      v = { ...v, order: elements.length + 1 };
    } else {
      v = { ...v, order: i + 1 };
    }
    return v;
  });
};

interface FinancingDistributionModalProps {
  type: FinancingDistributionType;
  selectedPaymentPlan: PaymentPlanType;
  disabled: boolean;
  variantId?: string;
  timing?: ElementTimelineReadModel | null;
  setTimeLine: (value: ElementTimelineReadModel | null) => void;
  setPaymentPlanType: (value: PaymentPlanType) => void;
  isRecurringPayback?: boolean;
  residualDebt?: number | null;
  setResidualDebt?: (value: number | null | undefined) => void;
  paymentPlan: FinancingPaymentPlanElementReadModel[] | FinancingPaybackPlanElementReadModel[];
  onClose: () => void;
  savePaymentPlan: (plan: FinancingPaymentPlanElementReadModel[] | FinancingPaybackPlanElementReadModel[]) => void;
  nominalAmount?: number;
  ltc: number | null;
  costCatalogElementIds: string[];
  isLoan?: boolean;
  repaymentMethod?: RepaymentMethod;
  setRepaymentMethod?: (method: RepaymentMethod) => void;
  isLocked?: boolean;
  annuityPercentage?: number | null;
  setAnnuityPercentage?: (value: number | null | undefined) => void;
}

export const FinancingDistributionModal = (props: FinancingDistributionModalProps) => {
  const {
    type,
    selectedPaymentPlan,
    disabled,
    variantId,
    timing,
    setTimeLine,
    setPaymentPlanType,
    isRecurringPayback = false,
    residualDebt,
    setResidualDebt,
    paymentPlan,
    onClose,
    savePaymentPlan,
    nominalAmount,
    ltc,
    costCatalogElementIds,
    isLoan = false,
    repaymentMethod,
    setRepaymentMethod,
    isLocked = false,
    annuityPercentage,
    setAnnuityPercentage,
  } = props;
  const { t } = useTranslation();

  const loadedCalculationModelId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModel = useLoadedVariant();

  const [calculationModelEndDate, setCalculationModelEndDate] = useState<Date>(new Date());

  useEffect(() => {
    if (loadedCalculationModel.data?.calculationModel?.modelMetadata?.calculationModelEndDate) {
      setCalculationModelEndDate(
        new Date(loadedCalculationModel.data.calculationModel.modelMetadata.calculationModelEndDate)
      );
    }
  }, [loadedCalculationModel]);

  const [getDistribution, { isLoading: isLoadingDistribution }] =
    useApiPostCalculateAutomaticTimelineDistributionMutation();
  const [getDistributionSuggestion, { isLoading: isLoadingSuggestion }] =
    useApiGetFinancingElementsDistributionSuggestionMutation();

  const title = useMemo(() => {
    return type === FinancingDistributionType.PAYOUT
      ? t('projectCalculate.financingElementLabelPayoutPlan')
      : t('projectCalculate.financingElementLabelPaybackPlan');
  }, [type, t]);

  const description = useMemo(() => {
    return type === FinancingDistributionType.PAYOUT
      ? t('projectCalculate.financingDistributionModalPayoutDescription')
      : t('projectCalculate.financingDistributionModalPaybackDescription');
  }, [type, t]);

  const [showLoadedData, setShowLoadedData] = useState(false);
  const [selectedSuggestionType, setSelectedSuggestionType] = useState<FinancePayoutTypeEnum | 'None'>('None');

  const addNewElement = useCallback(() => {
    const extendedPlan = [
      ...paymentPlan,
      {
        id: '',
        description: '',
        amount: 0,
        percentage: null,
        paymentOn: formatDateOnly(new Date()),
        order: paymentPlan.length + 1,
        rest: type === FinancingDistributionType.PAYBACK ? false : undefined,
      },
    ];

    savePaymentPlan(updateOrder(extendedPlan));
  }, [paymentPlan, type, savePaymentPlan]);

  const setPaybackRestElementDate = (plans: FinancingPaybackPlanElementReadModel[]) => {
    if (selectedPaymentPlan === 'Variable' && type === FinancingDistributionType.PAYBACK) {
      const rest = plans.find((plan) => {
        return plan.rest;
      });
      if (rest) {
        const updatedRest = { ...rest };
        const restIndex = plans.findIndex((value) => {
          return value.rest;
        });
        // get the "newest" date of all elements
        let newestDate = new Date(rest.paymentOn);
        plans.forEach((elem) => {
          if (elem.paymentOn && new Date(elem.paymentOn) > newestDate && !elem.rest) {
            newestDate = new Date(elem.paymentOn);
          }
        });
        if (formatISO(newestDate, { representation: 'date' }) !== rest.paymentOn) {
          updatedRest.paymentOn = formatISO(newestDate, { representation: 'date' });
        }
        updatedRest.order = plans.length + 1;
        plans[restIndex] = updatedRest;
      }
    }
    return plans;
  };

  const onItemChange = useCallback(
    (item: FinancingPaymentPlanElementReadModel | FinancingPaybackPlanElementReadModel, index: number) => {
      let plans = [...paymentPlan];
      plans[index] = item;
      // distribution got changed by loaded data (not user input)
      if (showLoadedData) {
        setShowLoadedData(false);
      } else {
        // when distribution was changed by user, automatically switch to variable
        if (selectedPaymentPlan !== 'Variable') {
          setPaymentPlanType('Variable');
        }
      }

      // if payback plan is updated and the date is larger than the rest payment on date,
      // we need to update the date of the rest element to be the latest
      if (selectedPaymentPlan === 'Variable' && type === FinancingDistributionType.PAYBACK) {
        plans = [...setPaybackRestElementDate(plans as FinancingPaybackPlanElementReadModel[])];
      }
      savePaymentPlan(updateOrder(plans));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentPlan, showLoadedData, selectedPaymentPlan, setPaymentPlanType, type, savePaymentPlan]
  );

  const onItemDeleted = useCallback(
    (item: FinancingPaymentPlanElementReadModel | FinancingPaybackPlanElementReadModel) => {
      const orders = [...paymentPlan];
      const ordered = updateOrder(orders.filter((x) => x.order !== item.order));
      savePaymentPlan(ordered);
    },
    [paymentPlan, savePaymentPlan]
  );

  const DistributionModalFooter = useMemo(() => {
    if (type === FinancingDistributionType.PAYOUT && nominalAmount) {
      const totalPayout =
        paymentPlan.length > 0
          ? paymentPlan
              .map((a) => a.amount)
              .reduce(function (a, b) {
                return (a ?? 0) + (b ?? 0);
              }) ?? 0
          : 0;

      const restAmount = nominalAmount - totalPayout;
      // avoid showing -0
      if (parseFloat(restAmount.toFixed(2)) !== 0) {
        return (
          <div className="flex items-center justify-end py-2 pl-2">
            <div className="font-medium text-gray-900 pr-2">
              {t('projectCalculate.financingElementLabelRestAmount')}:
            </div>
            <div className="flex flex-row items-center p-2 space-x-2">
              <div>
                <FormattedCurrency amount={restAmount} options={{ maxDigits: 2 }} />
              </div>
            </div>
          </div>
        );
      }
      return null;
    }
    return null;
  }, [nominalAmount, paymentPlan, t, type]);

  /**
   * AllInTheBeginning: DistributionType: AllAtTheStart, Grain: Month
   * MonthlyLinear: DistributionType: Constant, Grain: Month
   * QuarterlyLinear: DistributionType: Constant, Grain: QuarterYear
   * Variable: DistributionType: Manual, Grain: None
   */
  const fetchPaymentDistribution = useCallback(
    (paymentPlanType: PaymentPlanType, timeline?: ElementTimelineReadModel | null | undefined, save = true) => {
      // always reset automatic suggestion
      setShowLoadedData(true);
      setSelectedSuggestionType('None');

      const fetchDistributionValues = async (
        distributionType: DistributionType,
        distributionFrequency: DistributionFrequency
      ) => {
        if (!timeline) return;
        if(timeline.effectiveStartDate && timeline.effectiveEndDate && new Date(timeline.effectiveStartDate) < new Date(timeline.effectiveEndDate)) {
          try {
            // const startDate = timeline.effectiveStartDate;// ? ToISODateTimeStringWithEmptyTimeZone(timeline.effectiveStartDate) : ''
            // const endDate = timeline.effectiveEndDate ? ToISODateTimeStringWithEmptyTimeZone(timeline.effectiveEndDate) : startDate
            return await getDistribution({
              projectId: loadedProjectId ?? 'unset',
              calculationModelId: loadedCalculationModelId ?? 'unset',
              body: {
                distributionType: distributionType ?? 'AllAtTheStart',
                start: timeline.effectiveStartDate ?? '',
                end: timeline.effectiveEndDate ?? timeline.effectiveStartDate ?? '',
                value: nominalAmount ?? 0,
                distributionFrequency: distributionFrequency ?? 'Month',
              },
            }).unwrap();
          } catch (error) {
            console.log(error);
            return [];
          }
        }
      };

      if (
        timeline &&
        paymentPlanType &&
        paymentPlanType !== 'Variable' &&
        (type === FinancingDistributionType.PAYOUT ||
          (type === FinancingDistributionType.PAYBACK && paymentPlanType === 'AllInTheBeginning'))
      ) {
        const distributionType = paymentPlanType === 'AllInTheBeginning' ? 'AllAtTheStart' : 'Constant';
        const grain = paymentPlanType === 'QuarterlyLinear' ? 'QuarterYear' : 'Month';
        fetchDistributionValues(distributionType, grain).then((distributionValues) => {
          if (distributionValues?.length) {
            const updatedPaymentPlan = distributionValues.map((dist, i) => {
              return {
                id: null,
                order: i,
                description: '',
                paymentOn: dist.effectiveStart,
                amount: dist.value,
                percentage: nominalAmount ? (dist.value / nominalAmount) * 100 : 0,
              };
            });
            savePaymentPlan(updatedPaymentPlan);
          }
        });
      } else if (paymentPlanType === 'EndOfTheProject') {
        savePaymentPlan([]);
        timeline = {
          startCalculationModelDeliveryPhaseId: null,
          startCalculationModelMileStoneId: null,
          startElementTimelineId: null,
          startOffset: timeline?.startOffset,
          startType: 'ProjectEnd',
          effectiveStartDate: formatDateOnly(calculationModelEndDate),
          duration: 0,
          endDate: formatDateOnly(calculationModelEndDate),
          endOffset: timeline?.startOffset,
          endType: 'ProjectEnd',
        };
      }

      if (save) {
        if (timeline) {
          setTimeLine(timeline);
        }
        setPaymentPlanType(paymentPlanType);
      }
    },
    [
      getDistribution,
      nominalAmount,
      savePaymentPlan,
      setPaymentPlanType,
      loadedCalculationModelId,
      loadedProjectId,
      setTimeLine,
      setShowLoadedData,
      type,
      calculationModelEndDate,
    ]
  );

  // ProRataPayout
  const fetchDistributionSuggestion = useCallback(
    (suggestionType: FinancePayoutTypeEnum) => {
      setPaymentPlanType('Variable');

      const fetchDistributionSuggestionValues = async () => {
        if (ltc && costCatalogElementIds.length && nominalAmount && !isLoadingSuggestion) {
          try {
            const result = await getDistributionSuggestion({
              projectId: loadedProjectId ?? 'unset',
              calculationModelId: loadedCalculationModelId ?? 'unset',
              body: {
                nominalAmount: nominalAmount,
                percentage: ltc,
                costCatalogElementIds: costCatalogElementIds,
                includeVat: false,
                payoutType: suggestionType,
              },
            }).unwrap();
            return result.monthlyDistributionSuggestions;
          } catch (e) {
            console.log(e);
          }
        }
        return [];
      };

      fetchDistributionSuggestionValues().then((distributionValues) => {
        if (distributionValues?.length) {
          const updatedPaymentPlan = distributionValues.map((dist, i) => {
            return {
              id: null,
              order: i,
              description: '',
              paymentOn: dist.date,
              amount: dist.totalSuggestedPayout,
              percentage: nominalAmount ? (dist.totalSuggestedPayout / nominalAmount) * 100 : 0,
            };
          });
          savePaymentPlan(updatedPaymentPlan);
        } else {
          savePaymentPlan([]);
        }
      });
    },
    [
      setPaymentPlanType,
      ltc,
      costCatalogElementIds,
      nominalAmount,
      isLoadingSuggestion,
      getDistributionSuggestion,
      savePaymentPlan,
      loadedCalculationModelId,
      loadedProjectId,
    ]
  );

  useEffect(() => {
    if (timing && selectedPaymentPlan && !isLoadingDistribution) {
      if (
        (selectedPaymentPlan === 'AllInTheBeginning' && timing.effectiveStartDate) ||
        (selectedPaymentPlan !== 'AllInTheBeginning' && timing.effectiveStartDate && timing.effectiveEndDate)
      ) {
        fetchPaymentDistribution(selectedPaymentPlan, timing, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timing]);

  useEffect(() => {
    if (selectedPaymentPlan === 'Variable' && type === FinancingDistributionType.PAYBACK) {
      // for payback we need to set rest value
      const updatedPlan = [...paymentPlan] as FinancingPaybackPlanElementReadModel[];
      const rest = updatedPlan.find((value) => {
        return value.rest;
      });
      if (!rest) {
        const lastPlanPaymentOn = updatedPlan.length
          ? updatedPlan[updatedPlan.length - 1].paymentOn
          : formatISO(new Date(), { representation: 'date' });
        const restElement = {
          id: null,
          order: updatedPlan.length + 1,
          description: '',
          amount: null,
          paymentOn: lastPlanPaymentOn,
          rest: true,
        } as FinancingPaybackPlanElementReadModel;
        updatedPlan.push(restElement);
        savePaymentPlan(updateOrder(updatedPlan));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentPlan]);

  const restElement = useMemo(() => {
    let index = 0;
    let restElement: FinancingPaybackPlanElementReadModel | undefined = undefined;
    if (paymentPlan && paymentPlan.length) {
      restElement = (paymentPlan as FinancingPaybackPlanElementReadModel[]).find((value, i) => {
        if (value.rest) {
          index = i;
        }
        return value.rest;
      });
    }
    return {
      element: restElement,
      index: index,
    };
  }, [paymentPlan]);

  const onClearTimeline = () => {
    setTimeLine(null);
  };

  return (
    <DistributionModal
      title={title}
      description={description}
      header={
        <FinancingDistributionModalHeader
          className="w-full px-12 border-b-2 shadow-lg z-10 relative"
          type={type}
          selectedPaymentPlan={selectedPaymentPlan}
          disabled={disabled}
          variantId={variantId}
          timing={timing}
          setTimeLine={(timeline) => {
            setTimeLine(timeline);
            // fetchPaymentDistribution(selectedPaymentPlan, timeline);
          }}
          setPaymentPlanType={(paymentPlanType) => {
            fetchPaymentDistribution(paymentPlanType, timing);
          }}
          suggestionType={selectedSuggestionType}
          setSuggestionType={(suggestionType) => {
            if (suggestionType !== selectedSuggestionType) {
              setSelectedSuggestionType(suggestionType);
              if (suggestionType !== 'None') {
                fetchDistributionSuggestion(suggestionType);
              }
            }
          }}
          isRecurringPayback={isRecurringPayback}
          residualDebt={residualDebt}
          setResidualDebt={setResidualDebt}
          showAutoSuggestion={ltc !== null && costCatalogElementIds.length > 0}
          isLoan={isLoan}
          repaymentMethod={repaymentMethod}
          setRepaymentMethod={setRepaymentMethod}
          isLocked={isLocked}
          annuityPercentage={annuityPercentage}
          setAnnuityPercentage={setAnnuityPercentage}
        />
      }
      controls={
        <div className="w-full flex justify-between">
          {timing && (
            <Button variant="text" className="text-sm flex-none" disabled={disabled} onClick={onClearTimeline}>
              {t('projectCalculate.ClearTimelineButton')}
            </Button>
          )}
          <div className="flex space-x-2 w-full justify-end">
            <Button onClick={onClose} className="mr-2" variant="secondary">
              {t('common.cancel')}
            </Button>
            <Button
              onClick={() => {
                savePaymentPlan(paymentPlan);
                onClose();
              }}
              disabled={timing?.effectiveEndDate === null}
            >
              {t('common.save')}
            </Button>
          </div>
        </div>
      }
      footer={DistributionModalFooter}
    >
      <>
        <div className="divide-y">
          {type === FinancingDistributionType.PAYOUT &&
            paymentPlan.map((payoutElement, i) => {
              return (
                <RenderFinancingPayoutElement
                  key={`payout-element-${payoutElement.id}-${i}`}
                  item={payoutElement as FinancingPaymentPlanElementReadModel}
                  onItemChange={(item) => onItemChange(item, i)}
                  onItemDeleted={onItemDeleted}
                />
              );
            })}
          {type === FinancingDistributionType.PAYBACK &&
            selectedPaymentPlan !== 'MonthlyLinear' &&
            selectedPaymentPlan !== 'QuarterlyLinear' &&
            paymentPlan.length > 0 && (
              <>
                {(paymentPlan as FinancingPaybackPlanElementReadModel[]).map((paybackElement, i) => {
                  if (!paybackElement.rest) {
                    return (
                      <RenderFinancingPaybackElement
                        key={`payback-element-${paybackElement.id}-${i}`}
                        item={paybackElement}
                        onItemChange={(item) => onItemChange(item, i)}
                        onItemDeleted={onItemDeleted}
                      />
                    );
                  }
                  return null;
                })}
                {restElement.element && (
                  <RenderFinancingPaybackElement
                    key={`payback-element-rest-rest-${restElement.element.id}-${restElement.index}`}
                    item={restElement.element}
                    onItemChange={(item) => onItemChange(item, restElement.index)}
                    onItemDeleted={onItemDeleted}
                  />
                )}
              </>
            )}
          {type === FinancingDistributionType.PAYBACK &&
            (selectedPaymentPlan === 'MonthlyLinear' || selectedPaymentPlan === 'QuarterlyLinear') && (
              <HintBox hintType="info">{t('projectCalculate.financingElement.paybackModal.generateHint')}</HintBox>
            )}
        </div>
        {/* Add Button */}
        {selectedPaymentPlan === 'Variable' && <DistributionModalAddButton onClick={addNewElement} />}
      </>
    </DistributionModal>
  );
};
