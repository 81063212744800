import { PropsWithChildren } from 'react';
import cn from 'classnames';

interface ProjectSettingsEditSectionProps extends PropsWithChildren {
  title?: string;
  description?: string;
  className?: string;
}
export const ProjectSettingsEditSection = (props: ProjectSettingsEditSectionProps) => {
  const {
    title,
    description,
    className,
    children
  } = props;
  return (
    <div className={cn('my-4', className)}>
      {title && <span className="font-bold text-sm">{title}</span>}
      {description && <div className="text-sm">{description}</div>}
      {children}
    </div>
  )
}
