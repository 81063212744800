import { DistributionType } from "@client/shared/api";

const createDistributionTypesArray = <T extends DistributionType[]>(...args: T) => args;

export function getAllDistributionTypes(): DistributionType[]{
    return createDistributionTypesArray(
        'None',
        'AllAtTheStart',
        'AllAtTheEnd',
        'Constant',
        'Progressive',
        'Degressive',
        'ReverseCurve',
        'Manual',
        'Effective',
        'BellCurve'
      );
}