import { useTranslation } from 'react-i18next';
import {
  InvoiceCalculationSchemeReadModel,
  useApiGetProjectInvoiceCalculationSchemesQuery,
  useApiGetTenantInvoiceCalculationSchemesQuery
} from '@client/shared/api';
import { InvoiceCoverSheetsListItem } from './InvoiceCoverSheetsListItem';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AddButton,
  ContextMenu,
  ContextMenuItem,
  FloatingActionButton,
  AddIcon,
  SettingsListHeader,
  SettingsListItemTitle,
  Modal, LoadingIndicator,
} from '@client/shared/toolkit';
import { Square2StackIcon } from '@heroicons/react/24/outline';
import { InvoiceCoverSheetCreateWizard } from './InvoiceCoverSheetCreateWizard';
import { InvoiceCoverSheetDeleteModal } from './InvoiceCoverSheetDeleteModal';
import { InvoiceCoverSheetEditSlideOver } from './InvoiceCoverSheetEditSlideOver';
import { InvoiceCoverSheetCopyModal } from './InvoiceCoverSheetCopyModal';

export type IcsType = 'project' | 'tenant';

type InvoiceCoverSheetsProps = {
  searchText?: string;
  projectId?: string;
  type: IcsType;
};
export const InvoiceCoverSheets = (props: InvoiceCoverSheetsProps) => {
  const { searchText, projectId, type } = props;
  const { t } = useTranslation();

  const { data: loadedSheets, isFetching } = useApiGetProjectInvoiceCalculationSchemesQuery(
    { projectId: projectId ?? '' },
    { skip: !projectId  || type !== 'project' },
  );
  const { data: tenantSheets, isFetching: isFetchingTenantSheets } = useApiGetTenantInvoiceCalculationSchemesQuery(undefined, {
    skip: type !== 'tenant'
  });

  const [openSlideOver, setOpenSlideOver] = useState<'edit' | 'delete' | 'create' | 'copy' | null>(null);
  const [isChildSlideOverOpen, setIsChildSlideOverOpen] = useState(false);
  const [selectedSheet, setSelectedSheet] = useState<InvoiceCalculationSchemeReadModel | null>(null);
  const [createCopyFromTenant, setCopyFromTenant] = useState(false);

  const sheets = useMemo(() => {
    return loadedSheets ?? tenantSheets ?? [];
  }, [loadedSheets, tenantSheets]);

  useEffect(() => {
    if (sheets?.length && selectedSheet) {
      const foundSelectedSheet = sheets?.find(
        (sheet) => sheet.invoiceCalculationSchemeId === selectedSheet.invoiceCalculationSchemeId,
      );
      if (foundSelectedSheet) {
        setSelectedSheet(foundSelectedSheet);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheets]);

  const filteredSheets = useMemo(() => {
    if (searchText) {
      return sheets?.filter((sheet) => sheet.name.toLowerCase().includes(searchText.trim().toLowerCase())) ?? [];
    }
    return sheets;
  }, [sheets, searchText])

  const contextItems: ContextMenuItem[] = useMemo(() => {
    const items: ContextMenuItem[] = [
      {
        label: t('app.settingsCreateInvoiceCoverSheetTitle'),
        subtitle: t('app.settingsCreateInvoiceCoverSheetSubtitle'),
        icon: <AddIcon />,
        onClick: () => {
          setOpenSlideOver('create');
        },
      },
      {
        label: t('common.copy'),
        subtitle: t('ics.copyInvoiceCoverSheet'),
        icon: <Square2StackIcon />,
        onClick: () => {
          setCopyFromTenant(false)
          setOpenSlideOver('copy');
        },
        isDisabled: !sheets?.length
      },
    ]
    if (type === 'project') {
      items.push( {
        label: t('ics.copyFromTenant'),
        subtitle: t('ics.copyInvoiceCoverSheetFromTenant'),
        icon: <Square2StackIcon />,
        onClick: () => {
          setCopyFromTenant(true)
          setOpenSlideOver('copy');
        },
      },)
    }

    return items
  }, [t, sheets?.length, type])

  const handleItemAction = (
    sheet: InvoiceCalculationSchemeReadModel,
    action: 'delete' | 'edit' | 'copy'
  ) => {
    setSelectedSheet(sheet);
    setOpenSlideOver(action);
  };

  const closeSlideOver = () => {
    setCopyFromTenant(false);
    setSelectedSheet(null);
    setOpenSlideOver(null);
  };

  return (
    <div className="my-5">
      {(isFetching || isFetchingTenantSheets) && (<LoadingIndicator text={t('app.settingsLoadingInvoiceCoverSheets')} mode="overlay" />)}

      {filteredSheets && filteredSheets.length > 0 ? (
        <>
          <SettingsListHeader>
            <div className='w-6/12'>
              <SettingsListItemTitle customTitle={t('common.name')} />
            </div>
            <div className='w-6/12'>
              <SettingsListItemTitle customTitle={t('app.settingsInvoiceCoverSheetDefaultFlag')} />
            </div>
          </SettingsListHeader>
          <ul>
            {filteredSheets.map((sheet) => (
              <InvoiceCoverSheetsListItem
                key={`invoice-cover-sheet-${sheet.invoiceCalculationSchemeId}`}
                sheet={sheet}
                searchText={searchText}
                handleAction={(action: 'delete' | 'edit' | 'copy') => handleItemAction(sheet, action)}
              />
            ))}
          </ul>
        </>
      ) : (
        <div>{t('ics.noCoverSheetsMessage')}</div>
      )}

      {!projectId ? (
        <FloatingActionButton menuItems={contextItems} position="fixed bottom-16 right-24" />
      ) : (
        <>
          <div className="h-14" />
          <div className="z-10 absolute right-5 -bottom-5">
            <div>
              <ContextMenu items={contextItems} button={<AddButton />} />
            </div>
          </div>
        </>
      )}

      {/* CREATE WIZARD */}
      <InvoiceCoverSheetCreateWizard
        isOpen={openSlideOver === 'create'}
        onClose={closeSlideOver}
        projectId={projectId}
        type={type}
      />

      {/* DELETE MODAL */}
      <InvoiceCoverSheetDeleteModal
        isOpen={openSlideOver === 'delete'}
        onClose={closeSlideOver}
        sheet={selectedSheet}
        type={type}
        projectId={projectId}
      />

      {/* EDIT SLIDE OVER */}
      <InvoiceCoverSheetEditSlideOver
        onClose={() => {
          if (!isChildSlideOverOpen) {
            setOpenSlideOver(null);
          }
        }}
        onAfterLeave={() => setSelectedSheet(null)}
        isOpen={openSlideOver === 'edit'}
        sheet={selectedSheet}
        type={type}
        projectId={projectId}
        setIsChildSlideOverOpen={setIsChildSlideOverOpen}
      />

      {/* COPY MODAL */}
      <Modal
        isOpen={openSlideOver === 'copy'}
        onClose={closeSlideOver}
        onAfterLeave={() => setSelectedSheet(null)}
      >
        <InvoiceCoverSheetCopyModal
          onClose={closeSlideOver}
          sheet={selectedSheet}
          sheets={sheets}
          type={type}
          projectId={projectId}
          createCopyFromTenant={createCopyFromTenant}
        />
      </Modal>
    </div>
  );
};
