import {
  CalculationModelRiskElementReadModel,
  CalculationModelRiskGroupReadModel,
  RiskElementShortReadDto,
  TimelineErrors,
} from '@client/shared/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DecoratedElement } from './useCosts';
import {filterElementsByCodes, filterElementsById} from '../utils';

export type RiskElement = {
  key: string;
  group?: CalculationModelRiskGroupReadModel;
  riskElement?: CalculationModelRiskElementReadModel;
  showError: boolean;
  errors: TimelineErrors[];
  type: 'group' | 'element' | 'summary';
};

const unassignedGroupId = 'unassigned';
const nonRiskGroupId = '00000000-0000-0000-0000-000000000000';
/**
 * Converts RiskElementShortReadDto[] to DecoratedRiskElement[]
 */
function decorateElements(
  elements?: RiskElementShortReadDto[],
  level = 0,
  parent?: DecoratedElement<RiskElement>
): DecoratedElement<RiskElement>[] {
  if (elements == null || elements.length === 0) return [];

  return elements.map((element) => {
    if (element.riskElement) {
      const decoratedRiskElement: DecoratedElement<RiskElement> = {
        element: {
          key: element.riskElement.riskElementId ?? 'id',
          type: 'element',
          errors: element.riskElement.timelineErrors,
          riskElement: element.riskElement,
          showError: element.riskElement.timelineErrors.length > 0,
        },
        categoryId: element.riskElement.groupId ?? unassignedGroupId,
        level,
        parent,
        children: [],
      };
      return decoratedRiskElement;
    }

    if (element.group == null) throw new Error('not a risk element, not a group');

    const decorated: DecoratedElement<RiskElement> = {
      element: {
        key: element.group.groupId ?? '',
        group: element.group ?? undefined,
        showError:
          element.group.calculationState === 'Overflow' ||
          (element.group.timelineElement && element.group.timelineElement?.errors.length > 0) ||
          false,
        type: 'group',
        errors: element.group.timelineElement?.errors ?? [],
      },
      categoryId: element.group.groupId ?? unassignedGroupId,
      children: [],
      level: level,
      parent,
      disableClick: !element.group.groupId || element.group.groupId === unassignedGroupId || element.group.groupId === nonRiskGroupId,
    };

    const children = element.group.children ?? [];

    decorated.children = decorateElements(children, level + 1, decorated);
    return decorated;
  });
}

export const useRisks = (risks: RiskElementShortReadDto[], filteredCodes?: string[], searchValue?: string, searchResults?: string[] | null,) => {
  const { t } = useTranslation();

  return useMemo(() => {
    // Take the elements from the server (or empty array)
    let allElements: RiskElementShortReadDto[] = risks;

    // keep only elements with group
    const elements = allElements.filter((e) => e.group || e.riskElement?.groupId);

    // extract unassigned elements
    const unassigned = allElements.filter((e) => e.riskElement && !e.riskElement.groupId);

    // group unassigned elements into mocked group
    if (unassigned.length > 0) {
      // backend does not group unassigned cost elements so grouping has to be done on frontend
      const mockedGeneralRisksGroup: CalculationModelRiskGroupReadModel = {
        calculationState: 'JustTotal',
        children: unassigned,
        groupId: unassignedGroupId,
        timelineElement: undefined,
        code: '',
        description: t('projectCalculate.calculateRisksElementGroupUnassigned'),
        hasFormula:false,
        total: unassigned.reduce((total, element) => {
          const elementSum = element.riskElement?.total || 0;
          return total + elementSum;
        }, 0),
      };

      allElements = [{ group: mockedGeneralRisksGroup, riskElement: null }, ...elements];
    }

    // decorate all elements while preserving all items and structure
    let filteredElements = decorateElements(allElements);

    // filter out elements
    filteredElements = filterElementsByCodes<RiskElement>(filteredElements, filteredCodes);

    if (searchValue && searchResults) {
      filteredElements = filterElementsById(filteredElements, searchResults);
    }

    return filteredElements;
  }, [risks, filteredCodes, searchValue, searchResults, t]);
};
