import {
  BaseSelect,
  // BooleanInput,
  BrowserIcon,
  Button,
  CircleNotchIcon,
  ConnectedIcon,
  Form,
  FormField,
  FormRefHandle,
  FormWatch,
  Modal,
  SlideOver,
  SlideOverOnCloseProps,
  SlideOverTitle,
  // SortingArrowsIcon,
  TextInput,
} from '@client/shared/toolkit';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DatevConnectionResponse,
  DatevDataFormat,
  DatevProbisMappingReadModel,
  DatevProperty,
  SynchronizingObjectType,
  useApiAddProjectDatevProbisMappingMutation,
  useApiAddTenantDatevProbisMappingMutation,
  useApiUpdateProjectDatevProbisMappingMutation,
  useApiUpdateTenantDatevProbisMappingMutation,
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { DatevMappingAddFormValidationSchema, DatevMappingAddFormValidationValues, DatevMappingDeleteModal } from '.';
import classNames from 'classnames';

interface DatevMappingAddSlideOverProps extends SlideOverOnCloseProps {
  isOpen: boolean;
  item?: DatevProbisMappingReadModel | null;
  apiAccessId?: string;
  projectId?: string;
  connectionData: DatevConnectionResponse | undefined;
}

export const DatevMappingAddSlideOver = ({
  isOpen,
  item,
  onClose,
  apiAccessId,
  projectId,
  connectionData,
}: DatevMappingAddSlideOverProps) => {
  const { t } = useTranslation();

  const isEdit = item !== null;

  const formRef = useRef<FormRefHandle<DatevMappingAddFormValidationValues>>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [addTenantMapping, { isLoading: isAddingTenantMapping }] = useApiAddTenantDatevProbisMappingMutation();
  const [addProjectMapping, { isLoading: isAddingProjectMapping }] = useApiAddProjectDatevProbisMappingMutation();
  const [updateTenantMapping, { isLoading: isUpdatingTenantMapping }] = useApiUpdateTenantDatevProbisMappingMutation();
  const [updateProjectMapping, { isLoading: isUpdatingProjectMapping }] =
    useApiUpdateProjectDatevProbisMappingMutation();
  const [datevPropertyType, setDatevPropertyType] = useState<string>('');
  const [submitted, setSubmitted] = useState(false);

  const [objectTypeReference, setObjectTypeReference] = useState<string>(item?.type.toLowerCase() ?? '');

  const [probisPropertyOptions, setProbisPropertyOptions] = useState<{ label: string; value: string }[]>([]);
  const [userDefinedFieldOptions, setUserDefinedFieldOptions] = useState<{ label: string; value: string }[]>([]);

  const [selectedDatevPropertyName, setSelectedDatevPropertyName] = useState<string>('');
  const [selectedDatevProperty, setSelectedDatevProperty] = useState<DatevProperty | undefined>(undefined);

  const inputOptions = [
    { value: 'probisPropertyName', label: t('app.settingsApiDatevConnectionProbisObjectId') },
    { value: 'userDefinedFieldName', label: t('app.settingsApiDatevConnectionCustomField') },
    { value: 'defaultValue', label: t('app.settingsApiDatevConnectionDefaultValue') },
  ];

  const objectTypeOptions = useMemo(() => {
    return Object.keys(connectionData?.probisPropertiesObject.probisProperties ?? {}).map((objectType) => {
      return {
        label: objectType,
        value: objectType,
      };
    });
  }, [connectionData?.probisPropertiesObject]);

  const datevPropertyTypeOptions = useMemo(() => {
    return Object.keys(connectionData?.datevProperties ?? {}).map((datevPropertyType) => {
      return {
        label: datevPropertyType,
        value: datevPropertyType,
      };
    });
  }, [connectionData?.datevProperties]);

  useEffect(() => {
    if (connectionData?.datevProperties) {
      const datevPropertyType = (
        Object.keys(connectionData?.datevProperties) as Array<keyof typeof connectionData.datevProperties>
      )?.find((key) => connectionData?.datevProperties[key].some(x => x.name === (item?.datevPropertyName ?? '')));
      setDatevPropertyType(datevPropertyType ?? '');
    }
  }, [connectionData, connectionData?.datevProperties, item?.datevPropertyName]);

  useEffect(() => {
    if (item && connectionData?.datevProperties && datevPropertyType in connectionData.datevProperties) {
      const key = datevPropertyType as DatevDataFormat;
      const property = connectionData?.datevProperties?.[key]?.find(x => x.name === item.datevPropertyName);
      setSelectedDatevProperty(property)
      setSelectedDatevPropertyName(property?.name ?? '')
      formRef.current?.setValue("datevPropertyName", property?.name)
    }
  }, [connectionData?.datevProperties, datevPropertyType, item])

  const datevPropertyOptions = useMemo(() => {
    const usedDatevIds =
      connectionData?.datevProbisMapping
        .filter((mapping) => mapping.datevPropertyName !== item?.datevPropertyName)
        .map((mapping) => mapping.datevPropertyName) ?? [];
    if (connectionData?.datevProperties[datevPropertyType as keyof typeof connectionData.datevProperties]) {
      return (
        connectionData?.datevProperties[datevPropertyType as keyof typeof connectionData.datevProperties]
          .filter((datevProperty) => !usedDatevIds.includes(datevProperty.name))
          .map((datevProperty) => ({
            label: datevProperty.name,
            value: datevProperty.name,
          })) ?? []
      );
    }

    return [];
  }, [connectionData, datevPropertyType, item?.datevPropertyName]);

  const handleOnClose = (confirmed: boolean) => {
    setSubmitted(false);
    onClose(confirmed);
  }

  const handleSubmit = async (values: DatevMappingAddFormValidationValues) => {
    try {
      const objectType = values.objectType as SynchronizingObjectType;

      if (isEdit && apiAccessId) {
        await safeMutation(
          updateTenantMapping,
          {
            datevApiAccessId: apiAccessId,
            mappingId: item?.id ?? '',
            body: {
              name: values.name,
              description: values.description,
              objectType: objectType,
              datevMappingType: datevPropertyType as DatevDataFormat,
              datevPropertyName: values.datevPropertyName,
              probisPropertyName: values.selectedInput === 'probisPropertyName' ? values.probisPropertyName : null,
              userDefinedFieldName:
                values.selectedInput === 'userDefinedFieldName' ? values.userDefinedFieldName : null,
              defaultValue: values.selectedInput === 'defaultValue' ? values.defaultValue : null,
            },
          },
          isUpdatingTenantMapping,
        );
      } else if (isEdit && projectId) {
        await safeMutation(
          updateProjectMapping,
          {
            projectId: projectId,
            mappingId: item?.id ?? '',
            body: {
              name: values.name,
              description: values.description,
              objectType: objectType,
              datevMappingType: datevPropertyType as DatevDataFormat,
              datevPropertyName: values.datevPropertyName,
              probisPropertyName: values.selectedInput === 'probisPropertyName' ? values.probisPropertyName : null,
              userDefinedFieldName:
                values.selectedInput === 'userDefinedFieldName' ? values.userDefinedFieldName : null,
              defaultValue: values.selectedInput === 'defaultValue' ? values.defaultValue : null,
            },
          },
          isUpdatingProjectMapping,
        );
      } else if (!isEdit && apiAccessId) {
        await safeMutation(
          addTenantMapping,
          {
            datevApiAccessId: apiAccessId,
            body: {
              name: values.name,
              description: values.description,
              objectType: objectType,
              datevMappingType: datevPropertyType as DatevDataFormat,
              datevPropertyName: values.datevPropertyName,
              probisPropertyName: values.selectedInput === 'probisPropertyName' ? values.probisPropertyName : null,
              userDefinedFieldName:
                values.selectedInput === 'userDefinedFieldName' ? values.userDefinedFieldName : null,
              defaultValue: values.selectedInput === 'defaultValue' ? values.defaultValue : null,
            },
          },
          isAddingTenantMapping,
        );
      } else if (!isEdit && projectId) {
        await safeMutation(
          addProjectMapping,
          {
            projectId: projectId,
            body: {
              name: values.name,
              description: values.description,
              objectType: objectType,
              datevMappingType: datevPropertyType as DatevDataFormat,
              datevPropertyName: values.datevPropertyName,
              probisPropertyName: values.selectedInput === 'probisPropertyName' ? values.probisPropertyName : null,
              userDefinedFieldName:
                values.selectedInput === 'userDefinedFieldName' ? values.userDefinedFieldName : null,
              defaultValue: values.selectedInput === 'defaultValue' ? values.defaultValue : null,
            },
          },
          isAddingProjectMapping,
        );
      }
      handleOnClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const defaultFormValues: DatevMappingAddFormValidationValues = {
    name: item?.name ?? '',
    description: item?.description ?? '',
    objectType: item?.type ?? '',
    datevPropertyName: item?.datevPropertyName ?? '',
    selectedInput: item?.defaultValue
      ? 'defaultValue'
      : item?.userDefinedFieldName
        ? 'userDefinedFieldName'
        : 'probisPropertyName',
    probisPropertyName: item?.probisPropertyName ?? '',
    userDefinedFieldName: item?.userDefinedFieldName ?? '',
    defaultValue: item?.defaultValue ?? '',
  };

  return (
    <SlideOver isOpen={isOpen} onClose={handleOnClose}>
      <SlideOver.Header
        title={isEdit ? item?.name ?? '' : t('app.settingsApiDatevAddConnection')}
        subTitle={t('app.settingsApiConnection')}
        backgroundClassName="bg-stone-800"
        onClose={handleOnClose}
      />
      <Form<DatevMappingAddFormValidationValues>
        validationSchema={DatevMappingAddFormValidationSchema}
        defaultValues={defaultFormValues}
        ref={formRef}
        onSubmit={handleSubmit}
        className="w-full flex flex-col flex-grow min-h-0"
      >
        <SlideOver.Content className="p-8">
          <FormField name="name">
            {(control) => (
              <TextInput
                label={t('common.name')}
                icon={<BrowserIcon />}
                disabled={item?.isMandatoryElement}
                {...control}
              />
            )}
          </FormField>

          <FormField name="description">
            {(control) => <TextInput label={t('common.description')} icon={<BrowserIcon />} {...control} />}
          </FormField>

          <FormField name="objectType">
            {(control) => (
              <BaseSelect
                label={t('app.settingsApiDatevSynchronisationType')}
                icon={<CircleNotchIcon className="-rotate-45" />}
                options={objectTypeOptions}
                disabled={item?.isMandatoryElement || isEdit}
                {...control}
              />
            )}
          </FormField>

          {/* <FormField name="syncActive">
            {(control) => (
              <BooleanInput
                label={
                  control.value
                    ? t('app.settingsApiDatevConnectionSyncActive')
                    : t('app.settingsApiDatevConnectionSyncInactive')
                }
                subLabel={t('app.settingsApiDatevConnectionActivation')}
                icon={<SortingArrowsIcon className="rotate-90" />}
                variant="switch"
                {...control}
              />
            )}
          </FormField> */}

          <SlideOverTitle title={t('common.details')} />

          <BaseSelect
            label={t('app.settingsApiDatevPropertyType')}
            icon={<CircleNotchIcon className="-rotate-45" />}
            options={datevPropertyTypeOptions}
            disabled={item?.isMandatoryElement}
            value={datevPropertyType}
            onChange={(value) => {
              setDatevPropertyType(value);
              formRef.current?.setValue('datevPropertyName', undefined);
            }}
            showValidation={submitted}
            helperText={submitted && datevPropertyType === '' ? t('validation.required') : undefined}
            isValidationValid={datevPropertyType !== ''}
          />

          <FormField name="datevPropertyName">
            {(control) => (
              <BaseSelect
                label={t('app.settingsApiDatevConnectionDatevId')}
                icon={<CircleNotchIcon className="-rotate-45" />}
                options={datevPropertyOptions}
                disabled={item?.isMandatoryElement || datevPropertyType === ''}
                {...control}
                value={selectedDatevPropertyName}
                onChange={(value) => {
                  const key = datevPropertyType as DatevDataFormat;
                  const property = connectionData?.datevProperties?.[key]?.find(x => x.name === value);
                  setSelectedDatevProperty(property)
                  setSelectedDatevPropertyName(property?.name ?? '')
                  formRef.current?.setValue("datevPropertyName", property?.name)
                }}
              />
            )}
          </FormField>

          <FormField name="selectedInput">
            {(control) => (
              <BaseSelect
                label={t('app.settingsApiDatevConnectionProbisPropertyType')}
                icon={<ConnectedIcon />}
                options={inputOptions}
                className="mt-5"
                {...control}
              />
            )}
          </FormField>

          <FormWatch
            onChange={({ objectType }) => {
              if (objectType && objectTypeReference !== objectType) {
                const { probisPropertyName = '', userDefinedFieldName = '' } = formRef.current?.getValues() || {};

                // const propertiesMap: { [key: string]: string[] } = {
                //   company: connectionData?.probisPropertiesObject?.company.companyProperties ?? [],
                //   contract: connectionData?.probisPropertiesObject?.contract.contractProperties ?? [],
                //   invoice: connectionData?.probisPropertiesObject?.invoice.invoiceProperties ?? [],
                // };

                // const udfMap: { [key: string]: string[] } = {
                //   company: connectionData?.probisPropertiesObject?.company.userDefinedFields ?? [],
                //   contract: connectionData?.probisPropertiesObject?.contract.userDefinedFields ?? [],
                //   invoice: connectionData?.probisPropertiesObject?.invoice.userDefinedFields ?? [],
                // };

                const currentProperties =
                  connectionData?.probisPropertiesObject?.probisProperties[
                    objectType as keyof typeof connectionData.probisPropertiesObject.probisProperties
                  ]?.properties || [];
                const currentUdf =
                  connectionData?.probisPropertiesObject?.probisProperties[
                    objectType as keyof typeof connectionData.probisPropertiesObject.probisProperties
                  ]?.userDefinedFields || [];

                if (!currentProperties.includes(probisPropertyName)) {
                  formRef.current?.setValue('probisPropertyName', '');
                }

                if (!currentUdf.includes(userDefinedFieldName)) {
                  formRef.current?.setValue('userDefinedFieldName', '');
                }

                setProbisPropertyOptions(currentProperties.map((property) => ({ label: property, value: property })));
                setUserDefinedFieldOptions(currentUdf.map((udf) => ({ label: udf, value: udf })));
                setObjectTypeReference(objectType);
              }
            }}
            fieldNames={['objectType', 'selectedInput']}
          >
            {({ objectType, selectedInput }) => (
              <>
                {selectedInput === 'probisPropertyName' && (
                  <FormField name="probisPropertyName">
                    {(control) => (
                      <BaseSelect
                        label={t('app.settingsApiDatevConnectionProbisObjectId')}
                        icon={<ConnectedIcon />}
                        options={probisPropertyOptions}
                        disabled={objectType === ''}
                        {...control}
                      />
                    )}
                  </FormField>
                )}
                {selectedInput === 'userDefinedFieldName' && (
                  <FormField name="userDefinedFieldName">
                    {(control) => (
                      <BaseSelect
                        label={t('app.settingsApiDatevConnectionCustomField')}
                        icon={<ConnectedIcon />}
                        options={userDefinedFieldOptions}
                        {...control}
                      />
                    )}
                  </FormField>
                )}
                {selectedInput === 'defaultValue' && selectedDatevProperty?.options.length === 0 && (
                  <FormField name="defaultValue">
                    {(control) => (
                      <TextInput
                        label={t('app.settingsApiDatevConnectionDefaultValue')}
                        icon={<ConnectedIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                )}
                {selectedInput === 'defaultValue' && selectedDatevProperty?.options.length !== 0 && (
                  <FormField name="defaultValue">
                    {(control) => (
                      <BaseSelect
                        label={t('app.settingsApiDatevConnectionDefaultValue')}
                        icon={<ConnectedIcon />}
                        options={selectedDatevProperty?.options.map((o) => {
                          return {
                            label: o,
                            value: o
                          }
                        }) ?? []}
                        {...control}
                      />
                    )}
                  </FormField>
                )}
              </>
            )}
          </FormWatch>
        </SlideOver.Content>
        <SlideOver.Controls
          className={classNames('w-full flex', {
            'justify-between': isEdit,
            'justify-end': !isEdit,
          })}
        >
          {isEdit && (
            <Button variant="danger" onClick={() => setIsDeleteModalOpen(true)} disabled={item?.isMandatoryElement}>
              {t('common.delete')}
            </Button>
          )}
          <div className="flex gap-3">
            <Button variant="secondary" onClick={() => handleOnClose(false)}>
              {t('common.close')}
            </Button>
            <Button variant="primary" formSubmit={true} onClick={() => setSubmitted(true)}>
              {t('common.save')}
            </Button>
          </div>
        </SlideOver.Controls>
      </Form>
      <Modal isOpen={isDeleteModalOpen} onClose={setIsDeleteModalOpen}>
        <DatevMappingDeleteModal
          onClose={(confirmed) => {
            setIsDeleteModalOpen(false);
            if (confirmed) {
              handleOnClose(confirmed)
            }
          }}
          item={item}
          apiAccessId={apiAccessId}
        />
      </Modal>
    </SlideOver>
  );
};
