import classNames from 'classnames';
import React, {KeyboardEvent, useEffect, useId, useState} from 'react';
import {isEmpty, getNumberSeparators} from '@client/shared/utilities';
import {FormHelperText} from './FormHelperText';
import {NumericFormat} from 'react-number-format';
import {NumberFormatValues} from 'react-number-format/types/types';

interface NumberInputProps {
    label: string;
    placeHolder?: string;
    value?: number | null;
    onChange: (value: number | null) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    icon?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    inputClassName?: string;
    maxLength?: number;
    decimalScale?: number;
    showDecimalDigits?: boolean;
    step?: number;
    name?: string;
    formatGroup?: boolean;
    autoComplete?: string;
    showValidation?: boolean;
    isValidationValid?: boolean;
    helperText?: string;
    allowNegative?: boolean;
    onKeyDown?: (event: KeyboardEvent) => void;
    textRight?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
    helperTextClassName?: string;
    min?: number | string | undefined;
    max?: number | string | undefined;
}

type LocaleSettings = {
    groupSeparator: string | undefined;
    decimalSeparator: string | undefined
}

export const NumberInput = ({
      autoComplete,
      className,
      disabled,
      icon,
      inputClassName,
      isValidationValid,
      label,
      maxLength,
      name,
      onChange,
      onFocus,
      onBlur,
      placeHolder,
      formatGroup = true,
      showValidation,
      value,
      step,
      helperText,
      allowNegative,
      decimalScale,
      showDecimalDigits = false,
      onKeyDown,
      textRight = false,
      inputRef,
      helperTextClassName,
      min,
      max
}: NumberInputProps) => {
    const inputId = useId();
    const [isFocused, setIsFocused] = React.useState(false);

    const [tmpValue, setTmpValue] = useState('');

    const [localeSettings, setLocaleSettings] = useState<LocaleSettings>(() => {
        return {
            groupSeparator: undefined,
            decimalSeparator: undefined,
        };
    });

    useEffect(() => {
      const {decimalSeparator, groupSeparator} = getNumberSeparators();
        setLocaleSettings({
            decimalSeparator,
            groupSeparator,
        });
    }, []);

    const handleOnChangeValues = (values: NumberFormatValues) => {
        if (onChange) {
            // do not change value immediately, but after on blur (so now negative decimals starting with 0 can be added)
            // if e.g. -0,000 was inserted before Number(values.value) always returns 0, so we store the value temporarily, so the user can insert actually a value starting with zeros
            if (typeof values.floatValue === 'undefined') {
              onChange(null);
            } else if (values.formattedValue === '0' || (values.formattedValue !== '0' && Number(values.value) !== 0)) {
              // FP: We convert the string value back to number because the float value rapidly looses accuracy in decimal places
              onChange(values.floatValue != null ? Number(values.value) : null);
            }
            setTmpValue(values.value);
        }
    };

    const handleFocusChange = (focus: boolean) => {
        if (focus && onFocus) {
            onFocus();
        }
        if (!focus && onBlur) {
            onBlur();
        }
        if (!focus) { // on blur
          // if '-' or '-0' or '-0.' was inserted before
          onChange(tmpValue != null && tmpValue !== '' ? Number(tmpValue) : value ?? null);
        }
        setIsFocused(focus);
    };

    return (
        <div className={className}>
            <div
                className={classNames('w-full relative h-14 px-3 flex flex-row bg-white', {
                    'shadow-[inset_0px_0px_0px_1px] shadow-red-500':
                        showValidation && isValidationValid != null && !isValidationValid,
                    'shadow-[inset_0px_0px_0px_1px] shadow-green-500': showValidation && isValidationValid,
                })}
            >
                {icon && (
                    <div className="flex items-center h-full">
                        <div className="h-5 w-5 flex items-center justify-center">{icon}</div>
                    </div>
                )}
                <div
                    className={classNames('relative flex-grow', {
                        'ml-2': icon,
                        'mx-1': !icon,
                        'flex items-center': !label
                    })}
                >
                    <NumericFormat
                        id={inputId}
                        className={classNames(
                            'fake-mt block w-full text-lg appearance-none focus:outline-none bg-transparent font-medium',
                            {
                                'text-gray-800': !disabled,
                                'text-gray-500 cursor-not-allowed': disabled,
                                'opacity-0': placeHolder && isEmpty(value) && !isFocused,
                                'text-right': textRight,
                                'pt-5': label
                            },
                            inputClassName
                        )}
                        value={value ?? ''}
                        placeholder={placeHolder}
                        disabled={disabled}
                        autoComplete={autoComplete}
                        name={name}
                        min={min}
                        max={max}
                        maxLength={maxLength}
                        onFocus={() => handleFocusChange(true)}
                        onBlur={() => handleFocusChange(false)}
                        onValueChange={handleOnChangeValues}
                        decimalSeparator={localeSettings.decimalSeparator}
                        thousandsGroupStyle={formatGroup ? 'thousand' : 'none'}
                        thousandSeparator={formatGroup ? localeSettings.groupSeparator : undefined}
                        step={step}
                        allowNegative={allowNegative}
                        decimalScale={decimalScale}
                        fixedDecimalScale={showDecimalDigits}
                        onKeyDown={onKeyDown}
                        getInputRef={inputRef}
                    />
                    {label && (
                      <label
                        htmlFor={inputId}
                        className={classNames(
                          'absolute top-0 left-0 right-0 text-lg duration-200 origin-0 text-gray-600 select-none transform truncate text-left',
                          {
                            'pt-3 mt-[3px]': isEmpty(value) && !isFocused,
                            'pt-5 -mt-px': !isEmpty(value) || isFocused,
                            'text-right': textRight,
                            'cursor-not-allowed': disabled
                          }
                        )}
                      >
                        {label}
                      </label>
                    )}
                </div>
                <div
                    className={classNames('absolute bottom-0 h-0.5 bg-black left-0 right-0 duration-300 transition-opacity', {
                        'opacity-0': !isFocused,
                    })}
                />
            </div>
            {helperText && (
              <FormHelperText text={helperText} error={!isValidationValid} className={classNames('w-full', helperTextClassName ?? 'bg-white')} />
            )}
        </div>
    );
};
