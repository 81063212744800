import {
  DistributionFrequency,
  PaymentFrequency,
  useApiGetEarningsElementQuery,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';

import {
  EditTimeLineMoneyDistributionModalProps,
  RecurringPaymentDistributionModal
} from '../index';
import { LoadingIndicator } from '@client/shared/toolkit';

export const EditEarningsTimeLineMondeyDistributionModal = (props: EditTimeLineMoneyDistributionModalProps) => {
  const { onClose, selectedElement, selectedDate } = props;
  const variantId = useLoadedVariantId();
  const projectId = useLoadedProjectId();

  const getEffectiveAmount = () => {
    let amount = 0;

    switch (dataEarningsElement?.readModel.amount.type) {
      case 'Static':
        amount = dataEarningsElement?.readModel.amount.staticValue?.value ?? 0;
        break;
      case 'Formula':
        amount = dataEarningsElement?.readModel.amount.formulaValue?.value ?? 0;
        break;
    }

    return amount;
  }

  const getEffectiveUnitPrice = () => {
    let unitPrice = 0;
    switch (dataEarningsElement?.readModel.unitPrice.type) {
      case 'Static':
        unitPrice = dataEarningsElement?.readModel.unitPrice.staticValue?.value ?? 0;
        break;
      case 'Formula':
        unitPrice = dataEarningsElement?.readModel.unitPrice.formulaValue?.value ?? 0;
        break;
    }

    return unitPrice;
  }

  const { data: dataEarningsElement, isFetching } = useApiGetEarningsElementQuery(
    {
      projectId: projectId ?? 'unset',
      calculationModelId: variantId ?? 'unset',
      id: selectedElement.elementId ?? '',
    },
    { skip: false }
  );

  return (
    <>
      {isFetching && <LoadingIndicator mode="overlay" />}
      {dataEarningsElement?.readModel.paymentTimeline && (
        <RecurringPaymentDistributionModal
          start={dataEarningsElement?.readModel?.paymentTimeline?.effectiveStartDate
            ? new Date(dataEarningsElement?.readModel?.paymentTimeline?.effectiveStartDate)
            : undefined}
          end={dataEarningsElement?.readModel?.paymentTimeline?.effectiveEndDate
            ? new Date(dataEarningsElement?.readModel?.paymentTimeline?.effectiveEndDate)
            : undefined}
          amount={getEffectiveAmount()}
          unitPrice={getEffectiveUnitPrice()}
          index={dataEarningsElement?.readModel.rentIndex ?? null}
          indexDate={
            dataEarningsElement?.readModel?.rentIndexDate
              ? new Date(dataEarningsElement.readModel.rentIndexDate)
              : undefined
          }
          frequency={(dataEarningsElement?.readModel?.paymentFrequency?.toString() ?? 'Monthly') as PaymentFrequency}
          distributionFrequency={(dataEarningsElement?.readModel?.paymentTimeline?.distribution?.frequency?.toString() ?? 'Month') as DistributionFrequency}
          onClose={onClose}
          selectedDate={selectedDate}
        />
      )}
    </>
  );
};
