import { useDocumentTitle, TitleBar, ToggleSlider } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { DashboardContainer } from '../components';
import { VariantDropDown } from '@client/project/shared';
import { useLoadedProject, useUnitSystem, toggleUnitSystem, setUnitSystem } from '@client/project/store';
import { useDispatch } from 'react-redux';
import { useFeatureFlags, useUi } from '@client/shared/store';
import { getUnitSystemLabel } from '@client/shared/utilities';
import React from 'react';
import { LazyReportExportButtons } from '@client/project/shared';

export const DashboardRoute = () => {
  const { t } = useTranslation();
  const ui = useUi();
  const user = ui.appUser;
  const dispatch = useDispatch();
  const unitSystem = useUnitSystem();

  const { unitSystem: showUnitSystem } = useFeatureFlags();

  const { data: loadedProject } = useLoadedProject();

  useDocumentTitle({ title: t('project.menuDashboard') });

  return (
    <>
      <TitleBar
        title={loadedProject?.project?.payload?.name ?? t('projectCalculate.routeTitle')}
        subTitle={t('projectDashboard.routeSubTitle')}
        longTitle
      >
        <div className="flex gap-2 xl:gap-5 items-center h-10 justify-end">
          <div />
          {showUnitSystem && (
            <ToggleSlider
              headerLabel={t('project.measurementSystem')}
              left={unitSystem ? unitSystem === 'Metric' : true}
              onClick={() => {
                dispatch(
                  setUnitSystem({
                    id: loadedProject?.project?.payload.id ?? '',
                    unitSystem: toggleUnitSystem(unitSystem, loadedProject?.project?.payload.id ?? ''),
                  }),
                );
              }}
              labelLeft={getUnitSystemLabel('Metric')}
              labelRight={getUnitSystemLabel('Imperial')}
            />
          )}
          <VariantDropDown />
          {user.permissions?.licenses.find((license) => license.feature === 'CARESTONE_REPORTING') && (
            <LazyReportExportButtons reportId="e43806b2-a661-4c0c-ba22-0d4455f8b1af" type="dashboard" buttons={['pdf', 'xlxs']} />
          )}
        </div>
      </TitleBar>
      <div className="flex-grow flex flex-col overflow-hidden">
        <div className="flex flex-col flex-grow overflow-y-auto">
          {user.permissions?.licenses.find((license) => license.feature === 'CARESTONE_REPORTING') ? (
            <DashboardContainer multiProject={false} />
          ) : (
            <>
              {user.permissions?.licenses.find((license) => license.feature === 'BMW_REPORTING') ? (
                <img className="w-full h-auto p-5" src="/assets/report-bmw-summary.svg" alt="BMW Summary report" />
              ) : (
                <DashboardContainer multiProject={false} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
