import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiGetAllTasksApiArg,
  ApiGetAllTasksApiResponse,
  ApiGetInvoiceWorkflowDetailApiArg,
  ApiGetInvoiceWorkflowDetailApiResponse,
  ApiGetUserWorkflowTasksApiArg,
  ApiGetUserWorkflowTasksApiResponse,
  ApiPostAssignableWorkflowActivitiesApiArg,
  ApiPostAssignableWorkflowActivitiesApiResponse,
  ApiPostReviewAiEvalDocumentWorkflowTaskApiArg,
  ApiPostReviewAiEvalDocumentWorkflowTaskApiResponse,
  ApiPostReviewInvoiceWorkflowTaskApiArg,
  ApiPostReviewInvoiceWorkflowTaskApiResponse,
  ApiPostUpdateAssignableWorkflowActivitiesApiArg,
  ApiPostUpdateAssignableWorkflowActivitiesApiResponse,
  ApiPostUpdateAutomaticWorkflowFlagApiArg,
  ApiPostUpdateAutomaticWorkflowFlagApiResponse,
  ApiPostUpdateWorkflowConfigurationsApiArg,
  ApiPostUpdateWorkflowConfigurationsApiResponse,
  ApiStartInvoiceWorkflowApiArg,
  ApiStartInvoiceWorkflowApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiWorkflowEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetUserWorkflowTasks: {
    providesTags: (
      _result: ApiGetUserWorkflowTasksApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetUserWorkflowTasksApiArg,
    ) => [{ type: ApiTagTypes.Tasks, id: args.calculationModelId }],
  },
  apiGetAllTasks: {
    providesTags: (_result: ApiGetAllTasksApiResponse, _error: FetchBaseQueryError, args: ApiGetAllTasksApiArg) => [
      { type: ApiTagTypes.Tasks, id: args.calculationModelId },
    ],
  },
  apiGetInvoiceWorkflowDetail: {
    providesTags: (
      _result: ApiGetInvoiceWorkflowDetailApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetInvoiceWorkflowDetailApiArg,
    ) => [{ type: ApiTagTypes.Workflow, id: args.invoiceId }],
  },
  apiGetAssignableWorkflowActivities: {
    providesTags: [ApiTagTypes.WorkflowActivities],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostReviewInvoiceWorkflowTask: {
    invalidatesTags: (
      _result: ApiPostReviewInvoiceWorkflowTaskApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostReviewInvoiceWorkflowTaskApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoice, id: args.body.invoiceId },
            { type: ApiTagTypes.Tasks, id: args.calculationModelId },
            { type: ApiTagTypes.InvoiceContract, id: args.body.invoiceId },
            { type: ApiTagTypes.Workflow, id: args.body.invoiceId },
          ]
        : [],
  },
  apiPostReviewAiEvalDocumentWorkflowTask: {
    invalidatesTags: (
      _result: ApiPostReviewAiEvalDocumentWorkflowTaskApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostReviewAiEvalDocumentWorkflowTaskApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Tasks, id: args.calculationModelId },
            { type: ApiTagTypes.Workflow, id: args.body.documentId },
          ]
        : [],
  },
  apiPostAssignableWorkflowActivities: {
    invalidatesTags: (
      _result: ApiPostAssignableWorkflowActivitiesApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostAssignableWorkflowActivitiesApiArg,
    ) => (error == null ? [ApiTagTypes.WorkflowActivities, ApiTagTypes.Workflow] : []),
  },
  apiPostUpdateAssignableWorkflowActivities: {
    invalidatesTags: (
      _result: ApiPostUpdateAssignableWorkflowActivitiesApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostUpdateAssignableWorkflowActivitiesApiArg,
    ) => (error == null ? [ApiTagTypes.WorkflowActivities, ApiTagTypes.Workflow] : []),
  },
  apiStartInvoiceWorkflow: {
    invalidatesTags: (
      _result: ApiStartInvoiceWorkflowApiResponse,
      error: FetchBaseQueryError,
      args: ApiStartInvoiceWorkflowApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoice, id: args.body.invoiceId },
            { type: ApiTagTypes.Workflow, id: args.body.invoiceId },
            { type: ApiTagTypes.Tasks, id: args.body.calculationModelId },
            { type: ApiTagTypes.Contracts, id: args.body.calculationModelId },
            { type: ApiTagTypes.InvoiceContract, id: args.body.invoiceId },
          ]
        : [],
  },
  apiPostUpdateAutomaticWorkflowFlag: {
    invalidatesTags: (
      _result: ApiPostUpdateAutomaticWorkflowFlagApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateAutomaticWorkflowFlagApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Project, id: args.projectId }, ApiTagTypes.Projects] : []),
  },
  apiPostUpdateWorkflowConfigurations: {
    invalidatesTags: (
      _result: ApiPostUpdateWorkflowConfigurationsApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostUpdateWorkflowConfigurationsApiArg
    ) => (error == null ? [ApiTagTypes.WorkflowActivities, ApiTagTypes.Workflow] : []),
  }
};
