import { AclSet, AclTenant } from '@client/shared/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type LoginActionPayload = {
  userId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  hasAvatar?: boolean;
  lastUpdate?: string;
  language?: string;
  tenant?: AclTenant;
  tenants?: AclTenant[];
  permissions?: AclSet;
};

export enum AppState {
  UNKNOWN,
  INIT,
  READY,
  ERROR,
}

export interface UserState {
  userId?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  hasAvatar?: boolean;
  language?: string;
  lastUpdate?: string;
  tenant?: AclTenant;
  tenants?: AclTenant[];
  permissions?: AclSet;
}

export interface UiState {
  sessionId?: string;
  appState: AppState;
  appError?: string;
  appUser: UserState;
  postLoginRedirect?: string;
}

const initialState: UiState = {
  appState: AppState.INIT,
  appUser: {},
};

/**
 * The UI slice deals with global application state, e.g. whether the application
 * is initialized correctly.
 * In the future we will add things theme / etc.
 */
export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    startAppInit: (state) => {
      // this should only work once when transitioning out of UNKNOWN state
      // was introduced for React 18 as the App component is initialized twice.
      if (state.appState !== AppState.UNKNOWN) return;

      state.appState = AppState.INIT;
    },
    finishAppInit: (state) => {
      // if the startup encountered an error, do nothing
      if (state.appState === AppState.ERROR) return;

      // otherwise we are ready to go
      state.appState = AppState.READY;
    },
    appError: (state, action: PayloadAction<string>) => {
      state.appState = AppState.ERROR;
      state.appError = action.payload;
    },
    logout: (state) => {
      state.sessionId = undefined;
      state.appUser = {};
    },
    setSession: (state, action: PayloadAction<string | undefined>) => {
      state.sessionId = action.payload;
    },
    login: (
      state,
      action: PayloadAction<LoginActionPayload>
    ) => {
      state.appUser = {
        userId: action.payload.userId,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        fullName: `${action.payload.firstName} ${action.payload.lastName}`,
        email: action.payload.email,
        hasAvatar: action.payload.hasAvatar,
        lastUpdate: action.payload.lastUpdate,
        language: action.payload.language,
        tenant: action.payload.tenant,
        tenants: action.payload.tenants,
        permissions: action.payload.permissions,
      };
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.appUser.language = action.payload;
    },
    setPostLoginRedirect: (state, action: PayloadAction<string>) => {
      state.postLoginRedirect = action.payload;
    },
  },
});

export const createAppError = (error: unknown) => {
  return JSON.stringify(error);
};

export const { startAppInit, finishAppInit, appError, logout, login, setLanguage, setPostLoginRedirect, setSession } =
  uiSlice.actions;
