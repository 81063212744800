import { WorkflowsList } from '@client/project/shared';
import {
  ProjectReadModel,
  useApiGetAssignableWorkflowActivitiesQuery,
  useApiPostUpdateAutomaticWorkflowFlagMutation,
} from '@client/shared/api';
import { HintBox, LoadingIndicator, ToggleSlider } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';

export interface EditProjectWorkflowsProps {
  projectId: string;
  project?: ProjectReadModel;
}

export const EditProjectWorkflows = (props: EditProjectWorkflowsProps) => {
  const { projectId, project } = props;
  const { t } = useTranslation();

  const { data: availableWorkflows, isFetching: isLoadingWorkflows } = useApiGetAssignableWorkflowActivitiesQuery(
    {
      projectId: projectId ?? '',
    },
    {
      skip: !projectId,
    },
  );

  const [postUpdateWorkflowFlag, { isLoading: isPosting }] = useApiPostUpdateAutomaticWorkflowFlagMutation();

  const handleSubmit = async (automaticTrigger: boolean) => {
    if (project && projectId) {
      try {
        await safeMutation(
          postUpdateWorkflowFlag,
          {
            projectId: projectId,
            body: {
              startWorkflowAutomatically: automaticTrigger,
            },
          },
          isPosting,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const hasAssignedWorkflows = availableWorkflows?.workflows.every((workflow) =>
    workflow.activities.every((a) => a.authorizedGroups?.length),
  );

  return (
    <div className="my-5">
      {isLoadingWorkflows && <LoadingIndicator text={t('app.settingsLoadingWorkflows')} />}
      {isPosting && <LoadingIndicator text={t('app.settingsLoadingWorkflows')} mode="overlay-window" />}
      {availableWorkflows?.workflows && availableWorkflows.workflows.length > 0 ? (
        <>
          {!hasAssignedWorkflows && (
            <HintBox hintType="warning" className="mb-2">
              {t('app.settingsWorkflowAutomaticTriggerInfo')}
            </HintBox>
          )}
          <div className="mb-5 w-full flex justify-end">
            <div className="flex items-center flex-col h-10">
              <ToggleSlider
                headerLabel={t('app.settingsWorkflowAutomaticTrigger')}
                labelLeft={t('common.off')}
                labelRight={t('common.on')}
                left={!project?.payload.startWorkflowAutomatically}
                onClick={() => handleSubmit(!project?.payload.startWorkflowAutomatically)}
                disabled={!hasAssignedWorkflows}
              />
            </div>
          </div>
          <WorkflowsList
            assignableWorkflows={availableWorkflows.workflows}
            configurationOptions={availableWorkflows.configurationOptions}
            projectId={projectId}
          />
        </>
      ) : !isLoadingWorkflows ? (
        <div className="mt-4">{t('app.settingsNoWorkflows')}</div>
      ) : null}
    </div>
  );
};
