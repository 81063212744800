import { InvoiceCoverSheets } from '@client/project/shared';
import { SettingsHeader } from './SettingsHeader';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SettingsInvoiceCoverSheets = () => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState<string | undefined>();

  return (
    <div className="w-full">
      <SettingsHeader searchText={searchText} setSearchText={setSearchText}>
        {t('app.settingsInvoiceCoverSheets')}
      </SettingsHeader>
      <div className="relative h-full">
        <InvoiceCoverSheets
          searchText={searchText}
          type="tenant"
        />
      </div>
    </div>
  )
}
