import { Currency, UnitSystem, ValueType } from '@client/shared/api';
import * as yup from 'yup';
import { InferType } from 'yup';

export const DefaultValuesFormValidationSchema = yup.object({
  dueDateDeadline: yup.number().optional().integer(),
  cashDiscountDeadline: yup.number().optional().integer(),
  currency: yup
    .mixed<Currency>()
    .oneOf(['Usd' , 'Eur' , 'Gbp'])
    .required('validation.required'),
  valueType: yup
    .mixed<ValueType>()
    .oneOf(['Net' , 'Gross'])
    .required('validation.required'),
  unitSystem: yup
    .mixed<UnitSystem>()
    .oneOf(['Metric' , 'Imperial'])
    .required('validation.required'),
});

export type DefaultValuesFormValidationValues = InferType<typeof DefaultValuesFormValidationSchema>;
