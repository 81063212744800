import { CountrySpecificIncreaseValuesReadModel } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {
  AddButton,
  AddIcon,
  ContextMenu,
  ProjectSettingsEditSection,
  SettingsInlineEditField,
  TrashIcon
} from '@client/shared/toolkit';
import { countryCodes } from '@client/shared/utilities';
import { BenchmarkingEditValues } from '.';

export interface DefaultValuesBenchmarkingCountrySpecificIncreaseValuesEditProps {
  title: string;
  data: CountrySpecificIncreaseValuesReadModel[];
  type: 'constructionCosts' | 'rental' | 'sales';
  setAddMode: (type: 'constructionCosts' | 'rental' | 'sales', countryCode?: string) => void;
  onChange: (data: BenchmarkingEditValues) => void;
  onDelete: (type: 'constructionCosts' | 'rental' | 'sales', countryCode: string) => void;
  description?: string;
}

export const DefaultValuesBenchmarkingCountrySpecificIncreaseValuesEdit = (
  props: DefaultValuesBenchmarkingCountrySpecificIncreaseValuesEditProps,
) => {
  const { title, data, type, setAddMode, onChange, description, onDelete } = props;
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<number | null>(null);
  const [currentValue, setCurrentValue] = useState<number | null>(null);

  return (
    <ProjectSettingsEditSection title={title} description={description} className="pt-4">
      <div className="mb-4 flex flex-col gap-2">
        {data.length > 0 &&
          data.map((increaseValueData) => (
            <div
              className="mt-2 flex flex-col gap-2 bg-white px-4 pt-3 pb-2"
              key={`benchmarking-${type}-country-code-${increaseValueData.countryCode}`}
            >
              <span className="font-medium text-sm">{countryCodes.getCountryName(increaseValueData.countryCode)}</span>
              <div className="flex gap-2 flex-none w-full">
                <div className="flex gap-1 flex-1 w-auto overflow-x-auto bg-white pb-2">
                  {increaseValueData.yearlyValues.length > 0 &&
                    increaseValueData.yearlyValues.map((yearlyValue, index) => (
                      <SettingsInlineEditField
                        key={`benchmarking-${type}-year-${
                          increaseValueData.countryCode
                        }-${yearlyValue.year.toString()}`}
                        value={`${yearlyValue.factor}% ${t('projectSettings.benchmarking.perYearLabel')}`}
                        valueInput={yearlyValue.factor}
                        label={yearlyValue.year.toString()}
                        onChange={(val) => {
                          if (val && val !== yearlyValue.factor && isEditing === index) {
                            setCurrentValue(val);
                          }
                        }}
                        updateEditMode={(isEditMode) => {
                          if (isEditMode) {
                            setIsEditing(index);
                          } else if (isEditing === index) {
                            setIsEditing(null);
                            onChange({
                              code: increaseValueData.countryCode,
                              year: yearlyValue.year,
                              factor: currentValue ?? yearlyValue.factor,
                            });
                            setCurrentValue(null);
                          }
                        }}
                        onDelete={() => {
                          onChange({
                            code: increaseValueData.countryCode,
                            year: yearlyValue.year,
                            // setting factor to undefined will delete it
                          });
                        }}
                      />
                    ))}
                </div>
                <ContextMenu
                  items={[
                    {
                      label: t('app.masterDataDefaultValues.benchmarking.addNewYear'),
                      subtitle: t('app.masterDataDefaultValues.benchmarking.addNewYearDescription'),
                      icon: <AddIcon />,
                      onClick: () => {
                        setAddMode(type, increaseValueData.countryCode);
                      },
                    },
                    {
                      label: t('common.delete'),
                      subtitle: t('app.masterDataDefaultValues.benchmarking.deleteCountryCodeIncreaseValues'),
                      icon: <TrashIcon />,
                      onClick: () => onDelete(type, increaseValueData.countryCode),
                    },
                  ]}
                />
              </div>
            </div>
          ))}
      </div>
      <AddButton onClick={() => setAddMode(type)} />
    </ProjectSettingsEditSection>
  );
};
