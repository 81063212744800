import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import {
  DatevInvoicePayload,
  ExportedInvoicesResponse,
  ShortInvoiceReadModel,
  useApiExportInvoicesToDatevMutation,
  useApiGetProjectDatevConnectionQuery,
} from '@client/shared/api';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import {
  BaseSelect,
  Button,
  CheckBox,
  LoadingIndicator,
  Modal,
  ModalOnCloseProps,
  MultiSelect,
  TextInput,
} from '@client/shared/toolkit';
import { formatDateOnly } from '@client/shared/utilities';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';

interface DatevExportModalProps extends ModalOnCloseProps {
  selectedInvoices: ShortInvoiceReadModel[];
  invoices: ShortInvoiceReadModel[];
  isOpen: boolean;
  type: 'datevExport' | 'datevLog';
}

export const DatevExportModal = ({ onClose, isOpen, selectedInvoices, invoices, type }: DatevExportModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();

  const [exportInvoices, { isLoading: isExporting }] = useApiExportInvoicesToDatevMutation();
  const { data: datevData, isFetching: isFetchingDatev } = useApiGetProjectDatevConnectionQuery({
    projectId: loadedProjectId ?? '',
  });

  const documentTypeOptions = useMemo(() => {
    return (
      datevData?.exportPreChecks?.documentTypes.map((documentType: string) => ({
        value: documentType,
        label: documentType,
      })) ?? []
    );
  }, [datevData?.exportPreChecks?.documentTypes]);

  const [invoicePayloads, setInvoicePayloads] = useState<
    {
      invoice: ShortInvoiceReadModel;
      documentIds: string[];
      documentType: string;
      documentNote: string;
      selected: boolean;
    }[]
  >([]);

  useEffect(() => {
    if (invoicePayloads.length === 0 && datevData) {
      setInvoicePayloads(
        selectedInvoices.map((invoice: ShortInvoiceReadModel) => ({
          invoice: invoice,
          documentIds:
            invoice.invoiceDocuments.find((doc) => doc.isInvoiceSource)?.id || invoice.invoiceDocuments[0]?.id
              ? [
                  invoice.invoiceDocuments.find((doc) => doc.isInvoiceSource)?.id ??
                    invoice.invoiceDocuments[0]?.id ??
                    '',
                ]
              : [],
          documentType: datevData?.exportPreChecks?.documentTypes.includes('Rechnungseingang')
            ? 'Rechnungseingang'
            : '',
          documentNote: '',
          selected: true,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvoices, datevData]);

  const [selectedYear, setSelectedYear] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<string>('');
  const [response, setResponse] = useState<ExportedInvoicesResponse | null>(null);

  const hasExportPermission = datevData?.exportPreChecks?.hasClientDocumentSendingPermission;

  const handleSubmit = async () => {
    try {
      await exportInvoices({
        projectId: loadedProjectId ?? '',
        calculationModelId: loadedCalculationModelId ?? '',
        body: {
          invoices: invoicePayloads
            .filter((invoicePayload) => invoicePayload.selected)
            .map(
              (invoicePayload) =>
                ({
                  invoiceId: invoicePayload.invoice.id,
                  documentIds: invoicePayload.documentIds ? invoicePayload.documentIds : [],
                  documentType: !invoicePayload.documentType ? undefined : invoicePayload.documentType,
                  note:
                    !invoicePayload.documentIds.length || invoicePayload.documentNote === ''
                      ? undefined
                      : invoicePayload.documentNote,
                }) as DatevInvoicePayload,
            ),
          selectedClientId: datevData?.connectionResponse.clientDetail?.id ?? '',
          accountingMonth: formatDateOnly(new Date(Number(selectedYear), Number(selectedMonth), 1)),
        },
      })
        .unwrap()
        .then((data) => {
          if (data) {
            if (
              (!data.invoiceResponse || !data.invoiceResponse.length) &&
              (!data.csvHeaderError || !data.csvHeaderError.length)
            ) {
              onClose(true);
            }
            setResponse(data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const yearOptions = useMemo(() => {
    const yearSet = new Set<string>();
    datevData?.connectionResponse.clientDetail?.accountingInformation.forEach(({ fiscalYearStart }) => {
      const year = String(new Date(fiscalYearStart).getFullYear());
      yearSet.add(year < '2000' ? '2000' : year);
    });
    return Array.from(yearSet).map((year) => ({ value: year, label: year }));
  }, [datevData?.connectionResponse.clientDetail?.accountingInformation]);

  const monthOptions = useMemo(() => {
    const year = datevData?.connectionResponse.clientDetail?.accountingInformation.find(
      (accountingInformation) => new Date(accountingInformation.fiscalYearStart).getFullYear() === Number(selectedYear),
    );
    const fiscalYearStart = year ? new Date(year.fiscalYearStart).getMonth() : new Date().getMonth();
    const fiscalYearEnd = year ? new Date(year.fiscalYearEnd).getMonth() : new Date().getMonth();

    return [
      { value: '0', label: t('common.monthJanuary'), disabled: fiscalYearStart > 0 && fiscalYearEnd < 0 },
      { value: '1', label: t('common.monthFebruary'), disabled: fiscalYearStart > 1 && fiscalYearEnd < 1 },
      { value: '2', label: t('common.monthMarch'), disabled: fiscalYearStart > 2 && fiscalYearEnd < 2 },
      { value: '3', label: t('common.monthApril'), disabled: fiscalYearStart > 3 && fiscalYearEnd < 3 },
      { value: '4', label: t('common.monthMay'), disabled: fiscalYearStart > 4 && fiscalYearEnd < 4 },
      { value: '5', label: t('common.monthJune'), disabled: fiscalYearStart > 5 && fiscalYearEnd < 5 },
      { value: '6', label: t('common.monthJuly'), disabled: fiscalYearStart > 6 && fiscalYearEnd < 6 },
      { value: '7', label: t('common.monthAugust'), disabled: fiscalYearStart > 7 && fiscalYearEnd < 7 },
      { value: '8', label: t('common.monthSeptember'), disabled: fiscalYearStart > 8 && fiscalYearEnd < 8 },
      { value: '9', label: t('common.monthOctober'), disabled: fiscalYearStart > 9 && fiscalYearEnd < 9 },
      { value: '10', label: t('common.monthNovember'), disabled: fiscalYearStart > 10 && fiscalYearEnd < 10 },
      { value: '11', label: t('common.monthDecember'), disabled: fiscalYearStart > 11 && fiscalYearEnd < 11 },
    ];
  }, [datevData?.connectionResponse.clientDetail?.accountingInformation, t, selectedYear]);

  useEffect(() => {
    setSelectedYear(
      String(
        datevData?.projectDatevOverview.accountingMonth
          ? new Date(datevData?.projectDatevOverview.accountingMonth).getFullYear()
          : '',
      ),
    );
  }, [datevData?.projectDatevOverview.accountingMonth, yearOptions]);

  useEffect(() => {
    setSelectedMonth(
      String(
        datevData?.projectDatevOverview.accountingMonth
          ? new Date(datevData?.projectDatevOverview.accountingMonth).getMonth()
          : '',
      ),
    );
  }, [datevData?.projectDatevOverview?.accountingMonth, monthOptions]);

  useEffect(() => {
    if (selectedYear !== '' && selectedMonth === '') {
      const firstAvailableMonth = monthOptions.find((month) => !month.disabled);
      setSelectedMonth(firstAvailableMonth?.value ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  useEffect(() => {
    if (
      response?.invoiceResponse &&
      invoices &&
      response.invoiceResponse.every(
        (invoice) => invoices.find((inv) => inv.id === invoice.id)?.datevStatus?.status === 'Succeeded',
      )
    ) {
      onClose(true);
    }
  }, [response?.invoiceResponse, invoices, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="max">
      <Modal.Header
        title={type === 'datevLog' ? t('projectControl.datevLogTitle') : t('projectControl.datevExportTitle')}
        description={
          type === 'datevLog'
            ? t('projectControl.datevExportLogDescription')
            : t('projectControl.datevExportDescription')
        }
      />
      <Modal.Content className="w-full flex justify-start items-center">
        {(isExporting || isFetchingDatev) && (
          <LoadingIndicator
            text={t(isFetchingDatev ? 'common.loading' : 'projectControl.datevExporting')}
            mode="overlay"
          />
        )}
        {invoices
          .filter((invoice) => selectedInvoices.find((selectedInvoice) => selectedInvoice.id === invoice.id))
          .some((invoice) => invoice.datevStatus?.status === 'InProcess') && (
          <div className="pb-2 gap-2 flex flex-col">
            <LoadingIndicator />
            <span>{t('projectControl.datevExportInProcess')}</span>
          </div>
        )}
        {!response && type !== 'datevLog' ? (
          <>
            {isFetchingDatev ? null : !datevData?.connectionResponse.clientDetail ? (
              <div className="h-full flex flex-col justify-center items-center">
                <div className="p-2 mb-2 text-center">{t('projectControl.datevExportNoClientConfigured')}</div>
                <Button
                  variant="primary"
                  onClick={() =>
                    navigate({
                      pathname: ROUTES_CONFIG.PROJECT_DASHBOARD_SETTINGS.path.replace(':id', loadedProjectId ?? ''),
                      search: createSearchParams({ tab: 'connections' }).toString(),
                    })
                  }
                >
                  {t('app.projectSettings')}
                </Button>
              </div>
            ) : (
              <div className="w-full">
                <div className="w-full flex divide-x-2">
                  <BaseSelect
                    className="w-full"
                    label={t('common.year')}
                    options={yearOptions}
                    value={selectedYear}
                    onChange={(value) => setSelectedYear(value)}
                  />
                  <BaseSelect
                    className={classNames('w-full', selectedYear === '' && 'opacity-70')}
                    label={t('common.month')}
                    options={monthOptions}
                    value={selectedMonth}
                    onChange={(value) => setSelectedMonth(value)}
                    disabled={selectedYear === ''}
                  />
                </div>
                <div className="flex flex-col items-center">
                  <div
                    className={classNames(
                      'pt-5 pb-1 font-bold text-xl text-center',
                      hasExportPermission ? 'pb-5' : 'pb-1',
                    )}
                  >
                    {t('projectControl.datevExportSelectedInvoices')}
                  </div>
                  {!hasExportPermission && (
                    <div className="text-yellow-700 text-sm">{t('projectControl.datevExportNoDocumentPermission')}</div>
                  )}
                  <div className="w-full flex items-center justify-between pb-2 font-bold">
                    <span className="w-2/12 pl-2">{t('projectControl.invoice')}</span>
                    <span className="w-3/12 px-2">{hasExportPermission && t('common.document')}</span>
                    <span className="w-3/12 px-2">
                      {hasExportPermission && t('projectControl.datevExportInvoicingType')}
                    </span>
                    <span className="w-3/12 px-2">
                      {hasExportPermission && t('projectControl.datevExportDocumentNote')}
                    </span>
                    <span className="w-1/12">{t('common.selected')}</span>
                  </div>
                  <div className="w-full flex flex-col items-center h-80 overflow-auto bg-white divide-y">
                    {invoicePayloads.map((invoicePayload) => (
                      <div
                        key={invoicePayload.invoice.id}
                        className={classNames('w-full flex items-center justify-between', {
                          'divide-x': hasExportPermission,
                        })}
                      >
                        <span className="w-2/12 pl-3 font-bold py-2">{invoicePayload.invoice.code}</span>
                        {hasExportPermission && (
                          <MultiSelect
                            className={classNames('w-3/12', {
                              'opacity-60': !invoicePayload.invoice.invoiceDocuments.length,
                            })}
                            disabled={!invoicePayload.invoice.invoiceDocuments.length}
                            label={t('common.document')}
                            options={invoicePayload.invoice.invoiceDocuments.map((document) => ({
                              value: document.id,
                              label: document.fileName,
                            }))}
                            value={invoicePayload.documentIds}
                            onChange={(value) => {
                              setInvoicePayloads(
                                invoicePayloads.map((payload) =>
                                  payload.invoice.id === invoicePayload.invoice.id
                                    ? {
                                        ...payload,
                                        documentIds: value,
                                        documentNote: !value ? '' : payload.documentNote,
                                      }
                                    : payload,
                                ),
                              );
                            }}
                            nullable
                          />
                        )}

                        <BaseSelect
                          className={classNames('w-3/12')}
                          label={t('projectControl.datevExportInvoicingType')}
                          options={documentTypeOptions}
                          value={invoicePayload.documentType}
                          onChange={(value) => {
                            setInvoicePayloads(
                              invoicePayloads.map((payload) =>
                                payload.invoice.id === invoicePayload.invoice.id
                                  ? { ...payload, documentType: value }
                                  : payload,
                              ),
                            );
                          }}
                          nullable
                        />

                        {hasExportPermission && (
                          <TextInput
                            className={classNames('w-3/12', { 'opacity-60': !invoicePayload.documentIds.length })}
                            label={t('projectControl.datevExportDocumentNote')}
                            disabled={!invoicePayload.documentIds.length}
                            value={invoicePayload.documentNote}
                            onChange={(value) => {
                              setInvoicePayloads(
                                invoicePayloads.map((payload) =>
                                  payload.invoice.id === invoicePayload.invoice.id
                                    ? { ...payload, documentNote: value }
                                    : payload,
                                ),
                              );
                            }}
                          />
                        )}
                        <span className="w-1/12 h-full flex justify-center items-center">
                          <CheckBox
                            className="cursor-pointer"
                            checked={invoicePayload.selected}
                            onChange={() => {
                              setInvoicePayloads(
                                invoicePayloads.map((payload) =>
                                  payload.invoice.id === invoicePayload.invoice.id
                                    ? { ...payload, selected: invoicePayload.selected ? false : true }
                                    : payload,
                                ),
                              );
                            }}
                          />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="w-full h-80 text-slate-800">
            {response?.csvHeaderError && response?.csvHeaderError?.length && (
              <>
                <div className="flex font-bold mt-2 bg-white px-2 pt-2">
                  <span className="w-40">{t('projectControl.datevExportCsvHeader')}</span>
                  <span>{t('projectControl.datevExportError')}</span>
                </div>
                <div className="p-2 w-full overflow-auto bg-white divide-y">
                  <ul className="ml-40 overflow-auto gap-3 flex flex-col">
                    {response?.csvHeaderError?.map((error, i) => (
                      <li key={`${error.code}-${i}`}>
                        {t(
                          `${error.code}`.toLowerCase(),
                          error.messageParameters?.reduce(
                            (acc: Record<string, string>, curr: string, index: number) => {
                              acc[index.toString()] = curr;
                              return acc;
                            },
                            {},
                          ) ?? {},
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
            {response?.invoiceResponse && (
              <>
                <div className="flex font-bold mt-2 bg-white p-2">
                  <span className="w-40">{t('projectControl.datevExportInvoice')}</span>
                  <span className="w-40">{t('projectControl.datevExportStatus')}</span>
                  <span className="flex-1">{t('projectControl.datevExportError')}</span>
                </div>
                <div className="p-2 w-full overflow-auto bg-white divide-y">
                  {response?.invoiceResponse.map((item) => {
                    const status =
                      invoices.find((invoice) => invoice.id === item.id)?.datevStatus?.status ?? item.status;
                    return (
                      <div key={item.id} className="flex">
                        <span className="w-40 font-bold">
                          {
                            invoicePayloads.find((invoicePayload) => invoicePayload.invoice.id === item.id)?.invoice
                              .code
                          }
                        </span>
                        <span
                          className={classNames('w-40 flex justify-between', {
                            'text-green-600': status === 'Succeeded',
                            'text-yellow-600': status === 'InProcess',
                            'text-red-600': status === 'Failed',
                          })}
                        >
                          {t(`projectControl.datevExportStatus${status}`)}
                        </span>
                        <ul className="flex-1 flex flex-col gap-3">
                          {item?.invoiceWithErrors?.length
                            ? item?.invoiceWithErrors.map((error, i) => (
                                <li key={`${error.code}-${i}`} className="flex">
                                  {t(
                                    `${error.code}`.toLowerCase(),
                                    error.messageParameters?.reduce(
                                      (acc: Record<string, string>, curr: string, index: number) => {
                                        acc[index.toString()] = curr;
                                        return acc;
                                      },
                                      {},
                                    ) ?? {},
                                  )}
                                </li>
                              ))
                            : '-'}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            {!response?.invoiceResponse && selectedInvoices[0].datevStatus?.error && (
              <>
                <div className="flex font-bold mt-2 bg-white p-2">
                  <span className="w-40">{t('projectControl.datevExportInvoice')}</span>
                  <span className="w-40">{t('projectControl.datevExportStatus')}</span>
                </div>
                <div className="p-2 w-full overflow-auto bg-white divide-y">
                  <div className="flex">
                    <span className="w-40 font-bold">{selectedInvoices[0].code}</span>
                    <span
                      className={classNames('w-40', {
                        'text-green-600': selectedInvoices[0].datevStatus?.status === 'Succeeded',
                        'text-yellow-600': selectedInvoices[0].datevStatus?.status === 'InProcess',
                        'text-red-600': selectedInvoices[0].datevStatus?.status === 'Failed',
                      })}
                    >
                      {t(`projectControl.datevExportStatus${selectedInvoices[0].datevStatus?.status}`)}
                    </span>
                  </div>
                </div>
                <div className="flex font-bold mt-2 bg-white p-2">
                  <span className="w-40">{t('projectControl.datevExportErrorType')}</span>
                  <span className="w-80">{t('projectControl.datevExportErrorDetail')}</span>
                  {selectedInvoices[0].datevStatus?.error.propertiesError.length && (
                    <span className="flex-1">{t('projectControl.datevExportErrorProperties')}</span>
                  )}
                </div>
                <div className="p-2 w-full overflow-auto bg-white divide-y">
                  <div className="flex">
                    <span className="w-40">{selectedInvoices[0].datevStatus?.error.errorType}</span>
                    <span
                      className={classNames(
                        selectedInvoices[0].datevStatus?.error.propertiesError.length ? 'w-80' : 'flex-1',
                      )}
                    >
                      {selectedInvoices[0].datevStatus?.error.detail}
                    </span>
                    {selectedInvoices[0].datevStatus?.error.propertiesError.length && (
                      <ul className="flex-1 flex flex-col gap-3">
                        {selectedInvoices[0].datevStatus?.error.propertiesError.map((error, i) => (
                          <li key={`${error.name}-${i}`} className="flex">
                            <span className="font-bold">{error.name}:</span>&nbsp;{error.error}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {response ? t('common.close') : t('common.cancel')}
        </Button>
        {type === 'datevExport' && !response && (
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={
              isExporting ||
              !datevData?.connectionResponse.clientDetail ||
              !selectedYear ||
              !selectedMonth ||
              !invoicePayloads.filter((invoicePayload) => invoicePayload.selected).length ||
              !!response
            }
          >
            {t('projectControl.datevExportSend')}
          </Button>
        )}
      </Modal.Controls>
    </Modal>
  );
};
