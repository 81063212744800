import {
  BaseSelect,
  BillIcon,
  ComboSelect, ComboSelectAdditionalOption,
  ComboSelectOption,
  DocumentViewerFileDataInlineEdit,
  DocumentViewerFileDataSet,
  FormField, FormRefHandle,
  FormWatch,
  LoadingIndicator,
} from '@client/shared/toolkit';
import { InvoiceCreateFormValidationValues } from '../InvoiceCreateFormValidationValues';
import { getInvoiceTypeLabel } from '../../../utils';
import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InvoiceReadModel,
  InvoiceType, SelectContractInvoiceCalculationSchemeReadModel,
  useApiGetProjectInvoiceCalculationSchemesQuery,
} from '@client/shared/api';
import { useLoadedProjectId } from '@client/project/store';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';

interface InvoiceCoverSheetEditProps {
  canEdit: boolean;
  defaultFormValues: InvoiceCreateFormValidationValues;
  contractHasFinalInvoice?: boolean;
  contractId?: string | null;
  allowChangeMode?: boolean;
  setAllowChangeMode?: (allowChangeMode: boolean) => void;
  setIsEditMode?: (isEditMode: boolean) => void;
  invoice?: InvoiceReadModel | null;
  invoiceCalculationSchemes?: SelectContractInvoiceCalculationSchemeReadModel[];
  updateUnsavedData?: (formField: string, unsaved: boolean) => void;
  inline?: boolean;
  formRef: RefObject<FormRefHandle<InvoiceCreateFormValidationValues>> | null;
  showTypeInput?: boolean
}

export const InvoiceCoverSheetEdit = (props: InvoiceCoverSheetEditProps) => {
  const {
    contractHasFinalInvoice,
    allowChangeMode,
    canEdit,
    defaultFormValues,
    contractId,
    invoiceCalculationSchemes = [],
    setAllowChangeMode,
    setIsEditMode,
    invoice,
    updateUnsavedData,
    inline = true,
    formRef,
    showTypeInput = true
  } = props;

  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const navigate = useNavigate();

  const { data: calculationSchemes, isFetching } = useApiGetProjectInvoiceCalculationSchemesQuery(
    {
      projectId: loadedProjectId ?? '',
    },
    {
      skip: !loadedProjectId,
    },
  );

  const [calculationSchemeOptions, setCalculationSchemeOptions] = useState<ComboSelectOption[]>([]);

  const invoiceTypeOptions = useMemo(() => {
    // invoice has no contract
    if (!contractId) {
      return [
        {
          value: 'Single',
          label: t('projectControl.invoiceTypeSingle'),
        },
      ];
    }

    if (contractId && contractHasFinalInvoice && invoice?.type !== 'Final') { // contract.invoices.some((x) => x.type === 'Final') && invoice?.type !== 'Final') {
      return [
        {
          value: 'Single',
          label: t('projectControl.invoiceTypeSingle'),
        },
        {
          value: 'Warranty',
          label: t('projectControl.invoiceTypeWarranty'),
        },
      ];
    } else {
      return [
        {
          value: 'AdvancePayment',
          label: t('projectControl.invoiceTypeAdvancePayment'),
        },
        {
          value: 'Single',
          label: t('projectControl.invoiceTypeSingle'),
        },
        {
          value: 'Partial',
          label: t('projectControl.invoiceTypePartial'),
        },
        {
          value: 'PartialFinal',
          label: t('projectControl.invoiceTypePartialFinal'),
        },
        {
          value: 'Final',
          label: t('projectControl.invoiceTypeFinal'),
        },
      ];
    }
  }, [contractHasFinalInvoice, t, invoice?.type, contractId]);

  const singleCalculationSchemeOptions = useMemo(() => {
    return calculationSchemes && calculationSchemes.length
      ? calculationSchemes
          .filter((calculationScheme) => calculationScheme.type === 'Single')
          .map((calculationScheme) => {
            return {
              label: calculationScheme.name,
              value: calculationScheme.invoiceCalculationSchemeId,
            };
          })
      : [];
  }, [calculationSchemes]);

  const cumulatedCalculationSchemeOptions = useMemo(() => {
    return calculationSchemes && calculationSchemes.length
      ? calculationSchemes
          .filter((calculationScheme) => calculationScheme.type === 'Cumulated')
          .map((calculationScheme) => {
            return {
              label: calculationScheme.name,
              value: calculationScheme.invoiceCalculationSchemeId,
            };
          })
      : [];
  }, [calculationSchemes]);

  const advancePaymentCalculationSchemeOptions = useMemo(() => {
    return calculationSchemes && calculationSchemes.length
      ? calculationSchemes
          .filter((calculationScheme) => calculationScheme.type === 'AdvancePayment')
          .map((calculationScheme) => {
            return {
              label: calculationScheme.name,
              value: calculationScheme.invoiceCalculationSchemeId,
            };
          })
      : [];
  }, [calculationSchemes]);

  const getCalculationSchemeOptions = useCallback(
    (type: InvoiceType | undefined) => {
      switch (type) {
        case 'AdvancePayment':
          return advancePaymentCalculationSchemeOptions;
        case 'Partial':
        case 'Final':
        case 'PartialFinal':
          return cumulatedCalculationSchemeOptions;
        case 'Warranty':
          return [];
        default:
          return singleCalculationSchemeOptions;
      }
    },
    [advancePaymentCalculationSchemeOptions, cumulatedCalculationSchemeOptions, singleCalculationSchemeOptions],
  );

  useEffect(() => {
    setCalculationSchemeOptions(getCalculationSchemeOptions(invoice?.type));
  }, [getCalculationSchemeOptions, invoice?.type, contractId]);

  const handleTypeChange = useCallback((type: InvoiceType) => {
    setCalculationSchemeOptions(getCalculationSchemeOptions(type));
    if (invoiceCalculationSchemes.length && formRef?.current && type) {
      const foundSchemeByType = invoiceCalculationSchemes.find((scheme) => scheme.type === type);
      if (foundSchemeByType) {
        formRef.current.setValue('calculationSchemeId', foundSchemeByType.id);
      } else {
        formRef?.current?.setValue('calculationSchemeId', null);
      }
    } else {
      formRef?.current?.setValue('calculationSchemeId', null);
    }
  }, [getCalculationSchemeOptions, formRef, invoiceCalculationSchemes])

  return (
    <>
      {isFetching && <LoadingIndicator text={t('projectControl.invoiceTypesLoading') ?? ''} mode="overlay" />}

      {/* INVOICE TYPE */}
      <FormWatch<InvoiceCreateFormValidationValues>
        fieldNames={['type']}
      >
        {({ type }) => {
          // inline editing e.g. in invoice slide over
          if (inline) {
            return (
              <DocumentViewerFileDataInlineEdit
                updateEditMode={setIsEditMode}
                className={cn('py-4', { 'hidden': !showTypeInput })}
                closeOnBlur={false}
                allowChangeMode={allowChangeMode}
                toggleContent={
                  canEdit ? (
                  <FormField name="type">
                    {(control) => (
                      <BaseSelect
                        label={t('projectControl.invoiceType')}
                        options={invoiceTypeOptions}
                        disabled={!canEdit}
                        {...control}
                        onChange={(value) => {
                          if (updateUnsavedData) {
                            updateUnsavedData('type', value === defaultFormValues.type);
                          }
                          handleTypeChange(value as InvoiceType);
                          control.onChange(value);
                        }}
                        handlePopoverVisibility={
                          setAllowChangeMode ? (isOpen) => setAllowChangeMode(!isOpen) : undefined
                        }
                      />
                    )}
                  </FormField>) : undefined
                }
              >
                <DocumentViewerFileDataSet
                  label={t('projectControl.invoiceType')}
                  subtitle={getInvoiceTypeLabel(type ?? 'Single')}
                  className='py-4'
                />
              </DocumentViewerFileDataInlineEdit>
            );
          } else {
            // not inline editing, e.g. in invoice create wizard
            return (
              <FormField name="type">
                {(control) => (
                  <BaseSelect
                    label={t('projectControl.invoiceType')}
                    options={invoiceTypeOptions}
                    disabled={!canEdit}
                    {...control}
                    onChange={(value) => {
                      handleTypeChange(value as InvoiceType);
                      control.onChange(value);
                    }}
                    className={!showTypeInput ? 'hidden' : undefined}
                  />
                )}
              </FormField>
            );
          }
        }}
      </FormWatch>
      {/* CALCULATION SCHEME */}
      <FormWatch<InvoiceCreateFormValidationValues> fieldNames={['calculationSchemeId', 'type']}>
        {({ calculationSchemeId, type }) => {
          // inline editing e.g. in invoice slide over
          if (inline) {
            return (
              <>
              {((type === 'Single' || type === 'AdvancePayment') && invoice?.state === 'Pending') ? (
                <DocumentViewerFileDataInlineEdit
                  updateEditMode={setIsEditMode}
                  allowChangeMode={allowChangeMode}
                  className="pt-4"
                  toggleContent={
                    canEdit && invoice?.state === 'Pending' ? (
                      <FormField name="calculationSchemeId">
                        {(control) => (
                          <ComboSelect
                            icon={<BillIcon className="h-6 w-6" />}
                            options={calculationSchemeOptions}
                            label={t('ics.invoiceCoverSheet')}
                            {...control}
                            onChange={(value) => {
                              if (updateUnsavedData) {
                                updateUnsavedData(
                                  'calculationSchemeId',
                                  defaultFormValues?.calculationSchemeId === value,
                                );
                              }
                              control.onChange(value);
                            }}
                            nullable
                            additionalOptionOnClick={() => {
                              navigate(`${ROUTES_CONFIG.PROJECT_DASHBOARD_SETTINGS.path.replace(':id', loadedProjectId ?? '')}?tab=invoiceCoverSheets`);
                            }}
                            additionalOption={<ComboSelectAdditionalOption label={t('app.settingsCreateInvoiceCoverSheetTitle')} />}
                          />
                        )}
                      </FormField>
                    ) : undefined
                  }
                >
                  <DocumentViewerFileDataSet
                    label={t('ics.invoiceCoverSheet')}
                    subtitle={
                      calculationSchemeId
                        ? calculationSchemes?.find((x) => x.invoiceCalculationSchemeId === calculationSchemeId)?.name
                        : '-'
                    }
                    className={cn(
                      defaultFormValues?.calculationSchemeId !== calculationSchemeId ? 'text-secondary' : undefined
                    )}
                  />
                </DocumentViewerFileDataInlineEdit>
              ) : (
                <DocumentViewerFileDataSet
                  label={t('ics.invoiceCoverSheet')}
                  subtitle={
                    calculationSchemeId
                      ? calculationSchemes?.find((x) => x.invoiceCalculationSchemeId === calculationSchemeId)?.name
                      : '-'
                  }
                  className="pt-4"
                />
              )}
              </>
            );
          } else {
            return (
              <FormField name="calculationSchemeId">
                {(control) => (
                  <ComboSelect
                    icon={<BillIcon className="h-6 w-6" />}
                    options={calculationSchemeOptions}
                    label={t('ics.invoiceCoverSheet')}
                    {...control}
                    disabled={!!invoice && invoice.state !== 'Pending' || type !== 'Single' && type !== 'AdvancePayment'}
                    className={type !== 'Single' && type !== 'AdvancePayment' ? 'hidden' : ''}  // ICS can only be selected for not cumulated invoices
                    nullable
                    additionalOptionOnClick={() => {
                      navigate(`${ROUTES_CONFIG.PROJECT_DASHBOARD_SETTINGS.path.replace(':id', loadedProjectId ?? '')}?tab=invoiceCoverSheets`);
                    }}
                    additionalOption={<ComboSelectAdditionalOption label={t('app.settingsCreateInvoiceCoverSheetTitle')} />}
                  />
                )}
              </FormField>
            );
          }
        }}
      </FormWatch>
    </>
  );
};
