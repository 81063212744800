import { useDocumentTitle, TitleBar } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import {
  ReportingTabs,
  ReportComparison,
  ReportBudget,
  ReportForecast,
  ReportBase,
  CarbonEmissionsChart,
  ReportDetails,
  ReportingContract,
  ReportCostBreakdown,
  ReportVariation,
} from '../components';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useLoadedProject, useLoadedProjectVariants } from '@client/project/store';
import { settings, useUi } from '@client/shared/store';
import { ProtectedRoute, ROUTES_CONFIG } from '@client/shared/permissions';
import { ReportingContextProvider } from '../components';
import { useLocation } from 'react-router-dom';
import LazyReportViewer from '../components/LazyReportViewer';
import { ReportWaterfall } from '../components';
import { ReportCashOut } from '../components';

export const ReportingRoute = () => {
  const { t } = useTranslation();

  const { data: loadedProject } = useLoadedProject();

  useDocumentTitle({ title: t('reporting.routeTitle') });

  const ui = useUi();
  const user = ui.appUser;

  const projectId = loadedProject?.project.payload.id;
  const { data: projectVariants } = useLoadedProjectVariants();
  const activeVariant = projectVariants.find((x) => x.type === 'Version');
  const activeVariantId = activeVariant?.id;

  const location = useLocation();

  return (
    <ReportingContextProvider projectId={projectId} activeVariantId={activeVariantId} location={location}>
      <TitleBar
        title={loadedProject?.project?.payload?.name ?? t('reporting.routeTitle')}
        subTitle={t('reporting.routeSubTitle')}
      >
        <ReportingTabs />
      </TitleBar>
      <div className="flex-grow flex flex-col overflow-hidden">
        <div
          id="pdf-content"
          className="p-8 flex flex-col flex-grow overflow-y-auto"
          style={{ lineHeight: '0.5 !important' }}
        >
          <Routes>
            <Route
              path={ROUTES_CONFIG.REPORTING_BASE.name}
              element={
                <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTING_BASE} projectId={projectId}>
                  <ReportBase />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES_CONFIG.REPORTING_COMPARISON.name}
              element={
                <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTING_COMPARISON} projectId={projectId}>
                  <ReportComparison />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES_CONFIG.REPORTING_BUDGET.name}
              element={
                <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTING_BUDGET} projectId={projectId}>
                  <ReportBudget />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES_CONFIG.REPORTING_FORECAST.name}
              element={
                <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTING_FORECAST} projectId={projectId}>
                  <ReportForecast />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES_CONFIG.REPORTING_DETAILS.name}
              element={
                <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTING_DETAILS} projectId={projectId}>
                  <ReportDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES_CONFIG.REPORTING_VARIATION.name}
              element={
                <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTING_VARIATION} projectId={projectId}>
                  <ReportVariation />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES_CONFIG.REPORTING_FORECAST_STEPS.name}
              element={
                <img
                  className="w-full h-auto"
                  src="/assets/fake-report-forecast-step.svg"
                  alt="Forecast stairs report"
                />
              }
            />

            {!!user.permissions?.licenses.find((license) => license.feature === 'BMW_REPORTING') && (
              <>
                <Route
                  path={ROUTES_CONFIG.REPORTING_BMW_CHANGE_MGMT.name}
                  element={
                    <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTING_BMW_CHANGE_MGMT} projectId={projectId}>
                      <img
                        className="w-full h-auto"
                        src="/assets/report-bmw-change-mgmt.png"
                        alt="BMW Change Management report"
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={ROUTES_CONFIG.REPORTING_BMW_RISKS_OPS.name}
                  element={
                    <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTING_BMW_RISKS_OPS} projectId={projectId}>
                      <img
                        className="w-full h-auto"
                        src="/assets/report-bmw-risks-ops.png"
                        alt="BMW Risks Ops report"
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={ROUTES_CONFIG.REPORTING_BMW_SAZ.name}
                  element={
                    <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTING_BMW_SAZ} projectId={projectId}>
                      <ReportCashOut title={t('reporting.cashout.bmwTitle')} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={ROUTES_CONFIG.REPORTING_BMW_COST_WATERFALL.name}
                  element={
                    <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTING_BMW_COST_WATERFALL} projectId={projectId}>
                      <ReportWaterfall />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={ROUTES_CONFIG.REPORTING_BMW_SUMMARY.name}
                  element={
                    <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTING_BMW_SUMMARY} projectId={projectId}>
                      <img className="w-full h-auto" src="/assets/report-bmw-summary.png" alt="BMW Summary report" />
                    </ProtectedRoute>
                  }
                />
              </>
            )}

            {(user.tenant?.tenantId === '3239f945-4fce-4ebb-931f-89700f4c7631' ||
              settings.mainDomain === 'probis.test' ||
              settings.mainDomain === 'probis.dev') && (
              <Route
                path={ROUTES_CONFIG.REPORTING_CONTROLLING_REPORT.name}
                element={
                  <>
                    <img className="w-full h-auto" src="/assets/report-1.svg" alt="Controlling Report 1" />
                    <img className="w-full h-auto" src="/assets/report-2.svg" alt="Controlling Report 2" />
                    <img className="w-full h-auto" src="/assets/report-3.svg" alt="Controlling Report 3" />
                    <img className="w-full h-auto" src="/assets/report-4.svg" alt="Controlling Report 4" />
                    <img className="w-full h-auto" src="/assets/report-5.svg" alt="Controlling Report 5" />
                  </>
                }
              />
            )}
            <Route
              path={ROUTES_CONFIG.REPORTING_CARBON_EMISSION_CHART.name}
              element={
                <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTING_CARBON_EMISSION_CHART} projectId={projectId}>
                  <CarbonEmissionsChart />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES_CONFIG.REPORTING_CONTRACT.name}
              element={
                <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTING_CONTRACT} projectId={projectId}>
                  <ReportingContract />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES_CONFIG.REPORTING_COST_BREAKDOWN_REPORT.name}
              element={
                <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTING_COST_BREAKDOWN_REPORT} projectId={projectId}>
                  <ReportCostBreakdown />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES_CONFIG.REPORTING_REPORT_VIEW.name}
              element={
                <ProtectedRoute routeConfig={ROUTES_CONFIG.REPORTING_REPORT_VIEW} projectId={projectId}>
                  <LazyReportViewer projectId={projectId} calculationModelId={activeVariantId} />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to={ROUTES_CONFIG.REPORTING_BASE.name} replace />} />
          </Routes>
        </div>
      </div>
    </ReportingContextProvider>
  );
};
