import {
  CalculationState,
  ContractBudgetErrors,
  CostElementCatalogElementReadModel,
  FormulaPayload,
  TimelineErrors,
  useApiGetProjectWritableCostCatalogQuery,
  useApiGetCostElementQuery,
  useApiPostCreateCostElementMutation,
  useApiPostUpdateCostElementMutation,
  CostElementReadmodel,
  UserDefinedFieldPayload,
  useApiPostUpdateVatElementMutation,
  VatElementReadModel,
  VatElementPayload,
  useApiPostGenerateNextProjectObjectCodeMutation,
} from '@client/shared/api';
import {
  ComboSelect,
  BaseSelectOption,
  Button,
  HintBox,
  EditNodeIcon,
  Modal,
  SlideOver,
  SlideOverOnCloseProps,
  TagWindowIcon,
  TextInput,
  SlideOverTitle,
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import classNames from 'classnames';
import { useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AuditLog,
  FormattedCurrency,
  ProjectTaxPickerInput,
  timelineReadModelToPayload
} from '@client/project/shared';
import { CostElementDeleteModal } from './CostElementDeleteModal';
import { CostFormulaInput } from './CostFormulaInput';
import { VatReturnEdit } from './Distribution';
import { TimeLineDistributionsModal } from '@client/project/shared';
import { useLoadedProject } from '@client/project/store';
import {
  CostCatalogElement,
  useElementErrors
} from '../hooks';
import { useFeatureFlags } from '@client/shared/store';
import {
  useValidateCanWriteCatalogElement,
  useValidateProjectPermission
} from '@client/shared/permissions';
import ExternalForecast from './ExternalForecast';
import { CalculateElementDocuments } from './Documents';
import { SlideOverTotalSection } from './SlideOverTotalSection';
import { EditUserDefinedFields, TimeLineDistribution } from '@client/project/shared';


interface CostElementSlideOverProps extends SlideOverOnCloseProps {
  onClose: () => void;
  variantId?: string;
  elementId?: string;
  catalogElement?: CostCatalogElement;
  catalogId?: string;
  disabled?: boolean;
  timelineErrors: TimelineErrors[];
  calculationState?: CalculationState;
  preselectedGroupId?: string;
  contractBudgetErrors: ContractBudgetErrors[];
  createCopy?: boolean;
}

type NestedModal = 'None' | 'Delete' | 'ElementSetting' | 'UserDefinedFields';

type SubArea = 'Values' | 'Documents' | 'History' | 'Comments';

export const CostElementSlideOver = ({
  catalogId,
  catalogElement,
  //decoratedCostElement,
  disabled,
  elementId,
  onClose,
  variantId,
  timelineErrors,
  calculationState,
  preselectedGroupId,
  contractBudgetErrors,
  createCopy = false,
}: CostElementSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const { fakeUi: showFakeUi } = useFeatureFlags();

  const isEditing = !!elementId;

  const project = useLoadedProject();
  const projectId = project.currentData?.project.payload.id;
  const translatedErrors = useElementErrors(calculationState, timelineErrors, contractBudgetErrors);

  const catalogElementId = catalogElement?.group?.id;

  const canDeleteCosts = useValidateProjectPermission(['COSTS_DELETE'], projectId ?? '');

  const canWriteElement = useValidateCanWriteCatalogElement(projectId ?? '', catalogId, catalogElementId);
  const canWriteCosts = useValidateProjectPermission(['COSTS_WRITE'], projectId ?? '') && canWriteElement;

  const [subarea, setSubarea] = useState<SubArea>('Values');

  const [isDeleting, setIsDeleting] = useState(false);

  const {
    data: dataCostElement,
    isLoading,
    isFetching,
  } = useApiGetCostElementQuery(
    {
      id: elementId ?? '',
      projectId: projectId ?? 'unset',
      calculationModelId: variantId ?? 'unset',
    },
    { skip: !isEditing || !elementId || isDeleting },
  );

  const defaultCostElement: CostElementReadmodel = useMemo(
    () => ({
      id: '',
      logicalId: '',
      tenantId: '',
      calculatedValue: 0,
      vat: project.currentData?.project.payload.vat ?? 0,
      hasBudget: false,
      catalogElementElement: {
        id: catalogElement?.group?.id,
        code: catalogElement?.group?.code,
        description: catalogElement?.group?.description,
      } as CostElementCatalogElementReadModel,
      amount: { type: 'Static', staticValue: { unit: 'lumpsum', value: null } },
      unitPrice: { type: 'Static', staticValue: null },
      deliveryPhase: null,
      isGroup: !!catalogElement?.group?.id,
      code: '',
      description: t('projectCalculate.rowMenu.newCostElementTitle'),
      readOnly: false,
      totalValueCostCatalogId: null,
      calculationModelId: '',
      documents: [],
      isLocalLawElement: false,
      budgetAssignments: [],
      isVatElement: false,
    }),
    [catalogElement, project, t],
  );

  const isVatElement = dataCostElement?.readModel.isVatElement ?? false;

  const [code, setCode] = useState(catalogElement?.group?.code ?? '');
  const [description, setDescription] = useState(catalogElement?.group?.description ?? '');
  const [selectedCatalogElementId, setSelectedCatalogElementId] = useState<string | null>(catalogElement?.group?.id ?? null);
  const [vat, setVat] = useState(0);

  const [formulaResult, setFormulaResult] = useState(dataCostElement?.readModel);
  const [elementTiming, setElementTiming] = useState(dataCostElement?.readModel.deliveryPhase);
  const [vatCostElement, setVatCostElement] = useState(dataCostElement?.readModel.vatCostElement ?? null);

  const [isLoadingFormula, setIsLoadingFormula] = useState(false);

  const [nestedModal, setNestedModal] = useState<NestedModal>('None');
  const [showCalculatedDistribution, setShowCalculatedDistribution] = useState(false);
  const [userDefinedFieldsPayload, setUserDefinedFieldsPayload] = useState<UserDefinedFieldPayload[] | undefined>();

  const [getNextCode] = useApiPostGenerateNextProjectObjectCodeMutation();
  const [putElement, { isLoading: isUpdating }] = useApiPostUpdateCostElementMutation();
  const [postElement, { isLoading: isCreating }] = useApiPostCreateCostElementMutation();
  const [updateVatElement, { isLoading: isUpdatingVat }] = useApiPostUpdateVatElementMutation();

  const isGroup = dataCostElement ? dataCostElement.readModel.isGroup : defaultCostElement.isGroup;
  const hasChildren = catalogElement?.group?.children.length !== 0;
  const isReadOnly = !canWriteCosts || (dataCostElement?.readModel.readOnly ?? false) || disabled;
  const showDelete =
    dataCostElement?.readModel.isGroup && !dataCostElement.readModel.hasBudget
      ? false
      : isEditing && !createCopy && !disabled && canDeleteCosts;

  // for custom fields valid check
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    const getNextCostElementCode = async () => {
      if (projectId && variantId) {
        const isGettingNextCode = false;
        try {
          const nextCodeResponse = await safeMutation(
            getNextCode,
            {
              projectId: projectId,
              calculationModelId: variantId,
              body: {
                ownerObjectId: preselectedGroupId ?? dataCostElement?.readModel.catalogElementElement?.id ,
                projectObjectType: 'CostElements'
              }
            },
            isGettingNextCode,
          );
          if (typeof nextCodeResponse !== 'undefined') {
            setCode(nextCodeResponse.code);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }

    if (isEditing && dataCostElement) {
      if (createCopy){
        getNextCostElementCode();
      }else{
        setCode(dataCostElement.readModel?.code ?? '');
      }

      let description = dataCostElement.readModel.description ?? '';
      if (description && createCopy) {
        description = t('app.copyCopyOf', { name: description });
      }
      setDescription(description);

      setVat(dataCostElement.readModel.vat);
      setSelectedCatalogElementId(dataCostElement.readModel.catalogElementElement?.id ?? null);
      setFormulaResult(dataCostElement.readModel);
      setElementTiming(dataCostElement.readModel.deliveryPhase);
      setVatCostElement(dataCostElement.readModel.vatCostElement ?? null);
    } else {
      if (!isGroup){
        getNextCostElementCode();
        setDescription(defaultCostElement.description ?? '');
      }

      setVat(defaultCostElement.vat);
      setSelectedCatalogElementId(
        preselectedGroupId && preselectedGroupId !== 'unassigned'
          ? preselectedGroupId
          : defaultCostElement.catalogElementElement?.id ?? null,
      );
      setFormulaResult(defaultCostElement);
      setElementTiming(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    preselectedGroupId,
    dataCostElement,
    defaultCostElement,
    isEditing,
    isGroup,
    getNextCode,
    projectId,
    variantId
  ]);

  const { data: costCatalog, isFetching: isLoadingCostCatalog } = useApiGetProjectWritableCostCatalogQuery(
    {
      projectId: projectId ?? '',
      id: catalogId ?? 'unset',
    },
    {
      skip: !catalogId,
    },
  );

  const catalogOptions: BaseSelectOption[] = useMemo(() => {
    const options =
      costCatalog?.elements.map((catalogOption) => ({
        label: (catalogOption.code || catalogOption.description) ? `${catalogOption.code} - ${catalogOption.description}` : t('projectCalculate.costElementNoLabel'),
        value: catalogOption.id ?? 'no-id',
        order: catalogOption.order ?? 0,
      })) ?? [];

    return options.sort((a, b) => a.order - b.order);
  }, [costCatalog?.elements, t]);

  const getFormulaRequestBody = (arg: 'amount' | 'unitPrice'): FormulaPayload => {
    const formulaPayload: FormulaPayload = {
      expression: formulaResult?.[arg]?.formulaValue?.expression ?? '',
      expressionParameterPayloads:
        formulaResult?.[arg]?.formulaValue?.expressionParameters?.map((p) => ({
          position: p.position,
          taxonomyElementId: p?.taxonomyItem?.id,
          costCatalogElementId: p?.costCatalogElement?.id,
          earningsCatalogElementId: p?.earningsCatalogElement?.id,
          plotId: p?.plot?.id,
        })) ?? [],
      catalogElementId: selectedCatalogElementId,
      elementType: 'CostElement',
    };
    return formulaPayload;
  };

  const getVatElementPayload = (vatElement: VatElementReadModel | null): VatElementPayload | null => {
    if (isVatElement && vatElement) {
      return {
        vatCostCatalogId: selectedCatalogElementId ?? '',
        vatDistributions:
          elementTiming?.distribution?.manualDistributionValues
            .filter((v) => v.description !== 'VAT')
            .map((v) => ({
              date: v.date ?? '',
              amount: v.value,
            })) ?? [],
        vatReturnPercentage: vatElement.vatReturnPercentage,
        delayMonths: vatElement.delayMonths,
        costGroupIds: vatElement.costGroupIds ?? [],
        userDefinedFieldId: vatElement.userDefinedFieldId,
        userDefinedFieldLabelIds: vatElement.userDefinedFieldLabelIds,
      };
    }
    return null;
  };

  const handleUpdate = async () => {
    const amountType = formulaResult?.amount?.type ?? 'Static';
    const unitType = formulaResult?.unitPrice?.type ?? 'Static';

    if (elementId) {
      try {
        await safeMutation(
          putElement,
          {
            id: elementId,
            projectId: projectId ?? 'unset',
            calculationModelId: variantId ?? 'unset',
            body: {
              code: code,
              description: description,
              isGroup,
              catalogElementId: selectedCatalogElementId,
              amount: {
                type: amountType ?? 'Static',
                staticFactor:
                  amountType === 'Static' || amountType === 'Formula'
                    ? {
                        value: amountType === 'Formula' ? 0 : formulaResult?.amount?.staticValue?.value ?? 0,
                        unit: formulaResult?.amount.staticValue?.unit ?? '',
                      }
                    : null,
                formula: amountType === 'Formula' ? getFormulaRequestBody('amount') : null,
              },
              unitPrice: {
                type: unitType ?? 'Static',
                staticFactor:
                  unitType === 'Static'
                    ? {
                        value: formulaResult?.unitPrice.staticValue?.value ?? 0,
                        unit: formulaResult?.unitPrice.staticValue?.unit ?? '€',
                      }
                    : null,
                formula: formulaResult?.unitPrice.type === 'Formula' ? getFormulaRequestBody('unitPrice') : null,
              },
              deliveryPhasePayload: elementTiming?.distribution ? timelineReadModelToPayload(elementTiming) : null,
              vat: vat,
              userDefinedFieldsPayload: userDefinedFieldsPayload?.length ? userDefinedFieldsPayload : undefined,
              vatPayload: getVatElementPayload(vatCostElement),
            },
          },
          isUpdating,
        );
        onClose();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleCreate = async () => {
    try {
      await safeMutation(
        postElement,
        {
          body: {
            code: code,
            description: description,
            isGroup,
            vat: vat,
            catalogElementId:
              selectedCatalogElementId !== '00000000-0000-0000-0000-000000000000'
                ? selectedCatalogElementId
                : undefined,
            amount: {
              type: formulaResult?.amount?.type ?? 'Static',
              staticFactor:
                formulaResult?.amount?.type === 'Static' || formulaResult?.amount?.type === 'Formula'
                  ? {
                      value:
                        formulaResult?.amount?.type === 'Formula'
                          ? 0
                          : formulaResult?.amount?.staticValue?.value ?? 0,
                      unit: formulaResult?.amount.staticValue?.unit ?? '',
                    }
                  : null,
              formula: formulaResult?.amount?.type === 'Formula' ? getFormulaRequestBody('amount') : null,
            },
            unitPrice: {
              type: formulaResult?.unitPrice?.type ?? 'Static',
              staticFactor:
                formulaResult?.unitPrice?.type === 'Static'
                  ? {
                      value: formulaResult?.unitPrice.staticValue?.value ?? 0,
                      unit: formulaResult?.unitPrice.staticValue?.unit ?? '€',
                    }
                  : null,
              formula: formulaResult?.unitPrice.type === 'Formula' ? getFormulaRequestBody('unitPrice') : null,
            },
            deliveryPhasePayload: elementTiming?.distribution ? timelineReadModelToPayload(elementTiming) : null,
            userDefinedFieldsPayload: userDefinedFieldsPayload?.length ? userDefinedFieldsPayload : undefined,
          },
          projectId: projectId ?? '',
          calculationModelId: variantId ?? 'unset',
        },
        isCreating,
      );
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdateVatElement = async (vatElement: VatElementReadModel | null) => {
    if (elementId) {
      const triggerUpdate = async () => {
        try {
          return await updateVatElement({
            id: elementId,
            projectId: projectId ?? 'unset',
            calculationModelId: variantId ?? 'unset',
            body: {
              vatReturnPercentage: vatElement?.vatReturnPercentage ?? 0,
              delayMonths: vatElement?.delayMonths ?? 0,
              costGroupIds: vatElement?.costGroupIds ?? null,
              userDefinedFieldId: vatElement?.userDefinedFieldId === 'none' ? null : vatElement?.userDefinedFieldId,
              userDefinedFieldLabelIds:
                vatElement?.userDefinedFieldId === 'none' ? null : vatElement?.userDefinedFieldLabelIds ?? null,
            },
          }).unwrap();
        } catch (e) {
          console.log(e);
        }
      };
      triggerUpdate().then((result) => {
        if (result) {
          const cleanUpdatedTimeline = {
            ...result.updatedTimeline,
            distribution: result.updatedTimeline.distribution
              ? {
                  ...result.updatedTimeline.distribution,
                  id: elementTiming?.distribution?.id ?? '',
                  distributionPattern: result.updatedTimeline.distribution
                    ? {
                        ...result.updatedTimeline.distribution,
                        distributionValues:
                          result.updatedTimeline.distribution?.manualDistributionValues.map((v) => {
                            return {
                              ...v,
                              id: null,
                            };
                          }),
                        id: null,
                      }
                    : null,
                }
              : null,
            id: elementTiming?.id ?? '',
          };

          setElementTiming(cleanUpdatedTimeline);
          if (formulaResult) {
            setFormulaResult({
              ...formulaResult,
              unitPrice: {
                type: 'Static',
                staticValue: {
                  value: result.updatedUnitPrice,
                  unit: 'piece',
                },
              },
            });
          }
        }
      });
    }
  };

  const total = formulaResult?.calculatedValue;

  const onCloseNestedModal = (deleted: boolean) => {
    setNestedModal('None');

    if (deleted) {
      onClose();
    }
  };

  const displayExternalForecast = Boolean(catalogElement);
  const forecastValue: number = catalogElement?.group?.modelValues?.forecastValue ?? 0;

  return (
    <>
      <SlideOver.Header
        onClose={onClose}
        title={description ?? t('projectCalculate.costElementNewElementTitle')}
        subTitle={
          isGroup ? t('projectCalculate.groupLabelSubTitle') : t('projectCalculate.costElementNewElementSubTitle')
        }
      >
        <div className="flex flex-row pt-2 pl-8 bg-red-700 text-white">
          <button
            type="button"
            className={classNames('px-4 pb-1 text block border-l border-r', {
              'font-bold border-b-2 border-white': subarea === 'Values',
            })}
            onClick={() => setSubarea('Values')}
          >
            <div className="flex felx-row items-center">{t('common.values')}</div>
          </button>
          {isEditing && !createCopy && (
            <>
              <button
                type="button"
                className={classNames('px-4 pb-1 text block border-r', {
                  'font-bold border-b-2 border-white': subarea === 'Documents',
                })}
                onClick={() => setSubarea('Documents')}
              >
                <div className="flex felx-row items-center">{t('common.documents')}</div>
              </button>
              {showFakeUi && (
                <>
                  <button
                    type="button"
                    className={classNames('px-4 pb-1 text block border-l border-r', {
                      'font-bold border-b-2 border-white': subarea === 'History',
                    })}
                    onClick={() => setSubarea('History')}
                  >
                    <div className="flex felx-row items-center">{t('common.history')}</div>
                  </button>
                  <button
                    type="button"
                    className={classNames('px-4 pb-1 text block border-r', {
                      'font-bold border-b-2 border-white': subarea === 'Comments',
                    })}
                    onClick={() => setSubarea('Comments')}
                  >
                    <div className="flex felx-row items-center">{t('common.comments')}</div>
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </SlideOver.Header>
      <SlideOver.Content
        isLoading={
          isLoading ||
          isFetching ||
          isUpdatingVat ||
          isLoadingCostCatalog ||
          isLoadingFormula
        }
        onKeyEnter={() => {
          submitRef.current?.click();
        }}
      >
        <div className="flex flex-grow flex-col">
          {(dataCostElement?.readModel.financingFeeElementId || dataCostElement?.readModel.financingElementId) && (
            <HintBox className="mt-4">
              {t('projectCalculate.costElementHintFinancingFee', { name: description })}
            </HintBox>
          )}

          {translatedErrors && translatedErrors.length > 0 && (
            <HintBox hintType="warning">{translatedErrors.join(`\n`)}</HintBox>
          )}

          {subarea === 'Comments' && (
            <div className="p-8">
              <img className="w-full h-auto" src="/assets/fake-calculate-element-comment.svg" alt="comments" />
            </div>
          )}

          {subarea === 'History' && (
            <div className="p-8">
              <AuditLog
                id={dataCostElement?.readModel.logicalId ?? ''}
                targetType='CostElement'
              />
            </div>
          )}

          {subarea === 'Documents' && (
            <div className="m-8">
              <CalculateElementDocuments
                ownerId={elementId ?? ''}
                type="CostElement"
                canWrite={canWriteCosts}
                canDelete={canDeleteCosts}
                documents={dataCostElement?.readModel.documents ?? []}
              />
            </div>
          )}

          {subarea === 'Values' && (
            <>
              <div className="mx-8 mt-8 bg-white">
                <div className="divide-gray-100 divide-y">
                  {!isGroup ? (
                    <ComboSelect
                      label={t('projectCalculate.groupLabelGroupSelector')}
                      options={catalogOptions}
                      value={selectedCatalogElementId ?? ''}
                      disabled={isReadOnly}
                      onChange={(selected) => setSelectedCatalogElementId(selected)}
                      icon={<EditNodeIcon />}
                    />
                  ) : (
                    <TextInput
                      label={t('projectCalculate.costElementLabelCatalogElement')}
                      value={`${code} ${description}`}
                      disabled={true}
                      icon={<TagWindowIcon />}
                    />
                  )}
                  {!isGroup &&
                    <>
                      <TextInput
                          label={t('projectCalculate.costElementElementLabelPosition')}
                          value={code}
                          onChange={(value) => setCode(value)}
                          icon={<EditNodeIcon />}
                          disabled={isReadOnly}
                      />

                      <TextInput
                          label={t('projectCalculate.costElementLabelDescription')}
                          value={description}
                          onChange={(value) => setDescription(value)}
                          icon={<TagWindowIcon />}
                          disabled={isReadOnly}
                      />
                    </>
                  }
                </div>
              </div>
              <SlideOverTitle
                title={
                  isGroup
                    ? t('projectCalculate.groupLabelCostCalculation')
                    : t('projectCalculate.costElementLabelCostCalculation')
                }
                className="px-8"
              />
              <div className="mx-8 bg-white">
                <CostFormulaInput
                  value={formulaResult ?? defaultCostElement}
                  disabled={isReadOnly || isVatElement}
                  onChange={setFormulaResult}
                  catalogElementId={selectedCatalogElementId || undefined}
                  hasBudget={!!dataCostElement?.readModel.budgetAssignments?.length}
                  setIsLoading={setIsLoadingFormula}
                />
              </div>
              {!isVatElement &&
                <div className="mx-8 bg-white">
                  <ProjectTaxPickerInput
                    value={vat}
                    disabled={isReadOnly || isVatElement}
                    onChange={(value) => setVat(value ?? 0)}
                  />
                </div>
              }
              {isVatElement && vatCostElement && (
                <>
                  <SlideOverTitle title={t('projectCalculate.vatReturnPlan')} className="px-8" />
                  <div className="mb-4 mx-8 ">
                    <VatReturnEdit
                      vatCostElement={vatCostElement}
                      updateVatCostElement={(value) => {
                        setVatCostElement(value);
                        handleUpdateVatElement(value);
                      }}
                      disabled={isReadOnly}
                    />
                  </div>
                </>
              )}
              <SlideOverTitle title={t('projectCalculate.costElementLabelTimeline')} className="px-8" />
              {isVatElement && (
                <div className="w-full flex justify-center">
                  <Button
                    variant="secondary"
                    hasPadding={true}
                    className="mx-8 mb-2"
                    onClick={() => handleUpdateVatElement(vatCostElement)}
                    disabled={isReadOnly}
                  >
                    {t('projectCalculate.costElementUpdateData')}
                  </Button>
                </div>
              )}
              <div className="mx-8 bg-white">
                <TimeLineDistribution
                  totalValue={total}
                  variantId={variantId}
                  timing={elementTiming}
                  disabled={isReadOnly}
                  disabledDistributionTypes={!elementId || !isGroup || !hasChildren ? ['Effective'] : []}
                  costGroupId={isGroup ? elementId : undefined}
                  onChange={(value) => setElementTiming(value)}
                  clearable={true}
                  onClear={() => setElementTiming(null)}
                />
              </div>
              {!dataCostElement?.readModel.deliveryPhase && dataCostElement?.readModel.calculatedTimeline && (
                <div className="mr-8 flex flex-row-reverse">
                  <Button
                    variant="text"
                    className="text-sm"
                    onClick={() => setShowCalculatedDistribution(!showCalculatedDistribution)}
                  >
                    {t('projectCalculate.ShowCalculatedTimelineButton')}
                  </Button>
                </div>
              )}
              <SlideOverTotalSection
                label={t('projectCalculate.costElementLabelNetSum')}
                value={<FormattedCurrency amount={total} />}
              />
              {!isReadOnly && (
                <EditUserDefinedFields
                  elementId={elementId}
                  type="Cost"
                  calculateElementType={isGroup ? 'Group' : 'Element'}
                  setUpdatePayload={setUserDefinedFieldsPayload}
                  isSubmitted={isFormSubmitted}
                />
              )}

              {displayExternalForecast && <ExternalForecast forecastValue={forecastValue} />}
            </>
          )}
        </div>
      </SlideOver.Content>
      <SlideOver.Controls disabled={isLoading}>
        <div
          className={classNames('w-full flex justify-end', {
            'justify-between': isEditing && !createCopy && !disabled && showDelete,
          })}
        >
          {showDelete && !isReadOnly && (
            <Button variant="warning" onClick={() => setNestedModal('Delete')}>
              {isGroup ? t('projectCalculate.groupLabelDeleteBudget') : t('common.delete')}
            </Button>
          )}
          <div className="flex">
            <Button variant="secondary" onClick={onClose} className="mr-2">
              {t('common.cancel')}
            </Button>
            {!isReadOnly && (
              <Button
                variant="primary"
                onClick={() => {
                  setIsFormSubmitted(true);
                  if (isEditing && !createCopy) {
                    handleUpdate();
                  } else {
                    handleCreate();
                  }
                }}
                innerRef={submitRef}
              >
                {t('common.save')}
              </Button>
            )}
          </div>
        </div>
      </SlideOver.Controls>

      <Modal isOpen={nestedModal === 'Delete'} onClose={onCloseNestedModal}>
        <CostElementDeleteModal
          elementId={elementId}
          variantId={variantId}
          onClose={onCloseNestedModal}
          setIsDeleting={setIsDeleting}
        />
      </Modal>

      {dataCostElement?.readModel.calculatedTimeline && (
        <Modal isOpen={showCalculatedDistribution} onClose={() => setShowCalculatedDistribution(false)}>
          <TimeLineDistributionsModal
            useExistingEffectiveDistributionValues={true}
            timeline={dataCostElement.readModel.calculatedTimeline}
            costGroupId={elementId}
            onClose={() => setShowCalculatedDistribution(false)}
          />
        </Modal>
      )}
    </>
  );
};
