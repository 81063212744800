import { Button, FileInput, Modal } from '@client/shared/toolkit';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiBase, ApiPostUploadAiEvalDocumentApiArg, ApiTagTypes, useApiPostUploadAiEvalDocumentMutation } from '@client/shared/api';
import toast from 'react-hot-toast';
import { safeMutation } from '@client/shared/utilities';
import { useLoadedProjectId } from '@client/project/store';
import { useDispatch } from 'react-redux';


interface InvoiceDocumentUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const InvoiceDocumentUploadModal = (props: InvoiceDocumentUploadModalProps) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const dispatch = useDispatch();

  const [uploadInvoiceDocument, { isLoading: isUploadingDocument }] = useApiPostUploadAiEvalDocumentMutation();

  const [files, setFiles] = useState<File[]>([]);
  const [isFileError, setIsFileError] = useState(false);

  const handleFileChange = (files: FileList | null) => {
    if (files?.length) {
      setFiles(Array.from(files));
    } else {
      setFiles([]);
    }
  };

  const uploadFile = useCallback(async () => {
    if (!isUploadingDocument && files?.length) {
      try {
        let errorCount = 0;
        files.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);

          const args = {
            invoiceId: null,
            projectId: loadedProjectId,
            body: formData,
          } as ApiPostUploadAiEvalDocumentApiArg;

          const response = await safeMutation(uploadInvoiceDocument, args, isUploadingDocument);
          if (
            response?.aiServiceError !== null &&
            response?.aiServiceError !== '' &&
            response?.aiServiceError !== undefined
          ) {
            errorCount += 1;
            toast.error(t(response.aiServiceError), { duration: 5000 });
          } else {
            toast.success(t('projectControl.notificationUploadingInvoiceDocument', { name: file.name }), {
              duration: 5000,
            });
            setTimeout(() => {
              // @ts-expect-error invalidate get tasks list
               dispatch(apiBase.util.invalidateTags([{ type: ApiTagTypes.Tasks }]));
            }, 3000);
          }
        });

        setFiles([]);
        if (errorCount === 0)
          toast.success(t('projectControl.notificationUploadingInvoiceDocumentsCompleted'));

        onClose();
      } catch (e) {
        console.log(e);
      }
    }
  }, [isUploadingDocument, files, t, onClose, loadedProjectId, uploadInvoiceDocument, dispatch]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header
        title={t('projectControl.uploadInvoiceTitle')}
        description={t('projectControl.uploadInvoiceSubTitle')}
      />
      <Modal.Content>
        <div className="bg-white p-2 mb-6 flex-1">
          <FileInput
            className="h-full flex-1"
            acceptedFileTypes={['application/pdf']}
            multiple={true}
            selectedFiles={null}
            onChange={handleFileChange}
            uploadDescription={t('projectControl.invoiceUploadMessage')}
            maxFileSize={15000000}
            setError={setIsFileError}
          />
        </div>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" className="mr-2" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={uploadFile} disabled={isFileError || !files}>
          {t('common.upload')}
        </Button>
      </Modal.Controls>
    </Modal>
  );
};

