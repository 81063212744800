import { DefaultValuesReadModel, useApiGetDefaultValuesQuery } from '@client/shared/api';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditButton, LoadingIndicator, SlideOver, getCurrencyLabelForCurrency } from '@client/shared/toolkit';
import { DefaultValuesBenchmarkingEditSlideOver } from './DefaultValuesBenchmarkingEditSlideOver';

export const DefaultValuesBenchmarking = ({ defaultValues }: { defaultValues?: DefaultValuesReadModel }) => {
  const { t } = useTranslation();
  const [isBenchmarkingSlideOverOpen, setIsBenchmarkingSlideOverOpen] = useState<boolean>(false);
  const { data: response, isFetching } = useApiGetDefaultValuesQuery(undefined, {
    skip: typeof defaultValues !== 'undefined',
  });
  const defaultBenchmarkingValues = useMemo(() => {
    return defaultValues ?? response;
  }, [defaultValues, response]);

  if (!defaultBenchmarkingValues) {
    return null;
  }
  return (
    <div className="pl-10">
      <div className="absolute top-4 right-10">
        <EditButton className="ml-auto" onClick={() => setIsBenchmarkingSlideOverOpen(true)} />
      </div>
      {isFetching && <LoadingIndicator text={t('app.masterDataDefaultValues.Loading')} />}
      <div className="flex flex-row items-center py-1 gap-2">
        <span className="font-bold">{t('common.currency')}:</span>
        <span>{getCurrencyLabelForCurrency(defaultBenchmarkingValues.currency)}</span>
      </div>
      <SlideOver isOpen={isBenchmarkingSlideOverOpen} onClose={() => setIsBenchmarkingSlideOverOpen(false)} variant="x-large">
        <DefaultValuesBenchmarkingEditSlideOver defaultValues={defaultBenchmarkingValues} onClose={() => setIsBenchmarkingSlideOverOpen(false)} />
      </SlideOver>
    </div>
  );
};
